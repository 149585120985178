import LikeIcon from "../../../../assets/LikeIcon";
import { useStore } from "../../../../store/store";
import useLikeQuestion from "../../../hooks/useLikeQuestion";
interface IProps {
	questionId: number | undefined;
	userId: number | undefined;
	approved: boolean;
	active: number;
}
const LikeButton = (props: IProps) => {
	const likeQuestionMutation = useLikeQuestion(props.questionId);
	const user = useStore((state) => state.user);
	return (
		<button
			title="like question"
			onClick={() => {
				if (props.approved) {
					likeQuestionMutation.mutate({
						questionId: props.questionId,
						...(user?.role !== "moderator" && { subscriberId: props.userId }),
						...(user?.role === "moderator" && { moderatorId: props.userId }),
					});
				}
			}}
		>
			<LikeIcon active={props.active > -1} />
		</button>
	);
};

export default LikeButton;
