import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import { useStore } from "../../store/store";
import { useLocation } from "react-router-dom";
import { SocketEvents } from "../../LivePage/hooks/useSocket/types";

const useDeleteQuestion = (questionId: number) => {
	const socket = useStore((state) => state.socket);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	return useMutation({
		mutationFn: async () => {
			const endpoint = await generateSignedEndpoint(`/live/questions/${questionId}`);
			const response = await axios.delete(endpoint, {
				headers: {
					token: token,
				},
			});
			return response.data;
		},
		onSuccess: (data) => {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.deleteQuestion,
						data: data.data,
					})
				);
			}
		},
	});
};

export default useDeleteQuestion;
