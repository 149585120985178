import { StateCreator } from "zustand";
import { ILiveDetails } from "../hooks/useGetLiveDetails";
export interface ISignupSlice {
	live: ILiveDetails | null;
	setLive: (live: ILiveDetails) => void;
	liveId: number | null;
	setLiveId: (liveId: number) => void;
	name: string;
	setName: (name: string) => void;
	lastName: string;
	setLastName: (lastName: string) => void;
	email: string;
	setEmail: (email: string) => void;
	chat: boolean;
	setChat: (canChat: boolean) => void;
	canComment: boolean;
	setCanComment: (canComment: boolean) => void;
	isCommentValidation: boolean;
	setIsCommentValidation: (isCommentValidation: boolean) => void;
	canReplyToQuestions: boolean;
	setCanReplyToQuestions: (canReplyToQuestions: boolean) => void;
	livePassword: string;
	setLivePassword: (livePassword: string) => void;
	cryptedLivePassword: string;
	setCryptedLivePassword: (cryptedLivePassword: string) => void;
	showPasswordModal: boolean;
	setShowPasswordModal: (showPasswordModal: boolean) => void;
	withSubscription: boolean;
	setWithSubscription: (withSubscription: boolean) => void;
	videoBeforeLive: string;
	setVideoBeforeLive: (videoBeforeLive: string) => void;
	isIntranet: boolean;
	setIsIntranet: (isIntranet: boolean) => void;
	liveTitle: string;
	setLiveTitle: (liveTitle: string) => void;
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (EndDate: string) => void;
	canCommentBeforeLive: boolean;
	setCanCommentBeforeLive: (canCommentBeforeLive: boolean) => void;
	backstage: boolean;
	setBackstage: (backstage: boolean) => void;
}
export const signupSlice: StateCreator<ISignupSlice> = (set) => ({
	live: null,
	setLive: (live) => set({ live }),
	liveId: null,
	setLiveId: (liveId) => set({ liveId }),
	name: "",
	setName: (name) => set({ name }),
	lastName: "",
	setLastName: (lastName) => set({ lastName }),
	email: "",
	setEmail: (email) => set({ email }),
	chat: false,
	setChat: (chat) => set({ chat }),
	canComment: false,
	setCanComment: (canComment) => set({ canComment }),
	isCommentValidation: false,
	setIsCommentValidation: (isCommentValidation) => set({ isCommentValidation }),
	canReplyToQuestions: false,
	setCanReplyToQuestions: (canReplyToQuestions) => set({ canReplyToQuestions }),
	livePassword: "",
	setLivePassword: (livePassword) => set({ livePassword }),
	cryptedLivePassword: "",
	setCryptedLivePassword: (cryptedLivePassword) => set({ cryptedLivePassword }),
	showPasswordModal: false,
	setShowPasswordModal: (showPasswordModal) => set({ showPasswordModal }),
	withSubscription: false,
	setWithSubscription: (withSubscription) => set({ withSubscription }),
	videoBeforeLive: "",
	setVideoBeforeLive: (videoBeforeLive) => set({ videoBeforeLive }),
	isIntranet: false,
	setIsIntranet: (isIntranet) => set({ isIntranet }),
	liveTitle: "",
	setLiveTitle: (liveTitle) => set({ liveTitle }),
	startDate: "",
	setStartDate: (startDate) => set({ startDate }),
	endDate: "",
	setEndDate: (endDate) => set({ endDate }),
	canCommentBeforeLive: false,
	setCanCommentBeforeLive: (canCommentBeforeLive) => set({ canCommentBeforeLive }),
	backstage: false,
	setBackstage: (backstage) => set({ backstage }),
});
