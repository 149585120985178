import { Navigate, useParams } from "react-router-dom";
import useGetLiveDetails from "../SignupPage/hooks/useGetLiveDetails";
import Loader from "../components/Loader/Loader";
import InteractionHeader from "./InteractionHeader/InteractionHeader";
import Tabs from "./Tabs/Tabs";
import { useStore } from "../store/store";
import InteractionSideBar from "./InteractionSideBar";
import useCheckProtectedLive from "../SignupPage/hooks/useCheckProtectedLive";
import RequirePassword from "../components/RequirePassword";
import { isAxiosError } from "axios";
import Error from "../components/Error";
import useLiveDetailsUpdate from "../hooks/useLiveDetailsUpdate";
import Footer from "../SignupPage/Footer";
import { Toaster } from "react-hot-toast";

const InteractionPage = () => {
	const { liveToken } = useParams();
	const liveDetails = useGetLiveDetails(liveToken);
	const data = liveDetails.data?.live;
	const showPasswordModal = useStore((state) => state.showPasswordModal);
	useCheckProtectedLive(liveDetails);
	useLiveDetailsUpdate(data);

	if (liveDetails.isLoading) {
		return (
			<div className="grid h-screen w-full place-items-center bg-[#ECF6FA]">
				<Loader />
			</div>
		);
	}

	if (
		liveDetails.isError &&
		liveDetails.error &&
		isAxiosError(liveDetails.error) &&
		liveDetails.error.response?.status === 404
	) {
		return <Navigate to={`/404`} />;
	}

	if (
		liveDetails.isError &&
		liveDetails.error &&
		isAxiosError(liveDetails.error) &&
		liveDetails.error.response?.status !== 403 &&
		liveDetails.error.response?.data?.message !== "Unauthorized"
	) {
		return <Error />;
	}

	return (
		<div className="h-screen w-full bg-[#ECF6FA]">
			<Toaster
				toastOptions={{
					style: {
						textAlign: "center",
					},
				}}
			/>
			{showPasswordModal ? <RequirePassword /> : null}
			<InteractionHeader title={liveDetails.data?.live.title} />
			<div className="flex h-[calc(100%-10rem)] w-full   items-center justify-center gap-3 p-3 lg:flex-row">
				<Tabs canComment={data?.canComment} backstage={data?.private_chat} chat={data?.chat} />
				<InteractionSideBar liveId={data?.id} backstage={data?.private_chat} />
			</div>

			<div className=" flex h-20 items-center justify-center bg-[#ECF6FA] p-2 text-center text-text">
				<Footer />
			</div>
		</div>
	);
};

export default InteractionPage;
