import { useState } from "react";
import Input from "../SignupPage/SignupForm/Input";
import SignupButton from "../SignupPage/SignupForm/SignupButton";
import { useTranslation } from "react-i18next";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { generateSignedEndpoint } from "../api/generateSignedEndpoint";

interface IProps {
	liveToken: string | undefined;
}

const ModeratorForm = (props: IProps) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const moderatorToken = queryParams.get("moderatorToken");

	const loginAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
		try {
			e.preventDefault();
			if (email.trim() === "" || password.trim() === "") {
				toast.error(t("signup_page.fill_all_fields"));
				return;
			}
			setLoading(true);
			const endpoint = "/live/login";
			const signedUrl = await generateSignedEndpoint(endpoint);
			const payload = {
				login: email,
				password: password,
				liveToken: props.liveToken,
			};
			const response = await axios.post(signedUrl, payload, {
				headers: {
					"Content-Type": "application/json",
					token: moderatorToken,
				},
			});
			const authToken = response?.data?.authToken;
			navigate(
				`/live-participant/${props.liveToken}?token=${authToken}&moderatorToken=${moderatorToken}`
			);
			setLoading(false);
		} catch (e) {
			if (axios.isAxiosError(e)) {
				const error = e;
				if (
					(error.response?.status === 401 &&
						error.response.data?.message === "Invalid credentials") ||
					(error.response?.status === 400 && error.response.data?.message === "record not found")
				) {
					toast.error(t("admin_page.bad_credentials"));
				} else {
					toast.error(t("admin_page.error"));
				}
			}
			setLoading(false);
		}
	};
	return (
		<form
			onSubmit={loginAdmin}
			className="flex w-full flex-col  items-center gap-5 rounded-lg bg-[#263238] p-4 sm:w-96 md:w-[30rem]"
			style={{
				boxShadow: "1px -2px 10px -1px rgba(255, 255, 255, 0.15)",
			}}
		>
			<Toaster
				toastOptions={{
					style: {
						textAlign: "center",
					},
				}}
			/>

			<h3 className=" pt-2 text-xl font-semibold text-white">{t("admin_page.authentication")}</h3>
			<div className="w-full">
				<Input
					required={false}
					color="white"
					value={email}
					setValue={setEmail}
					type="email"
					label={t("signup_page.email")}
				/>
			</div>

			<Input
				required={false}
				value={password}
				setValue={setPassword}
				type="password"
				color="white"
				label={t("admin_page.password")}
			/>
			<SignupButton loading={loading} text={t("admin_page.authenticate")} width="w-full" />
		</form>
	);
};

export default ModeratorForm;
