import { StateCreator } from "zustand";
export interface IQuestionWithoutRegisterSlice {
	listenerName: string;
	setListenerName: (name: string) => void;
	listenerLastName: string;
	setListenerLastName: (name: string) => void;
	listenerEmail: string;
	setListenerEmail: (email: string) => void;
	listenerEneterprise: string;
	setListenerEnterprise: (enterprise: string) => void;
	question: string;
	setQuestion: (question: string) => void;
	showSuccessToast: boolean;
	setShowSuccessToast: (showToast: boolean) => void;
	showFailToast: boolean;
	setShowFailToast: (showToast: boolean) => void;
	showForm: boolean;
	setShowForm: (showForm: boolean) => void;
}

export const questionWithoutRegisterSlice: StateCreator<IQuestionWithoutRegisterSlice> = (set) => ({
	listenerName: "",
	setListenerName: (name) => set({ listenerName: name }),
	listenerLastName: "",
	setListenerLastName: (name) => set({ listenerLastName: name }),
	listenerEmail: "",
	setListenerEmail: (email) => set({ listenerEmail: email }),
	listenerEneterprise: "",
	setListenerEnterprise: (enterprise) => set({ listenerEneterprise: enterprise }),
	question: "",
	setQuestion: (question) => set({ question }),
	showSuccessToast: false,
	setShowSuccessToast: (showSuccessToast) => set({ showSuccessToast }),
	showFailToast: false,
	setShowFailToast: (showFailToast) => set({ showFailToast }),
	showForm: false,
	setShowForm: (showForm) => set({ showForm }),
});
