const ReloadIcon = () => {
	return (
		<svg width="22" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.3748 6.96879L23.9998 5.36917C22.8339 4.14466 21.4311 3.17017 19.8767 2.50501C18.3223 1.83984 16.6488 1.4979 14.9581 1.50001C8.057 1.50001 2.45801 7.099 2.45801 14.0001C2.45801 20.9012 8.057 26.5002 14.9581 26.5002C17.5434 26.5 20.0651 25.6986 22.1762 24.2062C24.2873 22.7139 25.884 20.6039 26.7466 18.1668"
				stroke="#00A6D9"
				strokeWidth="3"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M29.5419 3.67584V10.8751C29.5419 11.1514 29.4322 11.4163 29.2368 11.6117C29.0415 11.807 28.7765 11.9168 28.5003 11.9168H21.301C20.3726 11.9168 19.9078 10.795 20.564 10.1388L27.7639 2.93886C28.4202 2.2813 29.5419 2.74745 29.5419 3.67584Z"
				fill="#00A6D9"
			/>
		</svg>
	);
};

export default ReloadIcon;
