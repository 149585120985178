export interface FilterIcon {
	ClassName: string;
}
const TrierIcon = (props: FilterIcon) => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className={props.ClassName}>
        <path d="M8.16667 9H14.8333M8.16667 5.66667H12.3333M8.16667 2.33333H10.6667M8.16667 12.3333H16.5M3.58333 16.5V1.5M3.58333 16.5C3 16.5 1.91 14.8383 1.5 14.4167M3.58333 16.5C4.16667 16.5 5.25667 14.8383 5.66667 14.4167" stroke="#999DAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
	);
};

export default TrierIcon;
