import { useTranslation } from "react-i18next";
import NotFoundImageFr from "../assets/NotFoundImageFr";
import NotFoundImageEn from "../assets/NotFoundImageEn";
const NotFoundPage = () => {
	const { t, i18n } = useTranslation();

	return (
		<div className="grid h-screen w-full place-items-center ">
			<div className="flex flex-col items-center">
				{i18n.language === "fr" ? <NotFoundImageFr /> : <NotFoundImageEn />}
				<div className="text-center ">
					<h1 className="font-semibold text-rte lg:text-2xl">{t("not_found_page.sorry")}</h1>
					<h3 className=" font-medium text-text lg:text-xl">{t("not_found_page.not_found")}</h3>
				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;
