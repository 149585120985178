export interface IAdminIconProps {
	active: boolean;
}
const AdminIcon = (props: IAdminIconProps) => {
	return (
		<svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.5 27.5C5.55375 25.9562 0 19.4025 0 12.5V5L12.5 0L25 5V12.5C25 19.405 19.4463 25.9562 12.5 27.5ZM2.5 6.25V12.5C2.57143 15.3902 3.58795 18.1773 5.39397 20.4348C7.19999 22.6923 9.69601 24.2959 12.5 25C15.304 24.2959 17.8 22.6923 19.606 20.4348C21.412 18.1773 22.4286 15.3902 22.5 12.5V6.25L12.5 2.5L2.5 6.25Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
			<path
				d="M12.5 12.5C14.2259 12.5 15.625 11.1009 15.625 9.375C15.625 7.64911 14.2259 6.25 12.5 6.25C10.7741 6.25 9.375 7.64911 9.375 9.375C9.375 11.1009 10.7741 12.5 12.5 12.5Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
			<path
				d="M6.25 17.5C6.86611 18.6228 7.76949 19.5616 8.86767 20.2205C9.96586 20.8795 11.2194 21.2347 12.5 21.25C13.7806 21.2347 15.0341 20.8795 16.1323 20.2205C17.2305 19.5616 18.1339 18.6228 18.75 17.5C18.7188 15.13 14.5725 13.75 12.5 13.75C10.4162 13.75 6.28125 15.13 6.25 17.5Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
		</svg>
	);
};

export default AdminIcon;
