interface IProps {
	active: boolean;
}

const BroadcastQuestion = (props: IProps) => {
	return (
		<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="31" height="31" rx="5" fill={props.active ? "#00A6D9" : "#C4C4C8"} />
			<path
				d="M7.58334 13.125V12.3333C7.58334 9.95833 9.16668 8.375 11.5417 8.375H19.4583C21.8333 8.375 23.4167 9.95833 23.4167 12.3333V18.6667C23.4167 21.0417 21.8333 22.625 19.4583 22.625H18.6667"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.92126 15.2704C12.5788 15.7375 15.2625 18.4291 15.7375 22.0866"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.07416 17.9304C10.7579 18.2708 12.7292 20.25 13.0775 22.9338"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.5675 20.9308C8.90542 21.105 9.89501 22.0867 10.0692 23.4325"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default BroadcastQuestion;
