export async function generateSignedUrl(url: URL) {
	// You will need some super-secret data to use as a symmetric key.
	const encoder = new TextEncoder();
	const secretKeyData = encoder.encode("2qySfsbI5akSqHmj_empreinte_bNLt1pnS4I");
	const key = await crypto.subtle.importKey(
		"raw",
		secretKeyData,
		{ name: "HMAC", hash: "SHA-256" },
		false,
		["sign"]
	);

	// Signed requests expire after one minute. Note that you could choose
	// expiration durations dynamically, depending on, for example, the path or a query
	// parameter.
	const expirationMs = 3 * 60 * 1000; // 3min
	const expiry = Date.now() + expirationMs;
	// The signature will be computed for the pathname and the expiry timestamp.
	// The two fields must be separated or padded to ensure that an attacker
	// will not be able to use the same signature for other pathname/expiry pairs.
	// The @ symbol is guaranteed not to appear in expiry, which is a (decimal)
	// number, so you can safely use it as a separator here. When combining more
	// fields, consider JSON.stringify-ing an array of the fields instead of
	// concatenating the values.
	const dataToAuthenticate = `${url.pathname}@${expiry}`;

	const mac = await crypto.subtle.sign("HMAC", key, encoder.encode(dataToAuthenticate));

	// `mac` is an ArrayBuffer, so you need to make a few changes to get
	// it into a ByteString, and then a Base64-encoded string.
	let base64Mac = btoa(String.fromCharCode(...new Uint8Array(mac)));

	// must convert "+" to "-" as urls encode "+" as " "
	url.searchParams.set("mac", base64Mac);
	url.searchParams.set("expiry", expiry.toString());

	return url.toString();
}
