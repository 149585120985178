import ErrorIcon from "./../../../assets/ErrorIcon.svg";
interface IProps {
	text: string;
}
const LiveEnded = (props: IProps) => {
	return (
		<div className="flex items-center justify-center gap-2 rounded-lg bg-[#ECF6FA] p-2 text-text">
			<img src={ErrorIcon} alt="Error icon" />
			{props.text}
		</div>
	);
};

export default LiveEnded;
