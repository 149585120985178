import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IApproveQuestionPayload {
	question: {
		approved?: boolean;
		question?: string;
		managedById?: number;
	};
}
const useEditQuestion = (questionId: number | undefined | null) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	const { t } = useTranslation();
	return useMutation({
		mutationFn: async (payload: IApproveQuestionPayload) => {
			const signedEndpoint = await generateSignedEndpoint(`/live/questions/question/${questionId}`);
			return axios.put(signedEndpoint, payload, {
				headers: {
					token: token,
				},
			});
		},
		onError: () => {
			toast.error(t("errors.edited"));
		},
	});
};

export default useEditQuestion;
