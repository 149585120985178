import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/store";
import Tab from "./Tab";
import { useEffect, useState } from "react";
import TabArrow from "../../../assets/TabArrow";
interface IProps {
	chat: boolean | undefined;
	canComment: boolean | undefined;
}
const Tabs = (props: IProps) => {
	const liveDetails = useStore((state) => state.live);
	const activeTab = useStore((state) => state.activeTab);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const { t } = useTranslation();
	const user = useStore((state) => state.user);
	const [clicked, setClicked] = useState(false);
	let numberOfTabs = 1;

	if ((user?.role && user?.role !== "listener") || props.canComment) {
		numberOfTabs++;
	}
	if (props.chat) {
		numberOfTabs++;
	}
	if (liveDetails?.canShareFile && liveDetails?.docPublic) {
		numberOfTabs++;
	}

	useEffect(() => {
		if ((user?.role && user?.role !== "listener") || props.canComment) {
			setActiveTab(2);
			return;
		}
		if (props.chat) {
			setActiveTab(1);
			return;
		}
		if (liveDetails?.canShareFile && liveDetails?.docPublic) {
			setActiveTab(8);
			return;
		}
		setActiveTab(3);
	}, [props.canComment, props.chat, user?.role, liveDetails?.canShareFile, liveDetails?.docPublic]);

	return (
		<ul className="relative flex h-full w-full items-center justify-around font-semibold text-white ">
			{activeTab === 4 ? (
				<Tab title={t("live_page.questions")} number={4} />
			) : (
				<>
					{!clicked && (
						<>
							{(user?.role && user?.role !== "listener") || props.canComment ? (
								<Tab title={t("live_page.questions")} number={2} />
							) : null}
							{props.chat ? <Tab title={t("live_page.chat")} number={1} /> : null}
							<Tab title={t("live_page.details")} number={3} />
							{liveDetails?.canShareFile && liveDetails?.docPublic && numberOfTabs <= 3 && (
								<Tab title={t("live_page.advanced_settings")} number={8} />
							)}
							{numberOfTabs > 3 && (
								<button
									className="p-1"
									onClick={() => {
										setClicked((prev) => !prev);
									}}
								>
									<TabArrow />
								</button>
							)}
						</>
					)}

					{clicked && liveDetails?.canShareFile && liveDetails?.docPublic && (
						<>
							{numberOfTabs > 3 && (
								<button
									className="rotate-180 p-1"
									onClick={() => {
										setClicked((prev) => !prev);
									}}
								>
									<TabArrow />
								</button>
							)}
							<Tab title={t("live_page.advanced_settings")} number={8} />
						</>
					)}
				</>
			)}
		</ul>
	);
};

export default Tabs;
