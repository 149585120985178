import { twMerge } from "tailwind-merge";
import Funny from "./../../../../../assets/Funny.svg";
import Insightful from "./../../../../../assets/Insightful.svg";
import Love from "./../../../../../assets/Love.svg";
import RepliedToIcon from "../../../../../assets/RepliedToIcon";
import { IMessage, IReaction } from "../../../../hooks/messages/types";
import LinkPreview from "../../../../../components/LinkPreview/LinkPreview";

interface IProps {
	message: string;
	subscriberId: number;
	userId: number | undefined;
	repliedTo?: IMessage;
	reactions: IReaction[];
}

const getImage = (interaction: string) => {
	let image = "";
	switch (interaction) {
		case "funny":
			image = Funny;
			break;
		case "insightful":
			image = Insightful;
			break;
		case "love":
			image = Love;
			break;
		default:
			break;
	}
	return image;
};

const MessageContent = (props: IProps) => {
	const LinkPreviews = props?.message
		?.split(" ")
		?.filter((word) => word.startsWith("http") || word.startsWith("https"))
		?.map((word, index) => {
			// this is a bad practice but we don't have unique identifiers and you can use array index when you are sure that the array will not change/shift
			return (
				<LinkPreview
					url={word}
					key={`${word} ${index}`}
					loaderColor={props.subscriberId === props.userId ? "white" : "#00A6D9"}
				/>
			);
		});
	return (
		<div
			className={twMerge(
				`relative mb-2 flex w-fit flex-col  flex-wrap rounded-lg`,
				props.subscriberId === props.userId ? "rounded-tr-none bg-rte " : "rounded-tl-none bg-white"
			)}
		>
			{props.repliedTo ? (
				<div className="flex flex-col  gap-1 border-b-[1px] pb-2 italic">
					<div className="flex items-center gap-1">
						<RepliedToIcon isMe={props.subscriberId === props.userId} />
						<span className="font-medium text-text">{props.repliedTo.subscriber.lastName}</span>
					</div>
					<span
						className={twMerge(
							"text-sm font-medium text-text",
							props.subscriberId === props.userId && "text-white"
						)}
					>
						{props.repliedTo.message}
					</span>
				</div>
			) : null}
			<span
				className={twMerge(
					`relative mb-2  w-auto max-w-[15rem] whitespace-pre-line rounded-lg  p-2 text-text md:max-w-sm lg:max-w-3xl`,
					props.repliedTo && "pt-2",
					props.subscriberId === props.userId && "text-white"
				)}
				style={{
					wordBreak: "break-word",
				}}
			>
				{props?.message?.split(" ")?.map((word, index) => {
					if (word.startsWith("http") || word.startsWith("https")) {
						return (
							<a className="underline" href={word} key={`${word} ${index}`} target="_blank">
								{word}
							</a>
						);
					}
					return word + " ";
				})}
				{LinkPreviews}
			</span>
			{props?.reactions?.length > 0 ? (
				<div
					className={twMerge(
						`absolute -bottom-7 flex items-center justify-center gap-1 rounded-lg border border-white bg-rteTransparent p-1`,
						props.subscriberId === props.userId ? "right-0" : "left-0"
					)}
				>
					{props.reactions.map((reaction) => {
						if (reaction.subscribers.length === 0) return null;
						return (
							<div
								key={reaction.reaction}
								className="flex w-7 items-center gap-1"
								title={reaction.reaction}
							>
								<img src={getImage(reaction.reaction)} alt={reaction.reaction} className="h-3" />
								<span>{reaction.subscribers.length}</span>
							</div>
						);
					})}
				</div>
			) : null}
		</div>
	);
};

export default MessageContent;
