import { useLocation, Outlet, Navigate, useParams } from "react-router-dom";
import useGetUserDetails from "../AuthenticationPage/hooks/useGetUserDetails";
import { useEffect } from "react";
import { useStore } from "../store/store";
import Loader from "./Loader/Loader";
import useGetLiveDetails from "../SignupPage/hooks/useGetLiveDetails";
export default function RequireAuth() {
	const { liveToken } = useParams();
	const setUser = useStore((state) => state.setUser);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	const userDetails = useGetUserDetails(token, "subscriber");
	const liveDetails = useGetLiveDetails(liveToken);
	const liveId = liveDetails.data?.live.id;
	const user = useStore((state) => state.user);
	useEffect(() => {
		if (userDetails.data) {
			setUser(userDetails.data);
		}
	}, [userDetails.data]);

	if (userDetails.isInitialLoading || liveDetails.isInitialLoading) {
		return (
			<div className="grid h-screen place-items-center">
				<Loader />
			</div>
		);
	}

	if (userDetails.error || liveDetails.error) {
		return <Navigate to={`/${liveToken}`} replace state={location} />;
	}

	if (!liveDetails.data?.live.subscription) {
		return <Outlet />;
	}

	if (userDetails.data?.role === "participant") {
		return <Navigate to={`/${liveToken}`} replace />;
	}
	if (userDetails.data?.role !== "moderator" && liveId !== userDetails.data?.liveId) {
		return <Navigate to={`/${liveToken}`} replace />;
	}
	if (user) {
		return <Outlet />;
	} else {
		return (
			<div className="grid h-screen place-items-center">
				<Loader />
			</div>
		);
	}
}
