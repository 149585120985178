import { twMerge } from "tailwind-merge";
import { useStore } from "../../../../store/store";
import { useTranslation } from "react-i18next";
import LikeIcon from "../../../../assets/LikeIcon";
import LinkPreview from "../../../../components/LinkPreview/LinkPreview";

interface IProps {
	question: string;
	questionToBeAnswered?: boolean;
	isAnswer?: boolean;
	subscriberId: number;
	userId: number | undefined;
	children?: React.ReactNode;
	answersLength?: number;
	questionId: number | undefined;
	likes: number;
}

const Content = (props: IProps) => {
	const { t } = useTranslation();
	const approvedAnswers = useStore((state) => state.approvedAnswers).filter(
		(answer) => answer.questionId === props.questionId
	);
	const LinkPreviews = props?.question
		?.split(" ")
		?.filter((word) => word.startsWith("http") || word.startsWith("https"))
		?.map((word, index) => {
			// this is a bad practice but we don't have unique identifiers and you can use array index when you are sure that the array will not change/shift
			return (
				<LinkPreview
					url={word}
					key={`${word} ${index}`}
					loaderColor={!props.isAnswer && props.subscriberId === props.userId ? "white" : "#00A6D9"}
				/>
			);
		});

	return (
		<div
			className={twMerge(
				"group flex  items-center gap-2  text-white ",
				!props.isAnswer && props.subscriberId === props.userId ? "flex-row-reverse  self-end" : ""
			)}
		>
			<div className="flex flex-col">
				<div
					className={twMerge(
						"flex items-center gap-3",
						props.subscriberId !== props.userId && "flex-row-reverse"
					)}
				>
					<div>{props.children}</div>
					<div
						className={twMerge(
							` relative mb-2 flex w-auto max-w-[15rem] whitespace-pre-line  rounded-lg p-3 md:max-w-sm lg:max-w-3xl`,
							!props.isAnswer && props.subscriberId === props.userId
								? "rounded-tr-none bg-rte "
								: "rounded-tl-none  bg-white text-text",
							props.questionToBeAnswered && "bg-white text-sm"
						)}
					>
						<span
							style={{
								wordBreak: "break-word",
								fontWeight: "400",
							}}
						>
							<span>
								{props?.question?.split(" ")?.map((word, index) => {
									if (word.startsWith("http") || word.startsWith("https")) {
										return (
											<a className="underline" href={word} key={`${word} ${index}`} target="_blank">
												{word}
											</a>
										);
									}
									return word + " ";
								})}

								{LinkPreviews}
							</span>
						</span>
					</div>
				</div>

				<div
					className={twMerge(
						" flex items-center gap-2",
						props.subscriberId === props.userId ? "self-end" : "self-start"
					)}
				>
					{props?.likes && !props.questionToBeAnswered ? (
						<span className="flex w-auto items-center  justify-center gap-1 rounded-lg border border-rte  p-1 text-sm text-text ">
							<span>{props.likes}</span>
							<LikeIcon active={true} />
						</span>
					) : null}
					{props?.answersLength ? (
						<span className="w-auto items-center gap-1  rounded-lg  border border-rte  p-1 text-sm text-text ">
							<span>
								{props?.answersLength}{" "}
								{props?.answersLength > 1 || approvedAnswers?.length > 1
									? t("live_page.replies")
									: t("live_page.one_reply")}
							</span>
						</span>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Content;
