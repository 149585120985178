import { useTranslation } from "react-i18next";

const Error = () => {
	const { t } = useTranslation();
	return (
		<div className="grid h-screen place-items-center">
			<h1 className=" text-5xl text-red-600 "> {t("live_page.server_error")}</h1>
		</div>
	);
};

export default Error;
