import classes from "./Loader.module.css";
const Loader = () => {
	return (
		<div className="flex  items-center justify-center">
			<div className={classes["lds-ripple"]}>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Loader;
