import { useEffect, useRef, useState } from "react";
import SideBarInput from "../SideBarInput/SideBarInput";
import { useStore } from "../../../store/store";
import Message from "./Message/Message";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import ReplyToMessagePopUp from "./Message/ReplyToMessagePopUp";
import useCreateMessage from "../../hooks/useCreateMessage";
import { SocketEvents } from "../../hooks/useSocket/types";
import { IMessage } from "../../hooks/messages/types";
import Dialogue from "../Dialogue/Dialogue";
import NoMessages from "./NoMessages";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";

interface IProps {
	liveId: number | undefined;
	chatType: "backstage" | "general";
	messages: IMessage[] | undefined;
	hasMore: boolean | undefined;
	isLoading: boolean | undefined;
	liveWithSubscription: boolean | undefined;
}

const Chat = (props: IProps) => {
	const { t } = useTranslation();
	const sideBarInput = useStore((state) => state.sideBarInput);
	const setSideBarInput = useStore((state) => state.setSideBarInput);
	const setNextPage = useStore((state) => state.setNextPage);
	const setBackstageNextPage = useStore((state) => state.setBackstageNextPage);
	const socket = useStore((state) => state.socket);
	const user = useStore((state) => state.user);
	const userId = user?.id;
	const messagesRef = useRef<VirtuosoHandle>(null);
	const messageToReply = useStore((state) => state.messageToReply);
	const setMessageToReply = useStore((state) => state.setMessageToReply);
	const createMessageMutation = useCreateMessage();
	const [moreMessages, setMoreMessages] = useState(false);
	const [atBottom, setAtBottom] = useState(false);

	useEffect(() => {
		if (createMessageMutation.isSuccess) {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.chat,
						data: createMessageMutation.data.data.message,
						...(props.chatType === "backstage" && { backstage: true }),
					})
				);
				setSideBarInput("");
			}
		}
	}, [createMessageMutation.isSuccess]);

	const sendMessage = () => {
		if (sideBarInput.trim() === "") return;
		createMessageMutation.mutate({
			message: sideBarInput.trim(),
			liveId: Number(props.liveId),
			...(user?.role !== "moderator" && { subscriberId: userId }),
			...(user?.role === "moderator" && { moderatorId: userId }),
			...(messageToReply && { replyMessageId: messageToReply?.id }),
			type: props.chatType,
		});
		if (messageToReply) {
			setMessageToReply(null);
		}
	};

	useEffect(() => {
		if (messagesRef?.current && messageToReply && props.messages) {
			messagesRef.current.scrollToIndex({
				index: props.messages.length - 1,
				behavior: "smooth",
			});
		}
		if (!atBottom && props.messages?.length) {
			setMoreMessages(true);
		} else {
			setMoreMessages(false);
		}
	}, [props?.messages?.length, messageToReply, atBottom]);

	return (
		<div className=" flex flex-grow flex-col overflow-x-hidden">
			<div className="relative flex flex-grow flex-col p-2">
				{props.isLoading ? (
					<Loader />
				) : (
					props?.hasMore && (
						<button
							className="absolute z-50 w-full items-center justify-center text-text underline"
							onClick={() => {
								if (props.chatType === "backstage") {
									setBackstageNextPage();
								} else {
									setNextPage();
								}
							}}
						>
							{t("live_page.load_more")}
						</button>
					)
				)}

				{props.messages?.length ? (
					<Virtuoso
						className="h-full"
						data={[...props.messages]?.reverse()}
						totalCount={props.messages.length}
						ref={messagesRef}
						atBottomStateChange={(bottom) => {
							setAtBottom(bottom);
						}}
						initialTopMostItemIndex={props.messages.length}
						itemContent={(_, message) => {
							return (
								<Message
									key={message.id}
									subscriberId={
										message.subscriber ? message?.subscriber?.id : message?.moderator?.id
									}
									userId={userId}
								>
									<Message.Sender
										createdAt={message.createdAt}
										subscriberId={
											message?.subscriber ? message?.subscriber?.id : message?.moderator?.id
										}
										userId={userId}
										lastName={
											message?.subscriber
												? message?.subscriber?.lastName
												: message?.moderator?.username
										}
										type="message"
									/>
									<Message.Content
										message={message.message}
										subscriberId={
											message?.subscriber ? message?.subscriber?.id : message?.moderator?.id
										}
										userId={userId}
										reactions={message?.reactions}
										id={message.id}
										liveId={props.liveId}
										lastName={
											message?.subscriber
												? message?.subscriber?.lastName
												: message?.moderator?.username
										}
										repliedTo={message?.repliedTo}
									/>
								</Message>
							);
						}}
					/>
				) : (
					<NoMessages />
				)}
				{moreMessages ? (
					<div className="sticky  bottom-1  flex w-full items-center  justify-center transition-all">
						<button
							onClick={() => {
								if (props.messages) {
									messagesRef.current?.scrollToIndex({
										index: props.messages?.length,
										behavior: "smooth",
									});
								}

								setMoreMessages(false);
							}}
							className=" cursor-pointer rounded-full bg-rte p-2 text-white"
						>
							{t("live_page.scroll_to_bottom")}
						</button>
					</div>
				) : null}
				{messageToReply && (user?.role !== "listener" || props.liveWithSubscription) ? (
					<ReplyToMessagePopUp />
				) : null}
			</div>

			{!props.liveWithSubscription && (!user?.role || user?.role === "listener") ? (
				<Dialogue type="message" />
			) : (
				<SideBarInput
					sendFunction={sendMessage}
					placeholder={t("live_page.message_input_placeholder")}
					isLoading={createMessageMutation.isLoading}
				/>
			)}
		</div>
	);
};

export default Chat;
