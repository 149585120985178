import { useTranslation } from "react-i18next";
import RegisterSuccess from "./../../assets/RegisterSuccess.svg";
interface IProps {
	email: string;
}

const SignupSucceded = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<div className="flex h-full w-full flex-col items-center justify-start gap-5 text-center">
			<img
				src={RegisterSuccess}
				className="h-44 w-44 lg:h-56 lg:w-56"
				alt="Registration succeded"
			/>
			<div className="flex flex-col gap-4">
				<h3 className="text-xl font-semibold text-rte">{t("signup_page.registration_succeded")}</h3>
				<p className="font-normal text-[#BABABA]">
					{t("signup_page.verify_email")}
					<span className=" font-semibold text-white"> {props.email}.</span>
				</p>
			</div>
		</div>
	);
};

export default SignupSucceded;
