interface IProps {
	isMe: boolean;
}
const RepliedToIcon = (props: IProps) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill={props.isMe ? "#C4C4C8" : "#00A6D9"}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_583_192)">
				<path
					d="M6.04598 11.6776C6.88355 10.2627 8.16288 9.16221 9.68709 8.54554C11.2113 7.92886 12.8959 7.83016 14.4817 8.26463C16.0675 8.69909 17.4666 9.64264 18.4637 10.9501C19.4608 12.2575 20.0005 13.8564 20 15.5006C20 15.7658 20.1053 16.0202 20.2929 16.2077C20.4804 16.3953 20.7348 16.5006 21 16.5006C21.2652 16.5006 21.5196 16.3953 21.7071 16.2077C21.8946 16.0202 22 15.7658 22 15.5006C22.0001 13.4893 21.3619 11.5299 20.1773 9.90455C18.9927 8.27917 17.3227 7.0717 15.408 6.45603C13.4933 5.84037 11.4326 5.84827 9.52265 6.47861C7.61271 7.10895 5.9521 8.32919 4.77998 9.96361L4.24298 6.91861C4.19697 6.65737 4.04906 6.42511 3.8318 6.27293C3.61454 6.12074 3.34572 6.0611 3.08448 6.10711C2.82324 6.15313 2.59098 6.30103 2.4388 6.51829C2.28661 6.73556 2.22697 7.00437 2.27298 7.26561L3.31498 13.1746C3.36135 13.4357 3.50955 13.6678 3.72698 13.8196C3.86867 13.9146 4.03052 13.9754 4.19974 13.9971C4.36896 14.0188 4.5409 14.0008 4.70198 13.9446L10.382 12.9436C10.6432 12.8976 10.8755 12.7497 11.0277 12.5324C11.1798 12.3152 11.2395 12.0463 11.1935 11.7851C11.1475 11.5239 10.9996 11.2916 10.7823 11.1394C10.565 10.9872 10.2962 10.9276 10.035 10.9736L6.04598 11.6776Z"
					fill={props.isMe ? "#C4C4C8" : "#00A6D9"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_583_192">
					<rect width="24" height="24" fill={props.isMe ? "#C4C4C8" : "#00A6D9"} />
				</clipPath>
			</defs>
		</svg>
	);
};

export default RepliedToIcon;
