import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../store/store";
import useGetQuestion from "../../hooks/useGetQuestion";
import Loader from "../../../components/Loader/Loader";
import Question from "./Question/Question";
import { IQuestions } from "../../hooks/useGetQuestions";
import SideBarInput from "../SideBarInput/SideBarInput";
import { useTranslation } from "react-i18next";
import useCreateQuestion from "../../hooks/useCreateQuestion";
import { SocketEvents } from "../../hooks/useSocket/types";
import Dialogue from "../Dialogue/Dialogue";
import { twMerge } from "tailwind-merge";

interface IProps {
	liveId: number | undefined;
	liveWithSubscription: boolean | undefined;
}

const QuestionToAnswer = (props: IProps) => {
	const { t } = useTranslation();
	const socket = useStore((state) => state.socket);
	const questionToAnswerId = useStore((state) => state.questionToAnswer);
	const setQuestionToAnswerId = useStore((state) => state.setQuestionToAnswer);
	const approvedAnswers = useStore((state) => state.approvedAnswers);
	const setApprovedAnswers = useStore((state) => state.setApprovedAnswers);
	const questionQuery = useGetQuestion(props.liveId, questionToAnswerId);
	const user = useStore((state) => state.user);
	const userId = user?.id;
	const [questionToBeAnswered, setQuestionToBeAnswered] = useState<IQuestions | null>(null);
	const sideBarInput = useStore((state) => state.sideBarInput);
	const approvedQuestions = useStore((state) => state.approvedQuestions);
	const setSideBarInput = useStore((state) => state.setSideBarInput);
	const createQuestionMutation = useCreateQuestion(props.liveId);
	const isCommentValidation = useStore((state) => state.isCommentValidation);

	useEffect(() => {
		if (questionQuery.data) {
			setQuestionToBeAnswered(approvedQuestions.find((q) => q.id === questionToAnswerId) ?? null);
			const answers = questionQuery.data.filter((q) => {
				return q.type === "answer";
			});
			setApprovedAnswers(answers);
		}
	}, [questionQuery.data?.length]);
	useEffect(() => {
		if (createQuestionMutation.isSuccess) {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.question,
						data: createQuestionMutation.data.data.question,
					})
				);
				setSideBarInput("");
			}
		}
	}, [createQuestionMutation.isSuccess, createQuestionMutation.data?.data.question]);
	const sendAnswer = () => {
		if (sideBarInput.trim() === "") return;
		createQuestionMutation.mutate({
			question: sideBarInput.trim(),
			liveId: Number(props.liveId),
			...(user?.role !== "moderator" && { subscriberId: userId }),
			...(user?.role === "moderator" && { moderatorId: userId }),
			type: "answer",
			questionId: questionToAnswerId,
			approved: !isCommentValidation,
		});
	};
	const questionsRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (questionsRef?.current) {
			questionsRef.current.scrollTop = questionsRef.current.scrollHeight;
		}
	}, [approvedAnswers]);

	useEffect(() => {
		return () => {
			setQuestionToAnswerId(null);
		};
	}, []);
	if (questionQuery.isLoading) return <Loader />;
	return (
		<div ref={questionsRef} className=" flex flex-grow flex-col overflow-y-auto overflow-x-hidden">
			<div className="flex flex-grow flex-col overflow-y-auto overflow-x-hidden p-2">
				{questionToBeAnswered ? (
					<Question
						subscriberId={
							questionToBeAnswered?.subscriber
								? questionToBeAnswered?.subscriberId
								: questionToBeAnswered?.moderatorId
						}
						userId={userId}
						isAnswer={true}
					>
						<Question.Sender
							createdAt={questionToBeAnswered.createdAt}
							subscriberId={
								questionToBeAnswered?.subscriber
									? questionToBeAnswered?.subscriberId
									: questionToBeAnswered?.moderatorId
							}
							userId={userId}
							email={
								questionToBeAnswered?.subscriber
									? questionToBeAnswered?.subscriber?.email
									: questionToBeAnswered?.moderator?.email
							}
							lastName={
								questionToBeAnswered.subscriber
									? questionToBeAnswered?.subscriber?.liveSubscribers &&
										questionToBeAnswered?.subscriber?.liveSubscribers?.length === 1
										? questionToBeAnswered?.subscriber?.liveSubscribers[0]?.lastName
										: questionToBeAnswered?.subscriber?.lastName
									: questionToBeAnswered?.moderator?.username
							}
							isAnswer={true}
							type="question"
						/>
						<Question.Content
							question={questionToBeAnswered.question}
							subscriberId={
								questionToBeAnswered?.subscriber
									? questionToBeAnswered?.subscriberId
									: questionToBeAnswered?.moderatorId
							}
							userId={userId}
							questionId={questionToBeAnswered.id}
							questionToBeAnswered={true}
							isAnswer={true}
							likes={questionToBeAnswered.likes}
						/>
					</Question>
				) : null}
				<div className="flex-grow overflow-y-auto">
					{approvedAnswers
						?.filter((answer) => {
							return (
								(answer.questionId === questionToAnswerId && answer.approved === true) ||
								answer?.subscriberId === userId ||
								answer?.moderatorId === userId
							);
						})
						?.map((answer) => {
							const AnswerComponent = (
								<div
									className={twMerge(
										" border-l-2 px-2",
										props.liveWithSubscription ? "border-secondary" : "border-rte"
									)}
								>
									<Question
										key={answer.id}
										subscriberId={answer?.subscriber ? answer?.subscriberId : answer?.moderatorId}
										userId={userId}
										isAnswer={true}
									>
										<Question.Sender
											createdAt={answer.createdAt}
											subscriberId={answer?.subscriber ? answer?.subscriberId : answer?.moderatorId}
											email={
												answer?.subscriber ? answer.subscriber.email : answer?.moderator?.email
											}
											userId={userId}
											lastName={
												answer?.subscriber
													? answer?.subscriber?.liveSubscribers &&
														answer?.subscriber?.liveSubscribers?.length === 1
														? answer?.subscriber?.liveSubscribers[0]?.lastName
														: answer?.subscriber?.lastName
													: answer?.moderator?.username
											}
											isAnswer={true}
											type="question"
										/>
										<Question.AnswerContent
											question={answer.question}
											subscriberId={answer?.subscriber ? answer?.subscriberId : answer?.moderatorId}
											userId={userId}
											questionId={questionToBeAnswered?.id}
										></Question.AnswerContent>
									</Question>
								</div>
							);
							if (
								isCommentValidation &&
								!answer.approved &&
								(answer?.subscriberId === userId || answer?.moderatorId === userId)
							) {
								return (
									<div key={answer.id} className=" mt-2 rounded-lg  bg-white p-2">
										<span className=" text-text">{t("live_page.answer_prompt")}</span>
										{AnswerComponent}
									</div>
								);
							} else {
								return <div key={answer.id}>{AnswerComponent}</div>;
							}
						})}
				</div>
			</div>
			{!props.liveWithSubscription && (!user?.role || user?.role === "listener") ? (
				<Dialogue type="answer" />
			) : (
				<SideBarInput
					sendFunction={sendAnswer}
					placeholder={t("live_page.answer_input_placeholder")}
					isLoading={createQuestionMutation.isLoading}
				/>
			)}
		</div>
	);
};

export default QuestionToAnswer;
