import OpenMenu from "./../../assets/OpenMenu.svg";
import ClosedMenu from "./../../assets/ClosedMenu.svg";
import Menu from "./Menu";
import { useStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
interface IProps {
	title: string | undefined;
}

const InteractionHeader = (props: IProps) => {
	const { t } = useTranslation();
	const showMenu = useStore((state) => state.showMenu);
	const toggleMenu = useStore((state) => state.toggleMenu);
	const user = useStore((state) => state.user);
	return (
		<header className="flex h-20 w-full items-center justify-between bg-rte p-3 lg:px-6">
			<div className="flex items-center gap-16">
				<button
					onClick={() => {
						toggleMenu();
					}}
					className="lg:hidden"
				>
					<img src={showMenu ? OpenMenu : ClosedMenu} alt="Menu" />
				</button>
				{showMenu ? <Menu /> : null}
				<div className="hidden h-16 lg:block">
					<Logo />
				</div>
				<span className="group relative ">
					{props.title ? (
						<h1 className="max-w-[7rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-bold text-white sm:max-w-xs md:max-w-md lg:max-w-lg lg:text-lg xl:max-w-xl 2xl:max-w-7xl">
							{props.title}
						</h1>
					) : null}
					<h2 className="absolute left-1/2 top-7 z-30 hidden -translate-x-1/2 rounded-lg bg-[#ffffffe7] p-3 text-rte group-hover:flex">
						{props.title}
					</h2>
				</span>
			</div>
			<div className="hidden items-center gap-3 lg:flex">
				<div className=" h-14  w-14 items-center justify-center rounded-[100%] bg-[#D9D9D9] p-4 lg:flex">
					<span className="hidden text-xl text-[#62626B] lg:block">
						{user?.name && user.lastName
							? user.name[0].toUpperCase() + user.lastName[0].toUpperCase()
							: user?.name[0].toUpperCase()}
					</span>
				</div>
				<span className=" flex flex-col text-white">
					<span className="text-[#C4C4C8]">{t("admin_page.hello")}</span>
					<span className=" font-bold">{user?.userName}</span>
				</span>
			</div>

			<div className="h-14 w-15 lg:hidden">
				<Logo/>
			</div>
		</header>
	);
};

export default InteractionHeader;
