import { Route, Routes } from "react-router-dom";
import SignupPage from "./SignupPage/SignupPage";
import LivePage from "./LivePage/LivePage";
import RequireAuth from "./components/RequireAuth";
import InteractionRequireAuth from "./components/InteractionRequireAuth";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import InteractionPage from "./InteractionPage/InteractionPage";
import { Helmet } from "react-helmet";
import { useStore } from "./store/store";
import PrompterPage from "./PrompterPage/PrompterPage";
import { useEffect } from "react";
import LiveRteWaitBug from "./assets/WaitLiveBug.png";
function App() {
	const live = useStore((state) => state.live);
	const hostname = window.location.href;
	let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
	const imageUrl = `https://${window.process.env.FO_CLIENT}/logo/${window.process.env.CLIENT_NAME}.png`;
	if (!link) {
		link = document.createElement("link");
		link.rel = "icon";
		document.head.appendChild(link);
	}
	link.href = imageUrl;
	const preloadImages = (images : string[]) => {
		images.forEach((src) => {
			const img = new Image();
			img.src = src;
		});
	};
	useEffect(() => {
		preloadImages([
			LiveRteWaitBug
		]);
	}, []);
	return (
		<div className="min-h-screen">
			<Helmet>
				<title>{live?.title}</title>
				<meta name="description" content={live?.description} />
				<meta name="twitter:card" content={live?.description} />
				<meta property="og:title" content={live?.title} />
				<meta property="og:description" content={live?.description} />
				<meta property="og:keywords" content={live?.tags} />
				<meta property="og:url" content={hostname} />
				<meta property="og:image" content={live?.thumbnail} />
				<meta property="og:type" content="webinaire" />
			</Helmet>
			<Routes>
				<Route path="/*" element={<NotFoundPage />} />
				<Route path="/404" element={<NotFoundPage />} />
				<Route path="/:liveToken" element={<SignupPage />} />
				<Route path="/moderator/:liveToken" element={<SignupPage />} />
				<Route path="/journalist/:liveToken" element={<SignupPage />} />
				<Route path="/prompter/:liveToken" element={<PrompterPage />} />

				<Route element={<InteractionRequireAuth />}>
					<Route path="/live-participant/:liveToken" element={<InteractionPage />} />
				</Route>
				<Route element={<RequireAuth />}>
					<Route path="/live/:liveToken" element={<LivePage />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
