import { useEffect } from "react";
import { useStore } from "../store/store";
import useGetQuestions from "../LivePage/hooks/useGetQuestions";
import { IMessagesResponse } from "../LivePage/hooks/messages/types";
import useGetApprovedQuestions from "../LivePage/hooks/useGetApprovedQuestions";
import { IUser } from "../InteractionPage/store/store";

const usePopulateQuestionsAndMessages = (
	liveId: number | undefined,
	user: IUser | null,
	messagesQuery: IMessagesResponse | undefined,
	backStageMessagesQuery: IMessagesResponse | undefined
) => {
	const activeTab = useStore((state) => state.activeTab);
	const setMessages = useStore((state) => state.setMessages);
	const setBackStageMessages = useStore((state) => state.setBackstageMessages);
	useGetQuestions(liveId);
	useGetApprovedQuestions(liveId, user);
	const setMessagesNotifications = useStore((state) => state.setMessagesNotifications);
	const setBackstageMessagesNotifications = useStore(
		(state) => state.setBackstageMessagesNotifications
	);

	useEffect(() => {
		if (backStageMessagesQuery?.messages) {
			setBackStageMessages(backStageMessagesQuery.messages);
			if (activeTab !== 7) {
				setBackstageMessagesNotifications(backStageMessagesQuery?.messages?.length);
			}
		}
	}, [backStageMessagesQuery]);

	useEffect(() => {
		if (messagesQuery?.messages) {
			setMessages(messagesQuery?.messages);
			if (activeTab !== 1) {
				setMessagesNotifications(messagesQuery?.messages.length);
			}
		}
	}, [messagesQuery]);
};

export default usePopulateQuestionsAndMessages;
