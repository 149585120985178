interface IProps {
	active: boolean;
}
const LikeIcon = (props: IProps) => {
	return (
		<svg width="18" height="17" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18 7.2C18 6.77565 17.8276 6.36869 17.5207 6.06863C17.2138 5.76857 16.7976 5.6 16.3636 5.6H11.1927L11.9782 1.944C11.9945 1.864 12.0027 1.776 12.0027 1.688C12.0027 1.36 11.8636 1.056 11.6427 0.84L10.7755 0L5.39182 5.264C5.08909 5.56 4.90909 5.96 4.90909 6.4V14.4C4.90909 14.8243 5.08149 15.2313 5.38837 15.5314C5.69525 15.8314 6.11146 16 6.54545 16H13.9091C14.5882 16 15.1691 15.6 15.4145 15.024L17.8855 9.384C17.9591 9.2 18 9.008 18 8.8V7.2ZM0 16H3.27273V6.4H0V16Z"
				fill={props.active ? "#00A6D9" : "#9A9A9A"}
			/>
		</svg>
	);
};

export default LikeIcon;
