import { useState } from "react";
import ArrowDown from "../../../../assets/ArrowDown";
import Filter from "./Filter";
import { useStore } from "../../../../store/store";
import { useTranslation } from "react-i18next";

const QuestionsFilter = () => {
	const { t } = useTranslation();
	const [showFilters, setShowFilters] = useState(false);
	const questionsFilter = useStore((state) => state.questionsFilter);
	const setQuestionsFilter = useStore((state) => state.setQuestionsFilter);

	let text = "";
	switch (questionsFilter) {
		case "recent":
			text = t("live_page.recent_questions");
			break;
		case "oldest":
			text = t("live_page.oldest_questions");
			break;
		case "likes":
			text = t("live_page.most_liked_questions");
			break;
	}

	const resetFilters = () => {
		setQuestionsFilter("recent");
	};

	return (
		<div className="relative min-w-[16.5%] rounded-lg border-2 border-rte bg-white p-2">
			<button
				className="w-full overflow-hidden"
				onClick={() => {
					setShowFilters((prev) => !prev);
				}}
			>
				<span className="flex w-full items-center justify-between overflow-hidden text-ellipsis whitespace-nowrap text-text">
					{text}
					<ArrowDown />
				</span>
			</button>
			{showFilters ? (
				<div className="absolute right-0 top-12 z-50 flex min-w-full flex-col items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-white shadow-lg">
					<span className="block w-full overflow-hidden text-ellipsis whitespace-nowrap border-b p-2 text-center text-lg font-semibold text-rte">
						Filtrer les questions
					</span>
					<ul className="flex w-full flex-col items-center justify-center">
						<Filter text={t("live_page.recent_questions")} value="recent" />
						<Filter text={t("live_page.oldest_questions")} value="oldest" />
						<Filter text={t("live_page.most_liked_questions")} value="likes" />
					</ul>
					<button className="mb-2 w-[90%] rounded-lg bg-rte p-2 text-white" onClick={resetFilters}>
						{t("moderator_page.reset_filter_question")}
					</button>
				</div>
			) : null}
		</div>
	);
};

export default QuestionsFilter;
