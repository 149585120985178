import { create } from "zustand";
import { ISignupSlice, signupSlice } from "../SignupPage/store/store";
import { livePageSlice } from "../LivePage/store/store";
import {
	IQuestionWithoutRegisterSlice,
	questionWithoutRegisterSlice,
} from "../LivePage/store/QuestionWithoutRegisterStore";
import { IInteractionPageSlice, interactionPageSlice } from "../InteractionPage/store/store";
import { ILivePageSlice } from "../LivePage/store/types";

export const useStore = create<
	ISignupSlice & ILivePageSlice & IInteractionPageSlice & IQuestionWithoutRegisterSlice
>((...a) => ({
	...signupSlice(...a),
	...livePageSlice(...a),
	...interactionPageSlice(...a),
	...questionWithoutRegisterSlice(...a),
}));
