import { twMerge } from "tailwind-merge";
import { useStore } from "../../../store/store";
import DialogueButton from "./DialogueButton";
import DialogueForm from "./DialogueForm";
import { IProps } from "./types";
import LiveEnded from "./LiveEnded";
import { useTranslation } from "react-i18next";
import ReplyToMessagePopUp from "../Chat/Message/ReplyToMessagePopUp";
const Dialogue = (props: IProps) => {
	const showForm = useStore((state) => state.showForm);
	const { t } = useTranslation();
	const liveActive = useStore((state) => state.liveActive);
	const liveStatus = useStore((state) => state.status);
	const canCommentBeforeLive = useStore((state) => state.canCommentBeforeLive);
	const messageToReply = useStore((state) => state.messageToReply);
	const activeTab = useStore((state) => state.activeTab);
	if (
		liveActive === 0 &&
		liveStatus === -1 &&
		!canCommentBeforeLive &&
		(activeTab === 2 || activeTab === 4)
	) {
		return <LiveEnded text={t("live_page.sidebar_live_not_started")} />;
	}

	if (liveActive === 0 && liveStatus === 1) {
		return <LiveEnded text={t("live_page.sidebar_live_ended")} />;
	}
	return (
		<>
			{messageToReply ? <ReplyToMessagePopUp /> : null}

			<div
				className={twMerge(
					"flex w-full  flex-col items-center justify-end gap-3 self-end rounded-lg",
					showForm && "bg-white"
				)}
				style={{
					boxShadow: showForm ? "0px -1px 10px rgba(0, 0, 0, 0.2)" : "",
				}}
			>
				<DialogueButton type={props.type} />
				{showForm && (
					<div className="w-full p-2 ">
						<DialogueForm type={props.type} />
					</div>
				)}
			</div>
		</>
	);
};

export default Dialogue;
