import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../store/store";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const exportQuestions = async (liveId: number | null, fileName: string, errorMessage: string) => {
	try {
		let endpoint = `/live/questions/export/${liveId}`;
		const signedUrl = await generateSignedEndpoint(endpoint);
		const response = await axios.get(signedUrl, {
			headers: {
				"Content-Type": "application/json",
			},
			responseType: "blob",
		});

		const strMimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		const downloadLink = document.createElement("a");
		downloadLink.href = window.URL.createObjectURL(
			new Blob([response.data], { type: strMimeType })
		);
		downloadLink.download = fileName;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		return response.data;
	} catch (_) {
		toast.error(errorMessage);
	}
};
const useExportQuestions = () => {
	const liveId = useStore((state) => state.liveId);
	const { t } = useTranslation();
	const fileName = `${t("live_page.exported_question's_file_name")} ${liveId}.xlsx`;
	const errorMessage = t("errors.export_questions");
	const exportQuery = useQuery({
		queryKey: ["exportQuestions", liveId],
		queryFn: () => exportQuestions(liveId, fileName, errorMessage),
		retry: false,
		enabled: false,
	});

	return exportQuery;
};

export default useExportQuestions;
