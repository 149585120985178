import { useState } from 'react';
import { twMerge } from "tailwind-merge";
import RteLOGO from "./../assets/RTELogo.svg";

const Logo = () => {
    const [imageUrl, setImageUrl] = useState(getInitialImageUrl());
  
    function getInitialImageUrl() {
        return  window.process.env.CLIENT_NAME.includes("rte") ? RteLOGO : 
             `https://${window.process.env.FO_CLIENT}/logo.png`
        }

    function handleImageError() {
        if (window.process.env.CLIENT_NAME.includes("rte")) {
            const newImageUrl = `https://${window.process.env.FO_CLIENT}/logo/${window.process.env.CLIENT_NAME}.png`;
            setImageUrl(newImageUrl);
        }
    }

    return (
        <div className={twMerge("flex h-full w-full items-center gap-2 p-1 text-white")}>
            <img
                src={imageUrl}
                onError={handleImageError}
                alt={window.process.env.CLIENT_NAME + " logo"}
                className="h-full"
            />
            <span
                className={twMerge(
                    "flex min-h-full flex-col justify-center whitespace-nowrap text-xs font-medium sm:text-base"
                )}
            >
            </span>
        </div>
    );
};

export default Logo;
