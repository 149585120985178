const CheckQuestion = () => {
	return (
		<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.7253 0.305528C14.9012 0.501216 15 0.766591 15 1.04329C15 1.32 14.9012 1.58537 14.7253 1.78106L5.81208 11.6945C5.63614 11.8901 5.39754 12 5.14875 12C4.89997 12 4.66137 11.8901 4.48542 11.6945L0.263374 6.99865C0.0924668 6.80184 -0.00210222 6.53824 3.54675e-05 6.26463C0.00217316 5.99103 0.100846 5.7293 0.274802 5.53582C0.448758 5.34235 0.684079 5.2326 0.930081 5.23022C1.17608 5.22785 1.41308 5.33303 1.59003 5.52311L5.14875 9.48117L13.3986 0.305528C13.5746 0.109899 13.8132 0 14.062 0C14.3108 0 14.5494 0.109899 14.7253 0.305528Z"
				fill="white"
			/>
		</svg>
	);
};

export default CheckQuestion;
