import { useStore } from "../store/store";

const PromptedQuestion = () => {
	const broadcastedQuestion = useStore((state) => state.broadcastedQuestion);
	return (
		<h1
			className="whitespace-pre-line text-center text-2xl text-white lg:text-5xl"
			style={{
				wordBreak: "break-word",
			}}
		>
			{broadcastedQuestion?.question ?? ""}
		</h1>
	);
};

export default PromptedQuestion;
