export interface FilterManagmentIcon {
	ClassName?: string;
}
const FilterManagmentIcon = (props: FilterManagmentIcon) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="22"
			viewBox="0 0 18 22"
			fill="none"
			className={props.ClassName}
		>
			<path
				d="M7.05534 7.33727H0V4.51514H7.05534V7.33727ZM7.05534 11.5705H0V14.3926H7.05534V11.5705ZM0 21.4479H14.1107V18.6258H0V21.4479Z"
				fill="black"
			/>
			<path
				d="M11.1645 14.6299C11.1645 14.2236 11.2924 13.8849 11.5483 13.614C11.8192 13.343 12.1503 13.2076 12.5417 13.2076C12.9631 13.2076 13.2942 13.343 13.5351 13.614C13.7909 13.8849 13.9189 14.2236 13.9189 14.6299C13.9189 15.0213 13.7909 15.3524 13.5351 15.6233C13.2942 15.8943 12.9631 16.0297 12.5417 16.0297C12.1503 16.0297 11.8192 15.8943 11.5483 15.6233C11.2924 15.3524 11.1645 15.0213 11.1645 14.6299ZM7.61987 2.52863C8.28213 1.73091 9.01213 1.1138 9.80985 0.677313C10.6226 0.225771 11.5558 0 12.6094 0C13.4523 0 14.2124 0.180616 14.8897 0.541849C15.5821 0.888031 16.1314 1.38473 16.5378 2.03194C16.9593 2.66409 17.17 3.41666 17.17 4.28964C17.17 4.81644 17.0646 5.31314 16.8539 5.77973C16.6432 6.24632 16.3421 6.66776 15.9508 7.04405C15.5745 7.42033 15.138 7.75899 14.6413 8.06001C14.3554 8.22558 14.1296 8.39115 13.964 8.55671C13.7985 8.72228 13.6856 8.91042 13.6254 9.12114C13.5652 9.33186 13.52 9.60278 13.4899 9.93391L13.4222 11.0628H11.6386L11.5031 9.64041C11.4429 9.0233 11.5332 8.50403 11.7741 8.08259C12.0149 7.66115 12.3761 7.28487 12.8578 6.95374C13.3093 6.65271 13.6856 6.36673 13.9866 6.09581C14.3027 5.82488 14.536 5.54643 14.6865 5.26046C14.8521 4.97448 14.9348 4.65088 14.9348 4.28964C14.9348 3.85315 14.8295 3.46934 14.6188 3.13821C14.4231 2.80708 14.1446 2.54368 13.7834 2.34802C13.4222 2.15235 13.0083 2.05451 12.5417 2.05451C11.7741 2.05451 11.1193 2.2276 10.5775 2.57379C10.0356 2.90492 9.52387 3.38656 9.04223 4.01872L7.61987 2.52863Z"
				fill="black"
			/>
		</svg>
	);
};

export default FilterManagmentIcon;
