import { useTranslation } from "react-i18next";
import NoMessagesImage from "../../../assets/NoMessagesImage";

const NoMessages = () => {
	const { t } = useTranslation();
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-1">
			<NoMessagesImage />
			<span className="text-text">{t("live_page.no_messages")}</span>
		</div>
	);
};

export default NoMessages;
