import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IUser } from "../../InteractionPage/store/store";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";

const useGetUserDetails = (token: string | null, userType: "moderator" | "subscriber") => {
	const fetchUserDetails = async (token: string | null): Promise<IUser> => {
		const endpoint = `/live/cryptage/current-user/${token}?userType=${userType}`;
		const signedUrl = await generateSignedEndpoint(endpoint);

		const response = await axios.get(signedUrl);
		return response?.data?.data;
	};
	return useQuery({
		queryKey: ["userDetails", token, userType],
		queryFn: () => fetchUserDetails(token),
		retry: false,
		refetchOnWindowFocus: false,
		enabled: !!token,
	});
};

export default useGetUserDetails;
