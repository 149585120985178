import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import Share from "../assets/Share.svg";
import toast, { Toaster } from "react-hot-toast";

interface IProps {
	livePage?: boolean;
}

const ShareButton = (props: IProps) => {
	const { t } = useTranslation();
	const copyToClipboard = () => {
		const url = window.location.href.split("?");
		if (url && url.length > 0) {
			const urlToCopy = url[0] ?? ""; // Ensure url[0] is not undefined
			navigator.clipboard.writeText(urlToCopy);
			return toast.success(t("signup_page.link_copied"));
		}
	};
	return (
		<>
			<Toaster
				toastOptions={{
					style: {
						textAlign: "center",
					},
				}}
			/>
			<img
				src={Share}
				onClick={copyToClipboard}
				className="h-8 cursor-pointer rounded-lg bg-[#12181D] p-2 sm:h-auto lg:hidden"
				alt="Share Icon"
			/>
			<button
				onClick={copyToClipboard}
				className={twMerge(
					`hidden w-40 rounded-md   border  border-[#12181D] p-2 lg:block `,
					props.livePage ? `bg-[#12181D]` : `bg-white`,
					props.livePage ? `text-white` : `text-black`
				)}
			>
				{t("signup_page.share")}
			</button>
		</>
	);
};

export default ShareButton;
