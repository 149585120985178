import { useQuery } from "@tanstack/react-query";
import { IQuestions } from "./useGetQuestions";
import axios from "axios";
import { useStore } from "../../store/store";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";

const fetchQuestions = async (
	liveId: number | undefined,
	id: number | null
): Promise<IQuestions[]> => {
	const endpoint = `/live/questions/live/${liveId}/question/${id}`;
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response.data.questions;
};
const useGetQuestion = (liveId: number | undefined, id: number | null) => {
	const status = useStore((state) => state.status);
	const liveActive = useStore((state) => state.liveActive);
	return useQuery({
		queryKey: ["question", liveId, id],
		queryFn: () => fetchQuestions(liveId, id),
		refetchOnWindowFocus: false,
		enabled: !!id,
		...(status === 1 && liveActive === 0 && { staleTime: 1000 * 60 * 60 * 24 }),
	});
};

export default useGetQuestion;
