import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../../store/store";
import { generateSignedEndpoint } from "../../../api/generateSignedEndpoint";
import { IMessagesResponse } from "./types";

const fetchMessages = async (
	liveId: number | undefined,
	page: number
): Promise<IMessagesResponse> => {
	const endpoint = `/live/messages/${liveId}?page=${page}&limit=10&type=general`;
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response?.data;
};
const useGetMessages = (liveId: number | undefined, page: number) => {
	const chat = useStore((state) => state.chat);
	return useQuery({
		queryKey: ["messages", liveId, page],
		queryFn: () => fetchMessages(liveId, page),
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		refetchOnMount: false,
		enabled: chat,
	});
};

export default useGetMessages;
