import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../store/store";
import Question from "./Question/Question";
import SideBarInput from "../SideBarInput/SideBarInput";
import ReplyButton from "./Question/ReplyButton";
import { useTranslation } from "react-i18next";
import BeforeLive from "./BeforeLive";
import useCreateQuestion from "../../hooks/useCreateQuestion";
import { SocketEvents } from "../../hooks/useSocket/types";
import LikeButton from "./Question/LikeButton";
import useGetApprovedQuestions from "../../hooks/useGetApprovedQuestions";
import Loader from "../../../components/Loader/Loader";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import QuestionsFilter from "./QuestionsFilter/QuestionsFilter";
interface IProps {
	liveId: number | undefined;
}
const Questions = (props: IProps) => {
	const { t } = useTranslation();
	const socket = useStore((state) => state.socket);
	const sideBarInput = useStore((state) => state.sideBarInput);
	const setSideBarInput = useStore((state) => state.setSideBarInput);
	const [atBottom, setAtBottom] = useState(false);
	const [moreQuestions, setMoreQuestions] = useState(false);
	const approvedQuestions = useStore((state) => state.approvedQuestions);
	const questionsFilter = useStore((state) => state.questionsFilter);
	const sortedApprovedQuestions = approvedQuestions.sort((a, b) => {
		switch (questionsFilter) {
			case "recent":
				return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
			case "oldest":
				return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
			case "likes":
				return b.likes - a.likes;
		}
	});
	const user = useStore((state) => state.user);
	const userId = user?.id;
	const questionsRef = useRef<VirtuosoHandle>(null);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const canReplyToQuestions = useStore((state) => state.canReplyToQuestions);
	const createQuestionQuery = useCreateQuestion(props.liveId);
	const addApprovedQuestions = useStore((state) => state.addApprovedQuestions);
	// this query wont be fired because it has a stale time of infinity, we are just using it here to get it's loading state to show a loader
	const getQuestionsQuery = useGetApprovedQuestions(props.liveId, user);
	useEffect(() => {
		if (createQuestionQuery.isSuccess) {
			const questionData = createQuestionQuery.data.data.question;
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.question,
						data: questionData,
						toAdminOnly: isCommentValidation,
					})
				);
				addApprovedQuestions(questionData);
				setSideBarInput("");
			}
		}
	}, [createQuestionQuery.isSuccess]);

	const sendQuestion = () => {
		if (sideBarInput.trim() === "") return;
		createQuestionQuery.mutate({
			question: sideBarInput.trim(),
			liveId: Number(props.liveId),
			...(user?.role !== "moderator" && { subscriberId: userId }),
			...(user?.role === "moderator" && { moderatorId: userId }),
			type: "question",
			approved: !isCommentValidation,
		});
	};

	useEffect(() => {
		if (!atBottom && sortedApprovedQuestions.length > 0) {
			setMoreQuestions(true);
		} else {
			setMoreQuestions(false);
			questionsRef.current?.scrollToIndex({
				index: sortedApprovedQuestions.length,
				behavior: "smooth",
			});
		}
	}, [sortedApprovedQuestions.length, atBottom]);

	let renderedQuestions;

	if (sortedApprovedQuestions.length === 0) {
		renderedQuestions = <BeforeLive />;
	} else {
		renderedQuestions = (
			<Virtuoso
				totalCount={sortedApprovedQuestions.length}
				atBottomStateChange={(bottom) => {
					setAtBottom(bottom);
				}}
				className="h-full"
				ref={questionsRef}
				data={sortedApprovedQuestions}
				initialTopMostItemIndex={sortedApprovedQuestions.length - 1}
				itemContent={(_, question) => {
					const QuestionComponent = (
						<Question
							subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
							userId={userId}
						>
							<Question.Sender
								createdAt={question.createdAt}
								lastName={
									question?.subscriber
										? question?.subscriber?.liveSubscribers &&
											question?.subscriber?.liveSubscribers?.length === 1
											? question?.subscriber?.liveSubscribers[0]?.lastName
											: question?.subscriber?.lastName
										: question?.moderator?.username
								}
								email={
									question?.subscriber ? question?.subscriber?.email : question?.moderator?.email
								}
								subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
								userId={userId}
								type="question"
							/>
							<Question.Content
								questionId={question.id}
								question={question.question}
								subscriberId={question?.subscriber ? question?.subscriberId : question?.moderatorId}
								userId={userId}
								answersLength={question?.answers?.filter((answer) => answer.approved)?.length}
								likes={question?.likes}
							>
								{!question.approved &&
								(question?.subscriberId === userId || question?.moderatorId === userId) ? null : (
									<div className="flex items-center gap-1">
										<LikeButton
											questionId={question.id}
											userId={userId}
											active={question?.questionLikes?.findIndex(
												(questionLike) =>
													questionLike?.subscriberId === userId ||
													questionLike?.moderatorId === userId
											)}
											approved={question.approved}
										/>
										{canReplyToQuestions && <ReplyButton id={question.id} />}
									</div>
								)}
							</Question.Content>
						</Question>
					);
					if (
						isCommentValidation &&
						!question.approved &&
						(question?.subscriberId === userId || question?.moderatorId === userId)
					) {
						return (
							<div key={question.id} className=" mt-2 rounded-lg  bg-white p-2">
								<span className=" text-text">{t("live_page.question_prompt")}</span>
								{QuestionComponent}
							</div>
						);
					} else {
						return <div key={question.id}>{QuestionComponent}</div>;
					}
				}}
			/>
		);
	}
	return (
		<>
			<div className=" relative z-10 flex-grow  overflow-hidden  p-2">
				{user?.role === "moderator" && (
					<div className="flex w-full justify-end">
						<QuestionsFilter />
					</div>
				)}
				{getQuestionsQuery.isLoading ? <Loader /> : renderedQuestions}
				{moreQuestions ? (
					<div className="sticky  bottom-1  flex w-full items-center  justify-center transition-all">
						<button
							onClick={() => {
								questionsRef.current?.scrollToIndex({
									index: sortedApprovedQuestions.length - 1,
									behavior: "smooth",
								});

								setMoreQuestions(false);
							}}
							className=" cursor-pointer rounded-full bg-rte p-2 text-white"
						>
							{t("live_page.scroll_to_bottom")}
						</button>
					</div>
				) : null}
			</div>
			<SideBarInput
				sendFunction={sendQuestion}
				placeholder={t("live_page.question_input_placeholder")}
				isLoading={createQuestionQuery.isLoading}
			/>
		</>
	);
};

export default Questions;
