import { twMerge } from "tailwind-merge";
import { useStore } from "../../../../store/store";
import { AdvancedSettingsTabId } from "../../../store/types";

interface IProps {
	title: string;
	icon: JSX.Element;
	number: AdvancedSettingsTabId;
}
const AdvancedSettingsTab = (props: IProps) => {
	const setActiveAdvancedSettingsTab = useStore((state) => state.setActiveAdvancedSettingsTab);
	const activeAdvancedSettingsTab = useStore((state) => state.activeAdvancedSettingsTab);
	return (
		<button
			className={twMerge(
				"flex w-full flex-col items-center  justify-center gap-1 border-r-2  border-white p-2 opacity-50 transition-all hover:opacity-100",
				activeAdvancedSettingsTab === props.number ? "opacity-100" : "opacity-50"
			)}
			onClick={() => {
				setActiveAdvancedSettingsTab(props.number);
			}}
		>
			{props.icon}
			{props.title}
		</button>
	);
};

export default AdvancedSettingsTab;
