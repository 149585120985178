export interface FilterIcon {
	ClassName: string;
}
const FilterIcon = (props: FilterIcon) => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={props.ClassName}>
        <path d="M0.75 3H15.25C15.4489 3 15.6397 3.07902 15.7803 3.21967C15.921 3.36032 16 3.55109 16 3.75C16 3.94891 15.921 4.13968 15.7803 4.28033C15.6397 4.42098 15.4489 4.5 15.25 4.5H0.75C0.551088 4.5 0.360322 4.42098 0.21967 4.28033C0.0790176 4.13968 0 3.94891 0 3.75C0 3.55109 0.0790176 3.36032 0.21967 3.21967C0.360322 3.07902 0.551088 3 0.75 3ZM3 7.75C3 7.55109 3.07902 7.36032 3.21967 7.21967C3.36032 7.07902 3.55109 7 3.75 7H12.25C12.4489 7 12.6397 7.07902 12.7803 7.21967C12.921 7.36032 13 7.55109 13 7.75C13 7.94891 12.921 8.13968 12.7803 8.28033C12.6397 8.42098 12.4489 8.5 12.25 8.5H3.75C3.55109 8.5 3.36032 8.42098 3.21967 8.28033C3.07902 8.13968 3 7.94891 3 7.75ZM6 11.75C6 11.5511 6.07902 11.3603 6.21967 11.2197C6.36032 11.079 6.55109 11 6.75 11H9.25C9.44891 11 9.63968 11.079 9.78033 11.2197C9.92098 11.3603 10 11.5511 10 11.75C10 11.9489 9.92098 12.1397 9.78033 12.2803C9.63968 12.421 9.44891 12.5 9.25 12.5H6.75C6.55109 12.5 6.36032 12.421 6.21967 12.2803C6.07902 12.1397 6 11.9489 6 11.75Z" fill="#999DAD"/>
      </svg>
	
	);
};

export default FilterIcon;
