export interface IQuestionsIconProps {
	active: boolean;
}
const QuestionsIcon = (props: IQuestionsIconProps) => {
	return (
		<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.764 8.91614C15.0211 9.09289 15.2272 9.33414 15.3616 9.61568C15.4757 9.82511 15.6666 9.98209 15.8941 10.0536C16.1217 10.125 16.368 10.1054 16.5814 9.9988C16.7947 9.89221 16.9583 9.70699 17.0378 9.48214C17.1173 9.2573 17.1064 9.01038 17.0074 8.79341C16.762 8.30005 16.3778 7.77968 15.7838 7.3845C15.1849 6.98318 14.4277 6.75 13.4999 6.75C12.6923 6.75 11.8197 6.98932 11.1263 7.50968C10.7397 7.79227 10.4262 8.16311 10.2119 8.59133C9.9976 9.01955 9.88865 9.49276 9.89414 9.97159C9.89414 11.2394 10.5237 12.0825 11.1546 12.69C11.3877 12.9146 11.6442 13.1294 11.8651 13.3135L12.0456 13.4644C12.2348 13.6192 12.4132 13.787 12.5794 13.9664V15.6477C12.5794 15.8918 12.6764 16.126 12.849 16.2986C13.0216 16.4712 13.2558 16.5682 13.4999 16.5682C13.744 16.5682 13.9781 16.4712 14.1507 16.2986C14.3233 16.126 14.4203 15.8918 14.4203 15.6477V13.84C14.4203 13.5491 14.3381 13.2214 14.1172 12.9367C13.8472 12.593 13.5207 12.3022 13.236 12.0604L13.0114 11.8726L13.0102 11.8714C12.7991 11.6959 12.6126 11.54 12.4334 11.3658C11.9817 10.9301 11.7351 10.5361 11.7351 9.97159C11.7296 9.7786 11.7721 9.58726 11.8586 9.41466C11.9451 9.24206 12.073 9.09356 12.2309 8.98241C12.5598 8.73573 13.0286 8.59091 13.4999 8.59091C14.1061 8.59091 14.4989 8.73941 14.764 8.91614ZM12.2726 19.6364C12.2726 19.9619 12.4019 20.274 12.6321 20.5042C12.8622 20.7343 13.1744 20.8636 13.4999 20.8636C13.8254 20.8636 14.1375 20.7343 14.3677 20.5042C14.5978 20.274 14.7271 19.9619 14.7271 19.6364C14.7271 19.3109 14.5978 18.9987 14.3677 18.7685C14.1375 18.5384 13.8254 18.4091 13.4999 18.4091C13.1744 18.4091 12.8622 18.5384 12.6321 18.7685C12.4019 18.9987 12.2726 19.3109 12.2726 19.6364Z"
				fill={props.active ? "#00A6D9" : "#FFFFFF"}
			/>
			<path
				d="M13.5 0C6.04432 0 0 6.04432 0 13.5C0 20.9557 6.04432 27 13.5 27C20.9557 27 27 20.9557 27 13.5C27 6.04432 20.9557 0 13.5 0ZM25.1591 13.5C25.1591 16.5922 23.9307 19.5577 21.7442 21.7442C19.5577 23.9307 16.5922 25.1591 13.5 25.1591C10.4078 25.1591 7.44228 23.9307 5.25578 21.7442C3.06927 19.5577 1.84091 16.5922 1.84091 13.5C1.84091 10.4078 3.06927 7.44228 5.25578 5.25578C7.44228 3.06927 10.4078 1.84091 13.5 1.84091C16.5922 1.84091 19.5577 3.06927 21.7442 5.25578C23.9307 7.44228 25.1591 10.4078 25.1591 13.5Z"
				fill={props.active ? "#00A6D9" : "#FFFFFF"}
			/>
		</svg>
	);
};

export default QuestionsIcon;
