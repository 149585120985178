import { useTranslation } from "react-i18next";
import Tab from "../Tabs/Tab";
import QuestionsIcon from "./../../assets/QuestionsIcon";
import LogoutIcon from "./../../assets/LogoutIcon";
import AdminIcon from "./../../assets/AdminIcon";
import ChatIcon from "./../../assets/ChatIcon";
import { useStore } from "../../store/store";
import BackstageIcon from "../../assets/BackstageIcon";
import ModeratorAdvancedSettingsIcon from "../../assets/ModeratorAdvancedSettingsIcon";

const Menu = () => {
	const { t } = useTranslation();
	const liveDetails = useStore((state) => state.live);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const user = useStore((state) => state.user);
	const activeTab = useStore((state) => state.activeTab);
	const chat = useStore((state) => state.chat);
	const canComment = useStore((state) => state.canComment);
	const backstage = useStore((state) => state.backstage);
	const messagesNotifications = useStore((state) => state.messagesNotifications);
	const questionsNotifications = useStore((state) => state.questionsNotifications);
	const backstageMessagesNotifications = useStore((state) => state.backstageMessagesNotifications);
	const approvedQuestionsNotifications = useStore((state) => state.approvedQuestionsNotifications);

	return (
		<div className="fixed left-0 top-20 z-50 h-[calc(100%-5rem)] w-full bg-text  lg:hidden">
			<div className=" flex w-full flex-col justify-center gap-2 p-3 ">
				{canComment || user?.role !== "listener" ? (
					<Tab
						title={t("live_page.questions")}
						icon={<QuestionsIcon active={activeTab === 2} />}
						number={2}
						showLabel={true}
						notifications={approvedQuestionsNotifications}
					/>
				) : null}
				{chat ? (
					<Tab
						title={t("live_page.chat")}
						icon={<ChatIcon active={activeTab === 1} />}
						number={1}
						showLabel={true}
						notifications={messagesNotifications}
					/>
				) : null}
				{isCommentValidation && user?.role === "moderator" ? (
					<Tab
						title={t("live_page.pending_questions")}
						icon={<AdminIcon active={activeTab === 5} />}
						number={5}
						showLabel={true}
						notifications={questionsNotifications}
					/>
				) : null}
				{backstage ? (
					<Tab
						title={"Backstage"}
						number={7}
						icon={<BackstageIcon active={activeTab === 7} />}
						showLabel={true}
						notifications={backstageMessagesNotifications}
					/>
				) : null}
				{liveDetails?.canShareFile && liveDetails?.docPublic ? (
					<Tab
						title={t("live_page.advanced_settings")}
						number={8}
						icon={<ModeratorAdvancedSettingsIcon active={activeTab === 8} />}
						showLabel={true}
						notifications={0}
					/>
				) : null}
				<Tab
					title={t("live_page.logout")}
					number={6}
					icon={<LogoutIcon active={activeTab === 6} />}
					showLabel={true}
					notifications={0}
				/>
			</div>
		</div>
	);
};

export default Menu;
