import { twMerge } from "tailwind-merge";
import Sender from "./Sender";
import Content from "./Content";
import NumberOfAnswers from "./NumberOfAnswers";
import AnswerContent from "./AnswerContent";
interface IProps {
	subscriberId: number;
	userId: number | undefined;
	isAnswer?: boolean;
	children?: React.ReactNode;
}

const Question = (props: IProps) => {
	return (
		<div
			className={twMerge(
				`flex flex-col font-normal `,
				!props.isAnswer && props.subscriberId === props.userId ? "justify-end" : ""
			)}
		>
			{props.children}
		</div>
	);
};
Question.Sender = Sender;
Question.Content = Content;
Question.AnswerContent = AnswerContent;
Question.NumberOfAnswers = NumberOfAnswers;
export default Question;
