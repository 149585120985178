const EditQuestion = () => {
	return (
		<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.6626 3.84967L14.3762 0.356745C14.159 0.128263 13.8651 0 13.5588 0C13.2524 0 12.9585 0.128263 12.7413 0.356745L1.09038 12.659L0.0266203 17.5135C-0.0100758 17.691 -0.00881786 17.8749 0.0303022 18.0518C0.0694222 18.2286 0.145416 18.3941 0.252733 18.5359C0.360049 18.6777 0.495977 18.7925 0.650586 18.8716C0.805195 18.9508 0.97458 18.9925 1.14637 18.9936C1.22641 19.0021 1.30707 19.0021 1.38711 18.9936L6.02846 17.8687L17.6626 5.57838C17.8787 5.34873 18 5.03797 18 4.71402C18 4.39008 17.8787 4.07931 17.6626 3.84967ZM5.46859 16.8031L1.11837 17.7681L2.10935 13.2569L10.8266 4.07464L14.1858 7.62677L5.46859 16.8031ZM14.936 6.76834L11.5768 3.21621L13.5252 1.16781L16.8284 4.71994L14.936 6.76834Z"
				fill="white"
			/>
		</svg>
	);
};

export default EditQuestion;
