import { useTranslation } from "react-i18next";
import Use4g from "./../../assets/Use4g.gif";

const Use4gPrompt = () => {
	const { t } = useTranslation();
	return (
		<div className="aspect-video w-full flex-grow md:max-h-full md:w-auto">
			<div className="mx-auto  block aspect-video w-full rounded-lg bg-[#F7F7F7]  md:h-full md:w-auto lg:pt-28">
				<img src={Use4g} alt="use4g" className="mx-auto mb-6 block h-56 w-56 lg:h-72 lg:w-72  " />
				<h2 className=" text-center text-base text-text sm:text-xl lg:text-2xl">
					{t("live_page.use_4g")}
				</h2>
			</div>
		</div>
	);
};

export default Use4gPrompt;
