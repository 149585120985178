import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/store";
import SmallLoader from "../../../components/SmallLoader";
import { DialogueType } from "./types";
interface IProps {
	isLoading: boolean;
	type: DialogueType;
}
const AddDialogueButton = (props: IProps) => {
	const { t } = useTranslation();
	const liveActive = useStore((state) => state.liveActive);
	const liveStatus = useStore((state) => state.status);
	const canCommentBeforeLive = useStore((state) => state.canCommentBeforeLive);
	const activeTab = useStore((state) => state.activeTab);
	let label = "";
	if (props.type === "question") {
		label = t("live_page.send_question");
	}

	if (props.type === "answer") {
		label = t("live_page.send_answer");
	}

	if (props.type === "message") {
		label = t("live_page.send_message");
	}

	return (
		<button
			type="submit"
			disabled={
				(liveActive !== 1 &&
					liveStatus !== 0 &&
					!canCommentBeforeLive &&
					(activeTab === 2 || activeTab === 4)) ||
				props.isLoading
			}
			className="flex w-full items-center justify-center gap-3 rounded-lg bg-rte p-3 font-medium text-white disabled:bg-[#BABABA] sm:w-4/5 md:w-full"
		>
			{label}
			{props.isLoading && <SmallLoader />}
		</button>
	);
};

export default AddDialogueButton;
