import { useEffect, useState } from "react";
import "./style.css";

function useDelayUnmount(isMounted: boolean, delayTime: number) {
	const [showDiv, setShowDiv] = useState(false);
	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (isMounted && !showDiv) {
			setShowDiv(true);
		} else if (!isMounted && showDiv) {
			timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
		}
		return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
	}, [isMounted, delayTime, showDiv]);
	return showDiv;
}
interface IProps {
	children: React.ReactNode;
	isMounted: boolean;
}
const ShowWithAnimation = (props: IProps) => {
	const showDiv = useDelayUnmount(props.isMounted, 450);
	const mountedStyle = { animation: "inAnimation 450ms ease-in" };
	const unmountedStyle = {
		animation: "outAnimation 470ms ease-out",
		animationFillMode: "forwards",
	};
	return (
		<div>
			{showDiv && (
				<div style={props.isMounted ? mountedStyle : unmountedStyle}>{props.children}</div>
			)}
		</div>
	);
};

export default ShowWithAnimation;
