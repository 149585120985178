export interface ILogoutIconProps {
	active: boolean;
}
const LogoutIcon = (props: ILogoutIconProps) => {
	return (
		<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.375 0V13.5H14.625V0H12.375ZM9 0.774C3.76425 2.63025 0 7.6365 0 13.5C0 20.9441 6.05587 27 13.5 27C20.9441 27 27 20.9441 27 13.5C27 7.63538 23.2358 2.63025 18 0.772875V3.1995C21.9667 4.93875 24.75 8.89875 24.75 13.5C24.75 19.7033 19.7033 24.75 13.5 24.75C7.29675 24.75 2.25 19.7033 2.25 13.5C2.25 8.89875 5.03325 4.93875 9 3.1995V0.772875V0.774Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
		</svg>
	);
};

export default LogoutIcon;
