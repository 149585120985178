import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../api/generateSignedEndpoint";
import axios from "axios";

const useSetParticipated = (token: string | null) => {
	return useMutation({
		mutationFn: async () => {
			const signedEndpoint = await generateSignedEndpoint(`/live/setParticipant/${token}`);
			return axios.put(signedEndpoint);
		},
	});
};

export default useSetParticipated;
