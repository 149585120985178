import { Navigate, useParams } from "react-router-dom";
import useGetLiveDetails from "../SignupPage/hooks/useGetLiveDetails";
import Header from "../components/Header";
// import SideBarButton from "./SideBarButton";
import SideBar from "./SideBar/SideBar";
import Loader from "../components/Loader/Loader";
import { twMerge } from "tailwind-merge";
import { useStore } from "../store/store";
import Error from "../components/Error";
import RequirePassword from "../components/RequirePassword";
import { isAxiosError } from "axios";
import Footer from "../SignupPage/Footer";
import useLiveDetailsUpdate from "../hooks/useLiveDetailsUpdate";
import LivePlayer from "./LivePlayer/LivePlayer";
import useCheckProtectedLive from "../SignupPage/hooks/useCheckProtectedLive";
import { useEffect } from "react";
import toast from "react-hot-toast";

const LivePage = () => {
	const { liveToken } = useParams();
	const liveData = useGetLiveDetails(liveToken);
	const data = liveData.data?.live;
	const showPasswordModal = useStore((state) => state.showPasswordModal);
	useEffect(() => {
		const getIpAddress = async () => {
			try {
				const response = await fetch("https://api.ipify.org?format=json");
				const data = await response.json();
				useStore.setState({ userIpAddress: data.ip });
			} catch (e) {
				toast.error("error getting ip address");
			}
		};
		getIpAddress();
	}, []);
	useLiveDetailsUpdate(data);
	useCheckProtectedLive(liveData);
	if (liveData.isLoading)
		return (
			<div className="grid h-screen place-items-center">
				<Loader />
			</div>
		);
	if (
		liveData.isError &&
		liveData.error &&
		isAxiosError(liveData.error) &&
		liveData.error.response?.status === 404
	) {
		return <Navigate to={`/404`} />;
	}
	if (
		liveData.isError &&
		liveData.error &&
		isAxiosError(liveData.error) &&
		liveData.error.response?.status !== 403 &&
		liveData.error.response?.data?.message !== "Unauthorized"
	) {
		return <Error />;
	}
	return (
		<main className={twMerge(`flex h-screen flex-col  bg-white`)}>
			{showPasswordModal ? <RequirePassword /> : null}
			<Header title={data?.title} livePage={true}>
				{/* <SideBarButton /> */}
			</Header>

			<div className=" flex h-[calc(100vh-10rem)] w-full  flex-col items-center gap-4 p-3  md:relative md:flex-row md:justify-around">
				<LivePlayer iframerSrc={data?.url} startDate={data?.date?.StartDate} />
				<SideBar
					liveId={data?.id}
					chat={data?.chat}
					canComment={data?.canComment}
					isCommentValidation={data?.isCommentValidation}
					backstage={data?.private_chat}
				/>
			</div>

			<div className=" fixed bottom-0 flex h-20 w-full items-center justify-center bg-[#ECF6FA] p-2 text-center text-text">
				<Footer />
			</div>
		</main>
	);
};

export default LivePage;
