const NoMessagesImage = () => {
	return (
		<svg
			width="246"
			height="176"
			viewBox="0 0 246 176"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1107_2)" filter="url(#filter0_d_1107_2)">
				<path
					d="M121.77 69.5H220.39C225.901 69.5158 231.183 71.7123 235.08 75.6097C238.977 79.507 241.174 84.7884 241.19 90.3V131.18C241.174 136.692 238.977 141.973 235.08 145.87C231.183 149.768 225.901 151.964 220.39 151.98H116.52L100.93 167.57V90.3C100.946 84.7815 103.148 79.4941 107.054 75.5956C110.959 71.6971 116.251 69.5053 121.77 69.5Z"
					fill="#62626B"
				/>
				<path
					d="M150.27 11.5508H21.22C16.6501 11.564 12.2718 13.388 9.04411 16.6231C5.81644 19.8583 4.00263 24.2409 4 28.8108V62.6108C4.01846 67.1703 5.83927 71.5375 9.06522 74.7597C12.2912 77.9819 16.6605 79.7976 21.22 79.8108H154.58L167.52 92.7408V28.8108C167.517 24.2357 165.699 19.8486 162.465 16.6126C159.231 13.3766 154.845 11.5561 150.27 11.5508Z"
					fill="#00A6D9"
				/>
				<path
					d="M100.78 6.66016H31.9004C31.7678 6.66016 31.6406 6.60748 31.5468 6.51371C31.4531 6.41994 31.4004 6.29276 31.4004 6.16016C31.4004 6.02755 31.4531 5.90037 31.5468 5.8066C31.6406 5.71283 31.7678 5.66016 31.9004 5.66016H100.78C100.913 5.66016 101.04 5.71283 101.134 5.8066C101.228 5.90037 101.28 6.02755 101.28 6.16016C101.28 6.29276 101.228 6.41994 101.134 6.51371C101.04 6.60748 100.913 6.66016 100.78 6.66016Z"
					fill="white"
				/>
				<path
					d="M82.65 85.1797H37.29C37.1574 85.1797 37.0303 85.127 36.9365 85.0332C36.8427 84.9395 36.79 84.8123 36.79 84.6797C36.79 84.5471 36.8427 84.4199 36.9365 84.3261C37.0303 84.2324 37.1574 84.1797 37.29 84.1797H82.65C82.7826 84.1797 82.9098 84.2324 83.0036 84.3261C83.0974 84.4199 83.15 84.5471 83.15 84.6797C83.15 84.8123 83.0974 84.9395 83.0036 85.0332C82.9098 85.127 82.7826 85.1797 82.65 85.1797Z"
					fill="white"
				/>
				<path
					d="M150.69 1H81.2197C81.0879 0.99744 80.9622 0.943942 80.869 0.850723C80.7758 0.757504 80.7223 0.631807 80.7197 0.5C80.7197 0.367392 80.7724 0.240214 80.8662 0.146446C80.9599 0.052678 81.0871 0 81.2197 0H150.69C150.822 0 150.95 0.052678 151.043 0.146446C151.137 0.240214 151.19 0.367392 151.19 0.5C151.187 0.631807 151.134 0.757504 151.04 0.850723C150.947 0.943942 150.822 0.99744 150.69 1Z"
					fill="white"
				/>
				<path
					d="M215.59 64.4902H177.88C177.747 64.4902 177.62 64.4376 177.526 64.3438C177.433 64.25 177.38 64.1228 177.38 63.9902C177.38 63.8576 177.433 63.7304 177.526 63.6367C177.62 63.5429 177.747 63.4902 177.88 63.4902H215.59C215.722 63.4902 215.85 63.5429 215.943 63.6367C216.037 63.7304 216.09 63.8576 216.09 63.9902C216.09 64.1228 216.037 64.25 215.943 64.3438C215.85 64.4376 215.722 64.4902 215.59 64.4902Z"
					fill="white"
				/>
				<path
					d="M215.09 158.75H124.31C124.177 158.75 124.05 158.697 123.956 158.604C123.862 158.51 123.81 158.383 123.81 158.25C123.81 158.117 123.862 157.99 123.956 157.896C124.05 157.803 124.177 157.75 124.31 157.75H215.09C215.222 157.75 215.349 157.803 215.443 157.896C215.537 157.99 215.59 158.117 215.59 158.25C215.59 158.383 215.537 158.51 215.443 158.604C215.349 158.697 215.222 158.75 215.09 158.75Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1107_2"
					x="0"
					y="0"
					width="245.19"
					height="175.58"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1107_2" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1107_2"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1107_2">
					<rect width="237.19" height="167.58" fill="white" transform="translate(4)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default NoMessagesImage;
