const ArrowDown = () => {
	return (
		<svg width="18" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.897 7.22221L3.19 13.9202C3.12489 13.9857 3.0473 14.0374 2.96184 14.0723C2.87638 14.1072 2.78478 14.1247 2.69246 14.1235C2.60015 14.1224 2.50899 14.1028 2.4244 14.0658C2.33981 14.0288 2.26351 13.9752 2.2 13.9082C2.06989 13.7712 1.99835 13.5888 2.00059 13.3998C2.00284 13.2108 2.07868 13.0302 2.212 12.8962L9.415 5.70322C9.47963 5.63814 9.55658 5.58661 9.64136 5.55163C9.72614 5.51664 9.81705 5.49891 9.90876 5.49947C10.0005 5.50003 10.0911 5.51887 10.1755 5.55488C10.2598 5.59089 10.3362 5.64336 10.4 5.70921L17.795 13.2802C17.9259 13.4158 17.999 13.5968 17.999 13.7852C17.999 13.9736 17.9259 14.1547 17.795 14.2902C17.7307 14.3564 17.6537 14.4091 17.5687 14.445C17.4837 14.481 17.3923 14.4995 17.3 14.4995C17.2077 14.4995 17.1163 14.481 17.0313 14.445C16.9463 14.4091 16.8693 14.3564 16.805 14.2902L9.897 7.22221Z"
				fill="#263238"
			/>
		</svg>
	);
};

export default ArrowDown;
