import { twMerge } from "tailwind-merge";
import { useStore } from "../../../store/store";
import BackArrow from "../../../assets/BackArrow.svg";
import { TabId } from "../../store/types";

interface IProps {
	title: string;
	number: TabId;
}

const Tab = (props: IProps) => {
	const activeTab = useStore((state) => state.activeTab);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const liveActive = useStore((state) => state.liveActive);
	const status = useStore((state) => state.status);
	return (
		<li
			className={twMerge(
				`relative h-full w-full  cursor-pointer whitespace-nowrap p-3  text-center text-white before:absolute before:bottom-0 before:left-0 before:z-50 before:h-1 before:w-full before:translate-y-1 before:rounded-lg  before:transition-all before:duration-200 before:content-[''] `,
				activeTab === props.number && "before:bg-white",
				activeTab === 4 && "w-full"
			)}
			onClick={() => {
				if (props.number === 4) {
					setActiveTab(2);
					return;
				}
				setActiveTab(props.number);
			}}
		>
			<div
				className={twMerge(
					"flex w-full items-center justify-center gap-2  text-center",
					props.number === 4 && "justify-start"
				)}
			>
				{props.number === 4 ? <img src={BackArrow} className="h-[15px]" alt="Back arrow" /> : null}
				{props.title}
				{activeTab === props.number ? (
					<div
						className={twMerge(
							"h-2 w-2 rounded-full",
							liveActive === 0 && status === -1 && "bg-question",
							liveActive === 0 && status === 1 && "bg-red-600",
							liveActive === 1 && status === 0 && "bg-[#21FF37]"
						)}
					></div>
				) : null}
			</div>
		</li>
	);
};

export default Tab;
