import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface IProps {
	label?: string;
	type: string;
	value: string;
	setValue: (value: string) => void;
	// just for styling
	required?: boolean;
	color?: string;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	placeholder?: string;
}

const Input = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<div className="flex w-full flex-col gap-2 ">
			{props.label ? (
				<label
					htmlFor={props.label}
					className={twMerge("whitespace-nowrap font-normal", props.color && `text-${props.color}`)}
				>
					{props.label}
					{props.required ? (
						<span className="font-light text-gray-400">{t("signup_page.required")}</span>
					) : null}
				</label>
			) : null}{" "}
			<input
				onKeyDown={props.onKeyDown}
				autoComplete="on"
				type={props.type}
				id={props.label}
				placeholder={props?.placeholder ?? ""}
				className=" w-full rounded-xl border border-gray-300 p-2 text-black outline-none ring-0 focus:ring-2 focus:ring-rte lg:rounded-md lg:p-3"
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
			/>
		</div>
	);
};

export default Input;
