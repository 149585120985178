import { useState } from "react";
import QR from "./../../../assets/Q&R.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/store";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const BeforeLive = () => {
	const { t } = useTranslation();
	const [showMessage, setShowMessage] = useState(true);
	const activeTab = useStore((state) => state.activeTab);
	const location = useLocation();
	const isLiveParticipant = location.pathname.includes("live-participant");
	return (
		<div className={twMerge("relative h-full w-full ")}>
			{showMessage ? (
				<div
					className={twMerge(
						"relative mx-auto my-0 rounded-lg bg-[#47515B8C] p-2 text-white lg:w-full lg:text-base",
						isLiveParticipant && "lg:w-4/5"
					)}
				>
					<button
						onClick={() => {
							setShowMessage(false);
						}}
						className="absolute right-2 top-0 cursor-pointer"
					>
						&#10005;
					</button>
					<p className="w-auto">
						{activeTab === 2
							? t("live_page.before_live_question_prompt")
							: t("live_page.before_live_admin_prompt")}
					</p>
				</div>
			) : null}
			<div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2 lg:top-1/2  ">
				<img src={QR} alt="Q&R" className="h-24 w-24 lg:h-80 lg:w-80" />
			</div>
		</div>
	);
};

export default BeforeLive;
