import { useEffect, useRef } from "react";
import { useStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import SendIcon from "./../../../assets/SendIcon.svg";
import ErrorIcon from "./../../../assets/ErrorIcon.svg";
interface IProps {
	sendFunction: () => void;
	placeholder: string;
	isLoading: boolean;
}
const TextAreaInput = (props: IProps) => {
	const { t } = useTranslation();
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const liveActive = useStore((state) => state.liveActive);
	const status = useStore((state) => state.status);
	const messageToReply = useStore((state) => state.messageToReply);
	const sideBarInput = useStore((state) => state.sideBarInput);
	const setSideBarInput = useStore((state) => state.setSideBarInput);
	const canCommentBeforeLive = useStore((state) => state.canCommentBeforeLive);
	const activeTab = useStore((state) => state.activeTab);
	useEffect(() => {
		if (inputRef.current || messageToReply) {
			inputRef?.current?.focus();
		}
		if (sideBarInput) {
			inputRef?.current?.focus();
		}
	}, [messageToReply, sideBarInput]);
	if (
		liveActive === 0 &&
		status === -1 &&
		!canCommentBeforeLive &&
		(activeTab === 2 || activeTab === 4)
	) {
		return (
			<div className=" flex w-full items-center justify-center  p-2">
				<p className=" flex items-center justify-center gap-1 rounded-lg  bg-white px-8 py-2 text-center ">
					<img src={ErrorIcon} alt="Error Icon" />
					{t("live_page.sidebar_live_not_started")}
				</p>
			</div>
		);
	}
	if (liveActive === 0 && status === 1) {
		return (
			<div className="flex  h-full items-center justify-center   p-2">
				<p className=" flex h-full  items-center justify-center gap-1 rounded-lg px-8 py-2 text-center ">
					<img src={ErrorIcon} alt="Error Icon" />
					{t("live_page.sidebar_live_ended")}
				</p>
			</div>
		);
	}
	return (
		<>
			<textarea
				ref={inputRef}
				value={sideBarInput}
				onChange={(e) => {
					setSideBarInput(e.target.value);
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
					if (e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
						props.sendFunction();
					}
				}}}
				disabled={props.isLoading}
				className=" w-full resize-none rounded-lg border border-rte bg-white p-2 text-black outline-none ring-rte  focus:ring-[1px] "
				placeholder={props.placeholder}
			/>
			<button onClick={props.sendFunction} disabled={props.isLoading}>
				<img src={SendIcon} alt="send icon" className="cursor-pointer" />
			</button>
		</>
	);
};

export default TextAreaInput;
