import { useTranslation } from "react-i18next";
import Logo from "../components/Logo";

interface IProps {
	title: string | undefined;
	isJournalist: boolean;
	isModerator: boolean;
}

const LiveTitle = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<div className="flex w-full  flex-col items-center justify-center gap-4 border-b-[1px] border-white px-5  py-9 xl:w-4/5 ">
			<div className="flex h-20">
				<Logo/>
			</div>
			<div className="flex w-full flex-col items-center justify-center gap-3 text-center">
				<h2 className="text-center text-2xl font-bold">{t("signup_page.webinar")}</h2>
				<h1 className="break-words text-center text-2xl font-bold">
					{props?.title ??
						`" RTE - Nos perspectives d'investissement dans le réseau - édition 2024 "`}
				</h1>
			</div>
		</div>
	);
};

export default LiveTitle;
