import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { generateSignedEndpoint } from "../api/generateSignedEndpoint";
import { useStore } from "../store/store";

export interface IUrlMetaTags {
	description?: string;
	title?: string;
	image?: string;
	"og:image"?: string;
	"og:title"?: string;
	"og:url"?: string;
	"og:description"?: string;
	"og:type"?: string;
	"og:site_name"?: string;
	"og:locale"?: string;
}

const fetchUrlMetaTags = async (url: string): Promise<IUrlMetaTags> => {
	let endpoint = "/live/preview-link?url=" + url;
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response.data.metaTags;
};
const useGetUrlMetaTags = (url: string) => {
	const failedPreviewLinksFetch = useStore((state) => state.failedPreviewLinksFetch);
	return useQuery({
		queryKey: ["urlMetaTags", url],
		queryFn: () => fetchUrlMetaTags(url),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTime: Infinity,
		staleTime: Infinity,
		retry: false,
		enabled: failedPreviewLinksFetch.filter((link) => link.includes(url)).length === 0,
	});
};

export default useGetUrlMetaTags;
