import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface ISendEmailPayload {
	subscriberId: number;
	liveId: number;
	url: string;
	ipAddress: string;
}

const sendVerificationEmail = async (payload: ISendEmailPayload, forceFlag: boolean) => {
	const endpoint = `/live/send-verification-email?force=${forceFlag || false}`;
	const signedEndpoint = await generateSignedEndpoint(endpoint);
	const response = await axios.post(signedEndpoint, payload);
	return response.data;
};

const useSendVerificationEmail = (forceFlag: boolean) => {
	const { t } = useTranslation();
	return useMutation({
		mutationFn: (payload: ISendEmailPayload) => sendVerificationEmail(payload, forceFlag),
		onSuccess: () => {
			if (forceFlag) {
				toast.success(t("live_page.verification_code_sent"));
			}
		},
		onError: () => {
			if (forceFlag) {
				toast.error(t("live_page.error_sending_email"));
			}
		},
	});
};

export default useSendVerificationEmail;
