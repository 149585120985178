interface IProps {
	color?: string;
	children?: React.ReactNode;
}
const SmallLoader = (props: IProps) => {
	return (
		<div className="flex items-center justify-center gap-2">
			<svg
				className="-ml-1 mr-3 h-5 w-5 animate-spin "
				style={{
					color: props.color ? props.color : "white",
				}}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle
					className="opacity-25"
					cx="12"
					cy="12"
					r="10"
					stroke="currentColor"
					strokeWidth="4"
				></circle>
				<path
					className="opacity-75"
					fill="currentColor"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
				></path>
			</svg>
			{props.children}
		</div>
	);
};

export default SmallLoader;
