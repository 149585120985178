import { useStore } from "../store/store";
import BackArrow from "./../assets/BackArrow.svg";
import { useTranslation } from "react-i18next";
import SideBarBg2 from "./../assets/SideBarBg2.png";
import { TabToRender } from "../LivePage/SideBar/SideBar";
import usePopulateQuestionsAndMessages from "../hooks/usePopulateQuestionsAndMessages";
import useGetMessages from "../LivePage/hooks/messages/useGetMessages";
import useGetBackStageMessages from "../LivePage/hooks/messages/useGetBackStageMessages";

interface IProps {
	liveId: number | undefined;
	backstage: boolean | undefined;
}

const InteractionSideBar = (props: IProps) => {
	const { t } = useTranslation();
	const liveDetails = useStore((state) => state.live);
	const activeTab = useStore((state) => state.activeTab);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const user = useStore((state) => state.user);
	const page = useStore((state) => state.page);
	const backstagePage = useStore((state) => state.backstagePage);
	const messagesQuery = useGetMessages(props.liveId, page);
	const backStageMessagesQuery = useGetBackStageMessages(
		props.liveId,
		backstagePage,
		props.backstage
	);
	const questionToAnswerId = useStore((state) => state.questionToAnswer);
	const setQuestionToAnswerId = useStore((state) => state.setQuestionToAnswer);
	const answers = useStore((state) => state.approvedAnswers).filter(
		(answer) => answer.questionId === questionToAnswerId
	);
	const messages = useStore((state) => state.messages);
	const backstageMessages = useStore((state) => state.backstageMessages);
	const Tab = TabToRender(
		activeTab,
		messagesQuery,
		backStageMessagesQuery,
		user,
		messages,
		backstageMessages,
		liveDetails
	);
	const answersLength = answers?.filter((a) => a.approved)?.length;
	usePopulateQuestionsAndMessages(
		props.liveId,
		user,
		messagesQuery.data,
		backStageMessagesQuery.data
	);
	return (
		<div
			className="relative z-20 flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-rte  bg-[#ECF6FA] bg-center"
			style={{
				backgroundImage: `url(${SideBarBg2})`,
			}}
		>
			{activeTab === 4 ? (
				<div className="flex items-center gap-2 rounded-se-lg rounded-ss-lg border-b-2 border-b-rte bg-rte p-2">
					<button
						onClick={() => {
							setActiveTab(2);
							setQuestionToAnswerId(null);
						}}
					>
						<img src={BackArrow} alt="Back arrow" className="cursor-pointer" />
					</button>
					<h1 className="text-lg text-white underline">{t("live_page.questions")}</h1>
					{answersLength > 0 ? (
						<span className="rounded-lg bg-[#4A4A4EB2] p-1 text-white">
							{answersLength}{" "}
							{answersLength > 1 ? t("live_page.replies") : t("live_page.one_reply")}
						</span>
					) : null}
				</div>
			) : null}
			{Tab}
		</div>
	);
};

export default InteractionSideBar;
