import { useStore } from "../../store/store";
import { twMerge } from "tailwind-merge";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabId } from "../../LivePage/store/types";

interface IProps {
	title: string;
	icon: JSX.Element;
	number?: TabId;
	showLabel: boolean;
	notifications: number;
}

const Tab = (props: IProps) => {
	const { liveToken } = useParams();
	const activeTab = useStore((state) => state.activeTab);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const closeMenu = useStore((state) => state.closeMenu);
	const navigate = useNavigate();
	const user = useStore((state) => state.user);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const moderatorToken = queryParams.get("moderatorToken");

	const tabClickHandler = () => {
		if (props.number && props.number !== 6) {
			setActiveTab(props.number);
			closeMenu();
		}
		if (props.number === 6) {
			if (user?.role === "moderator") {
				closeMenu();
				navigate(`/moderator/${liveToken ?? ""}?moderatorToken=${moderatorToken}`);
			} else {
				closeMenu();
				navigate(`/journalist/${liveToken ?? ""}`);
			}
		}
	};

	return (
		<button className="flex cursor-pointer items-center justify-center" onClick={tabClickHandler}>
			<div className="group relative flex w-full items-center gap-2  ">
				<div
					className={twMerge(
						"relative  flex h-14 w-14 cursor-pointer items-center justify-center rounded-full  p-3  transition-all   hover:bg-[#5C5A5A66]",
						activeTab === props.number ? "  bg-[#5C5A5A66]" : ""
					)}
				>
					{props.notifications && activeTab !== props.number ? (
						<span className="absolute right-0 top-1 flex h-6 min-h-min w-6 min-w-min items-center justify-center rounded-3xl bg-red-600 p-1 text-white">
							{props.notifications}
						</span>
					) : null}
					<div>{props.icon}</div>
				</div>
				{props.showLabel ? <span className="text-white ">{props.title}</span> : null}
				{props.showLabel ? null : (
					<span className=" absolute left-14 z-50 hidden whitespace-nowrap rounded-lg bg-[#2B292980] p-1 text-white group-hover:block">
						{props.title}
					</span>
				)}
			</div>
		</button>
	);
};

export default Tab;
