import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en/translation.json";
import fr from "./locales/fr/translation.json";

i18n
	.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		lng: ["fr", "en"].includes(window.navigator.language?.slice(0, 2))
			? window.navigator.language?.slice(0, 2)
			: "fr",
		fallbackLng: "fr",
		debug: true,
		backend: {},
		resources: {
			fr: {
				common: fr,
			},
			en: {
				common: en,
			},
		},
		ns: ["common"],
	});
export default i18n;
