import { useTranslation } from "react-i18next";
import { useStore } from "../../store/store";
import LiveStatusIndicator from "./LiveStatusIndicator";
import Iframe from "./Iframe";
import Use4gPrompt from "./Use4gPrompt";
import { isEqual, startOfDay } from "date-fns";
import WebSocketIndicator from "./WebSocketIndicator";
// import UserAlreadyConnected from "./UserAlreadyConnected";

interface IProps {
	iframerSrc: string | undefined;
	startDate: string | undefined;
}

const LivePlayer = (props: IProps) => {
	const { t } = useTranslation();
	const status = useStore((state) => state.status);
	const liveActive = useStore((state) => state.liveActive);
	const videoEmbedUrl = useStore((state) => state.videoEmbedUrl);
	const videoBeforeLive = useStore((state) => state.videoBeforeLive);
	const isIntranet = useStore((state) => state.isIntranet);
	const webSocketError = useStore((state) => state.webSocketError);
	const startDate = new Date(props.startDate ?? "");
	const now = new Date();
	const isToday = isEqual(startOfDay(startDate), startOfDay(now));
	const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
	// const userAlreadyConnected = useStore((state) => state.userAlreadyConnected);
	if (webSocketError && status === -1 && liveActive === 0 && isToday) {
		return <WebSocketIndicator />;
	}

	// if (userAlreadyConnected) {
	// 	return <UserAlreadyConnected />;
	// }
	if (isIntranet && isMobile) {
		return <Use4gPrompt />;
	}

	if (status === -1 && liveActive === 0) {
		if (videoBeforeLive) {
			return <Iframe src={videoBeforeLive + "&a=1&loop=1"} live={false} />;		}
		return <LiveStatusIndicator />;
	}
	if (status === 1 && liveActive === 0 && !videoEmbedUrl) {
		return <LiveStatusIndicator text={t("live_page.live_over")} />;
	}
	return <Iframe src={videoEmbedUrl || props.iframerSrc} live={true} />;
};

export default LivePlayer;
