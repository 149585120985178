import { format } from "date-fns";
import { useStore } from "../../../../store/store";
import ReplyButton from "../../Questions/Question/ReplyButton";
import { useTranslation } from "react-i18next";
import LinkPreview from "../../../../components/LinkPreview/LinkPreview";

interface IProps {
	id: number;
	question: string;
	sender: string;
	email: string;
	time: string;
	answersLength: number;
}
const Question = (props: IProps) => {
	const user = useStore((state) => state.user);
	const { t } = useTranslation();
	const approvedAnswers = useStore((state) => state.approvedAnswers).filter(
		(answer) => answer.questionId === props.id
	);
	const canReplyToQuestions = useStore((state) => state.canReplyToQuestions);

	const LinkPreviews = props?.question
		?.split(" ")
		?.filter((word) => word.startsWith("http") || word.startsWith("https"))
		?.map((word, index) => {
			// this is a bad practice but we don't have unique identifiers and you can use array index when you are sure that the array will not change/shift
			return <LinkPreview url={word} key={`${word} ${index}`} loaderColor={"#00A6D9"} />;
		});

	return (
		<div className="w-full  text-text">
			<div className="flex items-center gap-3  pt-1">
				{user?.role === "moderator" ? (
					<span className="font-medium text-rte">{`${props.sender} (${props.email})`}</span>
				) : null}
				<span>{format(new Date(props.time), "HH:mm")}</span>
			</div>
			<div className="flex items-center gap-3 pb-2">
				<div className="flex max-w-full items-center gap-2">
					<p
						className="max-w-[15rem] whitespace-pre-line  rounded-lg bg-white  p-2 md:max-w-sm lg:max-w-5xl"
						style={{
							wordBreak: "break-word",
						}}
					>
						<span>
							{props?.question?.split(" ")?.map((word, index) => {
								if (word.startsWith("http") || word.startsWith("https")) {
									return (
										<a className="underline" href={word} key={`${word} ${index}`} target="_blank">
											{word}
										</a>
									);
								}
								return word + " ";
							})}

							{LinkPreviews}
						</span>
					</p>
				</div>
				{canReplyToQuestions && <ReplyButton id={props.id} />}
			</div>
			{props?.answersLength ? (
				<span className="   rounded-lg border border-rte  p-1 text-sm text-text ">
					{props?.answersLength}{" "}
					{props?.answersLength > 1 || approvedAnswers?.length > 1
						? t("live_page.replies")
						: t("live_page.one_reply")}
				</span>
			) : null}
		</div>
	);
};

export default Question;
