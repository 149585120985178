import Input from "../SignupPage/SignupForm/Input";
import { useStore } from "../store/store";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import SmallLoader from "./SmallLoader";
import { useTranslation } from "react-i18next";
import { generateSignedEndpoint } from "../api/generateSignedEndpoint";

const RequirePassword = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const livePassword = useStore((state) => state.livePassword);
	const setLivePassword = useStore((state) => state.setLivePassword);
	const cryptedLivePassword = useStore((state) => state.cryptedLivePassword);
	const setCryptedLivePassword = useStore((state) => state.setCryptedLivePassword);
	const setShowPasswordModal = useStore((state) => state.setShowPasswordModal);
	const { liveToken } = useParams();
	const queryClient = useQueryClient();
	const checkLivePassword = async () => {
		try {
			if (!livePassword) {
				setError("Password is required");
				return;
			}
			setLoading(true);
			setError("");
			const formData = new FormData();
			formData.append("password", livePassword);
			if (liveToken) {
				formData.append("id", liveToken);
			}

			const endpoint = "/live/check-authorization";
			const signedUrl = await generateSignedEndpoint(endpoint);
			const response = await axios.post(signedUrl, formData);
			setCryptedLivePassword(response.data.token);
			setLoading(true);
			setShowPasswordModal(false);
			queryClient.invalidateQueries({ queryKey: ["liveDetails", liveToken, cryptedLivePassword] });
		} catch (e) {
			setError(t("signup_page.invalid_password"));
			setLoading(false);
		}
	};

	return (
		<div
			className="fixed bottom-0 left-0 right-0 top-0 z-50 grid h-screen w-full place-items-center p-3 "
			style={{
				backdropFilter: "blur(10px)",
				background: "rgba(211, 213, 218, 0.40) ",
			}}
		>
			<div className=" flex flex-col items-center justify-center gap-2 rounded-lg bg-white p-4 shadow-2xl ">
				<h3 className="text-xl text-black">{t("signup_page.password_prompt")}</h3>
				<Input
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							checkLivePassword();
						}
					}}
					type="password"
					setValue={setLivePassword}
					value={livePassword}
					placeholder={t("admin_page.password")}
				/>
				{error && <span className="self-start text-red-600">{error}</span>}
				<button
					onClick={checkLivePassword}
					className="w-full rounded-lg bg-rte p-3 font-semibold text-white"
				>
					{loading ? (
						<SmallLoader>
							<span>{t("signup_page.loading")}</span>
						</SmallLoader>
					) : (
						t("signup_page.submit")
					)}
				</button>
			</div>
		</div>
	);
};

export default RequirePassword;
