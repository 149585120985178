import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../store/store";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import { useEffect } from "react";
import { IModerator, ISubscriber } from "./messages/types";
export interface IQuestions {
	liveId: number;
	question: string;
	id: number;
	createdAt: string;
	updatedAt: string;
	subscriberId: number;
	type: "question" | "answer";
	subscriber: ISubscriber;
	approved: boolean;
	// using any as a type cause we only need the length of the table, and we don't care about the answers
	answers: Omit<IQuestions, "subscriber">[];
	questionId: number;
	likes: number;
	managedById: number;
	managedBy: IModerator;
	questionLikes: {
		id: number;
		questionId: number;
		subscriberId: number;
		moderatorId: number;
		createdAt: string;
		updatedAt: string;
	}[];
	moderatorId: number;
	moderator: IModerator;
}
const fetchQuestions = async (liveId: number | undefined): Promise<IQuestions[]> => {
	const endpoint = `/live/questions/live/${liveId}/questions`;
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response.data.questions;
};
const useGetQuestions = (liveId: number | undefined) => {
	const setQuestions = useStore((state) => state.setQuestions);
	const setQuestionsNotifications = useStore((state) => state.setQuestionsNotifications);
	const activeTab = useStore((state) => state.activeTab);
	const canComment = useStore((state) => state.canComment);
	const questions = useStore((state) => state.questions);

	const getQuestionsQuery = useQuery({
		queryKey: ["questions", liveId],
		queryFn: () => fetchQuestions(liveId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!canComment,
		staleTime: Infinity,
	});
	useEffect(() => {
		if (getQuestionsQuery.data && !questions.length) {
			setQuestions(getQuestionsQuery?.data);
			if (activeTab !== 5) {
				setQuestionsNotifications(getQuestionsQuery?.data.length);
			}
		}
	}, [getQuestionsQuery?.data]);

	return getQuestionsQuery;
};

export default useGetQuestions;
