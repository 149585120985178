import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IQuestionWithoutRegistration {
	firstName: string;
	lastName: string;
	question: string;
	email: string;
	enterprise: string;
	userRole: string;
	type: string;
	approved: boolean;
	liveId: number | null;
}
interface IQuestionWithRegistration {
	question: string;
	liveId: number | undefined;
	type: "question" | "answer";
	subscriberId?: number;
	moderatorId?: number;
	questionId?: number | null;
	approved?: boolean;
}
const useCreateQuestion = (liveId: number | null | undefined) => {
	const { t } = useTranslation();
	return useMutation({
		mutationFn: async (question: IQuestionWithoutRegistration | IQuestionWithRegistration) => {
			const endpoint = `/live/questions/live/${liveId}/questions`;
			const signedEndpoint = await generateSignedEndpoint(endpoint);
			return axios.post(signedEndpoint, question);
		},
		onError: () => {
			toast.error(t("errors.question"));
		},
	});
};

export default useCreateQuestion;
