const PDFPreview = () => {
	return (
		<svg width="64" height="61" viewBox="0 0 64 61" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M50.7689 0.154785L63.6753 12.612V59.9839H15.4619V60.1386H63.84V12.7688L50.7689 0.154785Z"
				fill="#909090"
			/>
			<path d="M50.609 0H15.2974V59.9838H63.6755V12.614L50.609 0Z" fill="#F4F4F4" />
			<path d="M14.9425 3.22168H0.120117V17.8847H46.7446V3.22168H14.9425Z" fill="#7A7B7C" />
			<path d="M46.9925 17.6357H0.421387V2.96191H46.9925V17.6357Z" fill="#DD2025" />
			<path
				d="M15.8633 5.44219H12.8315V15.7516H15.2161V12.2743L15.7427 12.3023C16.2543 12.2941 16.7611 12.2093 17.2435 12.051C17.6663 11.9163 18.0554 11.7037 18.387 11.426C18.7245 11.1614 18.9906 10.8275 19.1641 10.4509C19.3968 9.82477 19.4799 9.15939 19.4077 8.50066C19.3932 8.03009 19.3041 7.56405 19.1432 7.11747C18.9968 6.7951 18.7795 6.50449 18.5051 6.26414C18.2308 6.02378 17.9054 5.83895 17.5497 5.7214C17.2421 5.61831 16.9243 5.54349 16.6009 5.49803C16.356 5.46305 16.1088 5.44438 15.861 5.44219M15.4226 10.3692H15.2161V7.1905H15.6638C15.8614 7.17731 16.0597 7.20539 16.2439 7.27268C16.4282 7.33997 16.5938 7.44474 16.7285 7.57925C17.0076 7.92508 17.1567 8.34602 17.153 8.77772C17.153 9.30608 17.153 9.78504 16.638 10.1222C16.2671 10.3112 15.8448 10.3984 15.4226 10.3692ZM23.9379 5.41427C23.6804 5.41427 23.4299 5.43145 23.2536 5.43789L22.7015 5.45078H20.8922V15.7602H23.0217C23.8354 15.7809 24.6457 15.6532 25.4062 15.3844C26.0184 15.1595 26.5605 14.7971 26.9836 14.3298C27.395 13.8583 27.6903 13.3092 27.8488 12.7211C28.031 12.055 28.1199 11.3699 28.1132 10.6828C28.1582 9.87129 28.0904 9.05777 27.9114 8.26225C27.7416 7.67668 27.4235 7.137 26.9836 6.68791C26.6384 6.32528 26.2159 6.03277 25.7426 5.82879C25.3361 5.65464 24.9086 5.52629 24.4691 5.44648C24.2943 5.41975 24.1173 5.40753 23.9402 5.40997M23.5181 13.8659H23.2861V7.285H23.3162C23.7945 7.23406 24.2783 7.31395 24.708 7.51482C25.0227 7.74748 25.2791 8.0406 25.4596 8.37394C25.6543 8.72475 25.7666 9.10979 25.789 9.50368C25.8098 9.9762 25.789 10.3628 25.789 10.6828C25.7984 11.0515 25.7728 11.4202 25.7124 11.7846C25.6409 12.1589 25.5088 12.5209 25.3204 12.8585C25.1072 13.1725 24.8192 13.437 24.4784 13.6318C24.1922 13.8031 23.8533 13.8831 23.5134 13.8594M35.2971 5.45078H29.6604V15.7602H32.045V11.6708H35.0605V9.75497H32.045V7.36662H35.2925V5.45078"
				fill="#464648"
			/>
			<path
				d="M45.3903 39.208C45.3903 39.208 52.7852 37.9666 52.7852 40.3056C52.7852 42.6445 48.204 41.693 45.3903 39.208ZM39.9229 39.3863C38.748 39.6267 37.603 39.9786 36.5061 40.4366L37.434 38.5036C38.3618 36.5705 39.3245 33.9352 39.3245 33.9352C40.4316 35.6606 41.7199 37.2807 43.1704 38.772C42.0765 38.9231 40.9924 39.1296 39.9229 39.3906V39.3863ZM36.9956 25.4256C36.9956 23.3873 37.7077 22.8311 38.2621 22.8311C38.8165 22.8311 39.4404 23.0781 39.4613 24.8478C39.2807 26.6274 38.8783 28.3817 38.2621 30.0756C37.418 28.6534 36.9812 27.0548 36.9932 25.432L36.9956 25.4256ZM26.2116 48.0119C23.943 46.7554 30.9692 42.8872 32.2426 42.7627C32.2357 42.7648 28.5869 49.3263 26.2116 48.0119ZM54.9448 40.5826C54.9216 40.3679 54.7129 37.9902 50.1432 38.0912C48.2385 38.0628 46.3347 38.1871 44.4532 38.4628C42.6305 36.7626 41.061 34.8448 39.7861 32.7603C40.5893 30.6113 41.0754 28.3717 41.2312 26.1022C41.1639 23.5248 40.4982 22.0471 38.3641 22.0686C36.2301 22.0901 35.9193 23.819 36.1999 26.3921C36.4749 28.1212 36.9935 29.8094 37.7425 31.4137C37.7425 31.4137 36.7566 34.2552 35.453 37.0817C34.1494 39.9082 33.2586 41.3902 33.2586 41.3902C30.9917 42.0735 28.8577 43.0887 26.9377 44.3971C25.0263 46.0445 24.2492 47.3095 25.2559 48.5746C26.1235 49.6657 29.1599 49.9127 31.8738 46.6201C33.3158 44.9195 34.6332 43.1314 35.8172 41.2678C35.8172 41.2678 39.9554 40.2175 41.2428 39.9297C42.5302 39.6419 44.0867 39.4142 44.0867 39.4142C44.0867 39.4142 47.8653 42.9345 51.5095 42.8099C55.1536 42.6853 54.9773 40.7931 54.9541 40.5869"
				fill="#DD2025"
			/>
			<path d="M50.4307 0.165039V12.7791H63.4971L50.4307 0.165039Z" fill="#909090" />
			<path d="M50.6089 0V12.614H63.6753L50.6089 0Z" fill="#F4F4F4" />
			<path
				d="M15.6846 5.27715H12.6528V15.5866H15.0467V12.1115L15.5756 12.1394C16.0872 12.1312 16.594 12.0464 17.0764 11.8881C17.4992 11.7534 17.8882 11.5407 18.2199 11.2631C18.5549 10.9978 18.8186 10.6639 18.99 10.288C19.2227 9.66188 19.3058 8.9965 19.2336 8.33777C19.2191 7.8672 19.13 7.40116 18.9692 6.95458C18.8227 6.63221 18.6054 6.3416 18.3311 6.10125C18.0567 5.86089 17.7313 5.67606 17.3756 5.55851C17.0666 5.45441 16.7473 5.37887 16.4222 5.33299C16.1773 5.29801 15.93 5.27934 15.6823 5.27715M15.2439 10.2042H15.0374V7.02546H15.4874C15.685 7.01227 15.8833 7.04035 16.0676 7.10764C16.2518 7.17493 16.4174 7.2797 16.5521 7.41421C16.8312 7.76004 16.9803 8.18098 16.9766 8.61269C16.9766 9.14105 16.9766 9.62 16.4617 9.95721C16.0907 10.1461 15.6684 10.2312 15.2462 10.2021M23.7592 5.24923C23.5017 5.24923 23.2512 5.26641 23.0749 5.27285L22.5298 5.28574H20.7205V15.5952H22.8499C23.6637 15.6158 24.4739 15.4881 25.2345 15.2193C25.8467 14.9945 26.3887 14.6321 26.8118 14.1648C27.2232 13.6932 27.5185 13.1442 27.677 12.556C27.8593 11.8899 27.9482 11.2049 27.9415 10.5178C27.9865 9.70625 27.9187 8.89273 27.7397 8.09721C27.5698 7.51164 27.2518 6.97196 26.8118 6.52288C26.4666 6.16025 26.0441 5.86773 25.5708 5.66375C25.1644 5.4896 24.7368 5.36125 24.2973 5.28145C24.1226 5.25471 23.9456 5.2425 23.7685 5.24493M23.3463 13.7008H23.1143V7.11996H23.1445C23.6227 7.06902 24.1066 7.14891 24.5363 7.34978C24.851 7.58244 25.1074 7.87556 25.2878 8.2089C25.4826 8.55971 25.5948 8.94475 25.6172 9.33864C25.6381 9.81116 25.6172 10.1978 25.6172 10.5178C25.6267 10.8864 25.601 11.2551 25.5407 11.6196C25.4692 11.9938 25.337 12.3559 25.1487 12.6935C24.9355 13.0074 24.6474 13.2719 24.3066 13.4667C24.0204 13.6381 23.6815 13.7181 23.3417 13.6944M35.1184 5.28574H29.4817V15.5952H31.8663V11.5058H34.8818V9.58994H31.8663V7.20158H35.1138V5.28574"
				fill="white"
			/>
		</svg>
	);
};

export default PDFPreview;
