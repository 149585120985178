// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n/index";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const clientName = window.process.env.CLIENT_NAME;

// Définissez les polices selon le nom du client
let fontFamily = '"Poppins", sans-serif';

if (clientName.includes("rte")) {
	fontFamily = '"Nunito Sans", sans-serif';
}

// Créez un style global
const GlobalStyle = `
  *, *::before, *::after {
    font-family: ${fontFamily};
  }
`;

// Injectez-le dans le document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = GlobalStyle;
document.head.appendChild(styleSheet);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();
root.render(
	// commented out React.StrictMode to avoid double websocket connections and also it seems to be causing some issues when viewing question's answers in dev
	// <React.StrictMode>
	<BrowserRouter basename="/live-event">
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
	</BrowserRouter>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
