import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store/store";
import { SocketEvents } from "./useSocket/types";

interface ILikePayload {
	questionId: number | undefined;
	subscriberId?: number;
	moderatorId?: number;
}
export interface IQuestionLike {
	id: number;
	questionId: number;
	subscriberId: number;
	moderatorId: number;
	createdAt: string;
	updatedAt: string;
}
export interface ILikeQuestionResponse {
	data: {
		questionLike: IQuestionLike | null;
		result: string;
	};
}

const likeQuestion = async (payload: ILikePayload): Promise<ILikeQuestionResponse> => {
	const endpoint = `/live/questions/like`;
	const signedEndpoint = await generateSignedEndpoint(endpoint);
	const response = await axios.post(signedEndpoint, payload);
	return response.data.data;
};
const useLikeQuestion = (questionId: number | undefined) => {
	const socket = useStore((state) => state.socket);
	const { t } = useTranslation();
	return useMutation({
		mutationFn: likeQuestion,
		onError: () => {
			toast.error(t("errors.like"));
		},
		onSuccess: (data) => {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({ type: SocketEvents.likeQuestion, data: { data, questionId } })
				);
			}
		},
	});
};

export default useLikeQuestion;
