import { useTranslation } from "react-i18next";
import NoQuestion from "./../../../../assets/NoQuestions.gif";
const NoQuestions = () => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-grow flex-col items-center justify-center gap-2 font-semibold text-rte">
			<img src={NoQuestion} className="h-52 w-52 justify-start " alt="No Questions" />
			<span className="italic">{t("live_page.no_questions")}</span>
		</div>
	);
};

export default NoQuestions;
