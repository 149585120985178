import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IQuestions } from "./useGetQuestions";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import { useEffect } from "react";
import { useStore } from "../../store/store";
import { IUser } from "../../InteractionPage/store/store";

const fetchQuestions = async (
	liveId: number | undefined,
	user: IUser | null
): Promise<IQuestions[]> => {
	let endpoint = `/live/questions/approvedQuestions/${liveId}`;
	if (user && user.role === "moderator") {
		endpoint += `?moderatorId=${user.id}`;
	} else if (user && user.role !== "moderator") {
		endpoint += `?subscriberId=${user.id}`;
	}
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response.data.questions;
};
const useGetApprovedQuestions = (liveId: number | undefined, user: IUser | null) => {
	const setApprovedQuestions = useStore((state) => state.setApprovedQuestions);
	const setApprovedQuestionsNotifications = useStore(
		(state) => state.setApprovedQuestionsNotifications
	);
	const activeTab = useStore((state) => state.activeTab);
	const approvedQuestions = useStore((state) => state.approvedQuestions);

	const approvedQuestionsQuery = useQuery({
		queryKey: ["approvedQuestions", liveId, user?.id],
		queryFn: () => fetchQuestions(liveId, user),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!liveId,
		staleTime: Infinity,
	});

	useEffect(() => {
		if (approvedQuestionsQuery.data && !approvedQuestions.length) {
			setApprovedQuestions(
				approvedQuestionsQuery.data.filter((question) => {
					return (
						question.approved ||
						question?.subscriberId === user?.id ||
						question?.moderatorId === user?.id
					);
				})
			);
			if (activeTab !== 2) {
				setApprovedQuestionsNotifications(
					approvedQuestionsQuery.data.filter((question) => {
						return question.approved === true;
					})?.length
				);
			}
		}
	}, [approvedQuestionsQuery.data?.length]);

	return approvedQuestionsQuery;
};

export default useGetApprovedQuestions;
