import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface IProps {
	answersNumber: number;
	subscriberId: number;
	userId: number;
}

const NumberOfAnswers = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<>
			{props.answersNumber ? (
				<span
					className={twMerge(
						`w-fit rounded-lg bg-[#4A4A4EB2] p-1 text-white`,
						props.subscriberId === props.userId ? "flex-row-reverse  justify-end self-end" : ""
					)}
				>
					{props.answersNumber} {t("live_page.replies")}
				</span>
			) : null}
		</>
	);
};

export default NumberOfAnswers;
