import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useStore } from "../../store/store";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import { IUser } from "../../InteractionPage/store/store";
import { useLocation } from "react-router-dom";
import { ISubscriber } from "../../LivePage/hooks/messages/types";

export type LiveType = {
	title: string;
	color1: string;
	color2: string;
	type: string;
};

interface IConf {
	id: number;
	video: {
		id: number;
		title: string;
		file_title: string;
		url: string;
	};
}

interface IFile {
	format: string;
	id: number;
	name: string;
	title: string;
	url: string;
}
export interface ILiveDetails {
	id: number;
	title: string;
	description: string;
	startDate: string;
	date: {
		StartDate: string;
		EndDate: string;
	};
	url: string;
	created_by: number;
	admin_info: {
		email: string;
		first_name: string;
		last_name: string;
		subscription_id: string;
	};
	status: 1 | 0 | -1;
	active: 1 | 0;
	thumbnail: string;
	guests: ISubscriber[];
	canComment: boolean;
	chat: boolean;
	isCommentValidation: boolean;
	videoEmbedUrl: string;
	canReplyToComments: boolean;
	tags: string;
	subscription: boolean;
	isSecured: boolean;
	conf: IConf;
	isIntranet: boolean;
	canCommentBeforeLive: boolean;
	private_chat: boolean;
	canShareFile: boolean;
	files: IFile[];
	docPrivate: boolean;
	docPublic: boolean;
	docPublicMail: boolean;
	types:LiveType[]
}
export interface ILiveDetailsResponse {
	live: ILiveDetails;
}

const fetchLiveDetails = async (
	token: string | undefined,
	livePassword: string | undefined | null,
	user: IUser | null
): Promise<ILiveDetailsResponse> => {
	let endpoint = "/live/by-token/" + token;
	if (livePassword) {
		endpoint += "?token=" + livePassword;
	}
	if (livePassword && user?.email) {
		endpoint += "&email=" + user.email;
	} else if (!livePassword && user?.email) {
		endpoint += "?email=" + user.email;
	}
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response.data.data;
};
const useGetLiveDetails = (liveToken: string | undefined) => {
	const cryptedLivePassword = useStore((state) => state.cryptedLivePassword);
	const livePasswordFromToken = useStore((state) => state.user);
	const livePassword = cryptedLivePassword || livePasswordFromToken?.livePassword;
	const setIsCommentValidation = useStore((state) => state.setIsCommentValidation);
	const user = useStore((state) => state.user);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	return useQuery({
		queryKey: ["liveDetails", liveToken, livePassword, user],
		queryFn: () => fetchLiveDetails(liveToken, livePassword, user),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!user?.email || !token,
		onSuccess(data) {
			setIsCommentValidation(data.live.isCommentValidation);
		},
	});
};

export default useGetLiveDetails;
