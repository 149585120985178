import { twMerge } from "tailwind-merge";
import MessageContent from "./MessageContent";
// import EmojiPicker from "./EmojiPicker";
// import { useStore } from "../../../../../store/store";
// import ReplyIcon from "../../../../../assets/ReplyIcon";
import { IMessage, IReaction } from "../../../../hooks/messages/types";

interface IProps {
	message: string;
	subscriberId: number;
	userId: number | undefined;
	id: number;
	reactions: IReaction[];
	liveId: number | undefined;
	lastName: string;
	repliedTo: IMessage | undefined;
}

const Content = (props: IProps) => {
	// const setMessageToReply = useStore((state) => state.setMessageToReply);
	// const liveActive = useStore((state) => state.liveActive);
	// const status = useStore((state) => state.status);
	// const setShowForm = useStore((state) => state.setShowForm);
	return (
		<div
			className={twMerge(
				"flex items-center gap-1 text-white",
				props.subscriberId === props.userId ? "flex-row-reverse  justify-end self-end" : ""
			)}
		>
			<MessageContent
				message={props.message}
				subscriberId={props.subscriberId}
				userId={props.userId}
				repliedTo={props.repliedTo}
				reactions={props.reactions}
			/>
			{/* <div
				className={twMerge(
					`flex w-12 items-center justify-center gap-2`,
					props.subscriberId === props.userId && "flex-row-reverse"
				)}
			>
				<EmojiPicker id={props.id} liveId={props.liveId} />
				{!liveActive && (status === 1 || status === -1) ? null : (
					<div>
						<button
							onClick={() => {
								setMessageToReply({
									id: props.id,
									message: props.message,
									lastName: props.lastName,
								});
								setShowForm(true);
							}}
						>
							<ReplyIcon />
						</button>
					</div>
				)}
			</div> */}
		</div>
	);
};

export default Content;
