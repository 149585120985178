import { twMerge } from "tailwind-merge";
import SmallLoader from "../../components/SmallLoader";
import { useTranslation } from "react-i18next";
interface IProps {
	loading: boolean;
	text?: string;
	width?: string;
}
const SignupButton = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<div className="w-full py-3">
			<button
				className={twMerge(
					" w-full rounded-xl bg-rte py-2 text-lg font-semibold  text-white lg:w-2/5 lg:rounded-lg lg:py-4 lg:text-xs xl:text-base",
					props.width && `lg:${props.width}`
				)}
				type="submit"
			>
				{props.loading ? (
					<SmallLoader>
						<span>{t("signup_page.loading")}</span>
					</SmallLoader>
				) : (
					props.text
				)}
			</button>
		</div>
	);
};

export default SignupButton;
