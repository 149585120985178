import { useTranslation } from "react-i18next";
import { useStore } from "../../../../store/store";

interface IProps {
	id: number;
}

const ReplyButton = (props: IProps) => {
	const setQuestionToAnswer = useStore((state) => state.setQuestionToAnswer);
	const setActiveTab = useStore((state) => state.setActiveTab);
	const answerQuestion = () => {
		setActiveTab(4);
		setQuestionToAnswer(props.id);
	};
	const { t } = useTranslation();
	return (
		<button className="cursor-pointer p-2 text-sm text-rte underline" onClick={answerQuestion}>
			{t("live_page.reply")}
		</button>
	);
};

export default ReplyButton;
