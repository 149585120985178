import React from "react";
import "./LiveTypePreview.css";
import RTELogo from "./../../assets/RTELogo.svg";
import PlayIcon from "./../../assets/play.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store/store";
import { LiveType } from "../../SignupPage/hooks/useGetLiveDetails";



interface IProps {
	type: LiveType;
}

const LiveTypePreview: React.FC<IProps> = React.memo((props) => {
	const { color1, color2, type } = props.type;
	const { t } = useTranslation();
	const status = useStore((state) => state.status);

	return (
		<div className="container-preview" style={{ backgroundColor: color1 }}>
			<div className="header-preview p-6">
				<img alt="rteLogo" src={RTELogo} />
			</div>
			<div className="content-preview" style={{ backgroundColor: color1 }}>
				<div className="tv-icon">
					<div className="antennas">
						<div className="antenna left"></div>
						<div className="antenna right"></div>
					</div>
					<div className="octagon-wrapper">
						<div className="octagon" style={{ backgroundColor: color2 }}>
							{status !== 0 ? (
								<img src={PlayIcon} alt="play_icon" className="ml-4" />
							) : (
								<div className="pause">
									<div className="bar"></div>
									<div className="bar"></div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="text">
					<div className="live" style={{ color: color2, backgroundColor: "white" }}>
						LIVE
					</div>
					<div className="window" style={{ backgroundColor: color2 }}>
						{type}
					</div>
				</div>
			</div>
			<div
				className="footer-preview"
				style={{
					justifyContent: status !== 0 ? "right" : "left",
					fontSize: status !== 0 ? "2.5em" : "1.5em",
				}}
			>
				{" "}
				{status === -1
					? t("live_page.live_will_start")
					: status === 0
					? t("live_page.wait_live_bug")
					: t("live_page.live_over")}
			</div>
		</div>
	);
});

export default LiveTypePreview;
