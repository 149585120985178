import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
export type Role = "moderator" | "listener" | "journalist" | "participant";
interface ICredentials {
	email: string;
	firstName: string;
	lastName: string;
	isOnline: boolean;
	isRegistered: boolean;
	liveId: number | null | undefined;
	enterprise: string;
	role: Role;
}

const useSignup = () => {
	return useMutation({
		mutationFn: async (credentials: ICredentials) => {
			const endpoint = "/live/inscription";
			const signedUrl = await generateSignedEndpoint(endpoint);
			return axios.post(signedUrl, credentials);
		},
	});
};

export default useSignup;
