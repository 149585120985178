import { useEffect } from "react";
import useSocket from "../LivePage/hooks/useSocket/useSocket";
import { useStore } from "../store/store";
import { ILiveDetails } from "../SignupPage/hooks/useGetLiveDetails";
import { useLocation } from "react-router-dom";
import useSetParticipated from "./useSetParticipated";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { enUS, fr } from "date-fns/locale";
const useLiveDetailsUpdate = (data: ILiveDetails | undefined) => {
	const { i18n, t } = useTranslation();
	const setLive = useStore((state) => state.setLive);
	const setDescription = useStore((state) => state.setDescription);
	const setLiveActive = useStore((state) => state.setLiveActive);
	const setStatus = useStore((state) => state.setStatus);
	const setCanReplyToQuestions = useStore((state) => state.setCanReplyToQuestions);
	const setWithSubscription = useStore((state) => state.setWithSubscription);
	const setCanComment = useStore((state) => state.setCanComment);
	const setChat = useStore((state) => state.setChat);
	const setVideoEmbedUrl = useStore((state) => state.setVideoEmbedUrl);
	const setLiveId = useStore((state) => state.setLiveId);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	const setParticipated = useSetParticipated(token);
	const setVideoBeforeLive = useStore((state) => state.setVideoBeforeLive);
	const setLiveTitle = useStore((state) => state.setLiveTitle);
	const setIsIntranet = useStore((state) => state.setIsIntranet);
	const setStartDate = useStore((state) => state.setStartDate);
	const setEndDate = useStore((state) => state.setEndDate);
	const setCanCommentBeforeLive = useStore((state) => state.setCanCommentBeforeLive);
	const setBackstage = useStore((state) => state.setBackstage);
	const user = useStore((state) => state.user);

	useEffect(() => {
		if (token && data?.active === 1 && data?.status === 0 && user?.role !== "moderator") {
			setParticipated.mutate();
		}
	}, [token, data?.active, data?.status, user]);

	useEffect(() => {
		if (data) {
			document.title = data.title;
			setLive(data);
			setDescription(data?.description);
			setLiveActive(data.active);
			setStatus(data.status);
			setCanReplyToQuestions(data.canReplyToComments);
			setWithSubscription(data.subscription);
			setCanComment(data.canComment);
			setChat(data.chat);
			setVideoEmbedUrl(data.videoEmbedUrl);
			setLiveId(data.id);
			setVideoBeforeLive(data.conf?.video?.url || "");
			setIsIntranet(data.isIntranet);
			setLiveTitle(data.title);
			setStartDate(
				format(
					new Date(data.date.StartDate?.replace(/\+\d{2}:\d{2}/, "Z")),
					` eeee d MMMM yyyy, '${t("signup_page.from")}' HH'h'mm '${t("signup_page.to")}'`,
					{
						locale: i18n.language === "en" ? enUS : fr,
					}
				)
			);
			setEndDate(
				format(new Date(data.date.EndDate?.replace(/\+\d{2}:\d{2}/, "Z")), "HH'h'mm", {
					locale: i18n.language === "en" ? enUS : fr,
				})
			);
			setCanCommentBeforeLive(data.canCommentBeforeLive);
			setBackstage(data.private_chat);
		}
	}, [data]);
	useSocket(data?.id);
};

export default useLiveDetailsUpdate;
