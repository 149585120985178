import { IQuestions } from "../../../hooks/useGetQuestions";
import { useStore } from "../../../../store/store";
import PersonQuestion from "../../../../assets/PersonQuestion";
import DeleteQuestion from "../../../../assets/DeleteQuestion";
import EditQuestion from "../../../../assets/EditQuestion";
import CheckQuestion from "../../../../assets/CheckQuestion";
import { useTranslation } from "react-i18next";
import AnswersQuestion from "../../../../assets/AnswerQuestion";
import BroadcastQuestion from "../../../../assets/BroadcastQuestion";
import { SocketEvents } from "../../../hooks/useSocket/types";

interface IProps {
	question: IQuestions;
	editQuestion: Function;
}

const QuestionActions = (props: IProps) => {
	const { t } = useTranslation();
	const user = useStore((state) => state.user);
	const setQuestionToView = useStore((state) => state.setQuestionToView);
	const broadcastedQuestion = useStore((state) => state.broadcastedQuestion);
	const actionQuestion = (type: keyof typeof SocketEvents) => {
		props.editQuestion(props.question, type);
	};
	const viewAnswers = () => {
		setQuestionToView(props.question);
	};

	return (
		<div className="flex w-full items-center justify-center gap-2">
			<div className="flex flex-wrap items-center justify-center gap-2">
				<div className="flex flex-wrap items-center justify-center gap-2">
					{user?.id === props.question.managedById ? (
						<button
							onClick={() => actionQuestion(SocketEvents.approveQuestion)}
							className="h-8 w-8 rounded-lg px-2 py-1"
							style={{ background: props.question.approved ? "#15DA28" : "#C4C4C8" }}
							title={props.question.approved?t("moderator_page.btn_disapprove"):t("moderator_page.btn_approve")}

						>
							<CheckQuestion />
						</button>
					) : null}
					{user?.id === props.question.managedById ? (
						<button
							onClick={() => actionQuestion(SocketEvents.editQuestion)}
							className="h-8 w-8 rounded-lg bg-[#81D4FA] px-2 py-1"
							title={t("moderator_page.btn_edit")}

						>
							<EditQuestion />
						</button>
					) : null}
					{user?.id === props.question.managedById ? (
						<button
							onClick={() => actionQuestion(SocketEvents.deleteQuestion)}
							className="h-8 w-8 rounded-lg bg-[#E11616] px-2 py-1"
							title={t("moderator_page.btn_delete")}

						>
							<DeleteQuestion />
						</button>
					) : null}
					{props.question.type === "question" ? (
						<button
							onClick={() => viewAnswers()}
							className="relative inline-block h-8 w-8 rounded-lg px-2 py-1"
							style={{ background: props.question?.answers?.length ? "#EB8F00" : "#C4C4C8" }}
							title={t("moderator_page.btn_responses")}

						>
							{props?.question?.answers?.length ? (
								<span
									className="absolute bg-white"
									style={{
										top: "-25%",
										right: "-10%",
										color: "#EB8F00",
										borderRadius: "50%",
										fontSize: "0.75em",
										width: "1.5em",
										height: "1.5em",
									}}
								>
									{props.question?.answers?.length}
								</span>
							) : null}
							<AnswersQuestion />
						</button>
					) : null}
					<button
						disabled={props.question.managedById !== user?.id}
						className="disabled:cursor-not-allowed"
						onClick={() => actionQuestion(SocketEvents.broadcastQuestion)}
						title={props.question.id === broadcastedQuestion?.id?t("moderator_page.btn_remove_prompt"):t("moderator_page.btn_prompt")}

					>
						<BroadcastQuestion active={props.question.id === broadcastedQuestion?.id} />
					</button>
					<button
						disabled={
							(props.question.managedById && user?.id !== props.question.managedById) as boolean
						}
						onClick={() => actionQuestion(SocketEvents.manageQuestion)}
						className="h-8 w-8 rounded-lg px-2 py-1"
						style={{ background: props.question.managedById ? "#15DA28" : "#C4C4C8" }}
						title={props.question.managedById?t("moderator_page.btn_unmanage"):t("moderator_page.btn_manage")}
					>
						<PersonQuestion />
					</button>
				</div>
				{props.question.managedById && props.question?.managedBy ? (
					<div className="text-center text-sm italic text-text">
						{t("moderator_page.managed_by_question")}
						<span className="ml-1 text-[#00A6D9]">
							{props.question?.managedBy?.email === user?.email
								? t("moderator_page.you_question")
								: props.question.managedBy.email}
						</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default QuestionActions;
