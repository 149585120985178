import { twMerge } from "tailwind-merge";
import { useStore } from "../../../../store/store";
import { QuestionsFilter } from "../../../store/types";
import CheckMark from "./../../../../assets/CheckMark.svg";

interface IProps {
	text: string;
	value: QuestionsFilter;
}
const Filter = (props: IProps) => {
	const questionsFilter = useStore((state) => state.questionsFilter);
	const setQuestionsFilter = useStore((state) => state.setQuestionsFilter);
	return (
		<li
			className="flex w-full cursor-pointer items-center gap-2 overflow-hidden p-1"
			onClick={() => {
				setQuestionsFilter(props.value);
			}}
		>
			<div
				className={twMerge(
					"flex min-h-6 min-w-6 items-center justify-center rounded-md border p-1",
					questionsFilter === props.value && "border-[#15da28]"
				)}
			>
				{questionsFilter === props.value && <img src={CheckMark} />}
			</div>
			<span
				className={twMerge(
					"block w-56 overflow-hidden text-ellipsis whitespace-nowrap text-[#C4C4C8]",
					questionsFilter === props.value && "text-text"
				)}
			>
				{props.text}
			</span>
		</li>
	);
};

export default Filter;
