import { useMutation } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import axios from "axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IMessagePayload {
	message: string;
	liveId: number | null;
	subscriberId?: number;
	moderatorId?: number;
	messageToReply?: number;
	type: "general" | "backstage";
}

interface IMessageWithoutRegistrationPayload {
	firstName: string;
	lastName: string;
	message: string;
	email: string;
	enterprise: string;
	userRole: string;
	type: "general" | "backstage";
	liveId: number | null;
	messageToReply?: number;
}
const useCreateMessage = () => {
	const { t } = useTranslation();
	return useMutation({
		mutationFn: async (message: IMessagePayload | IMessageWithoutRegistrationPayload) => {
			const endpoint = `/live/messages/`;
			const signedEndpoint = await generateSignedEndpoint(endpoint);
			return axios.post(signedEndpoint, message);
		},
		onError: () => {
			toast.error(t("errors.message"));
		},
	});
};

export default useCreateMessage;
