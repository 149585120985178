import DownArrow from "./../../../assets/DownArrow.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/store";
import { IProps } from "./types";

const DialogueButton = (props: IProps) => {
	const liveActive = useStore((state) => state.liveActive);
	const liveStatus = useStore((state) => state.status);
	const { t } = useTranslation();
	const canCommentBeforeLive = useStore((state) => state.canCommentBeforeLive);
	const showForm = useStore((state) => state.showForm);
	const setShowForm = useStore((state) => state.setShowForm);
	const activeTab = useStore((state) => state.activeTab);

	let label = "";
	if (props.type === "question") {
		label = t("live_page.add_question");
	}
	if (props.type === "answer") {
		label = t("live_page.add_answer");
	}

	if (props.type === "message") {
		label = t("live_page.add_message");
	}

	return (
		<button
			disabled={
				liveActive !== 1 &&
				liveStatus !== 0 &&
				!canCommentBeforeLive &&
				(activeTab === 2 || activeTab === 4)
			}
			onClick={() => {
				setShowForm(!showForm);
			}}
			className="relative flex w-full flex-col items-center gap-1 rounded-lg  p-3 font-medium after:absolute after:left-1/2 after:top-0 after:h-[2px] after:w-1/2 after:-translate-x-1/2 after:rounded-lg after:bg-[#263238]"
			style={{
				boxShadow: !showForm ? "0px -1px 10px rgba(0, 0, 0, 0.2)" : "",
				color: showForm ? "#00A6D9" : "black",
			}}
		>
			{label}
			{showForm && <img src={DownArrow} alt="down arrow" className="w-6 animate-bounce" />}
		</button>
	);
};
export default DialogueButton;
