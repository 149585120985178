import { twMerge } from "tailwind-merge";
import { useStore } from "../../../../store/store";
import { useTranslation } from "react-i18next";

interface IProps {
	question: string;
	questionToBeAnswered?: boolean;
	subscriberId: number;
	userId: number | undefined;
	children?: React.ReactNode;
	answersLength?: number;
	questionId: number | undefined;
}
const AnswerContent = (props: IProps) => {
	const { t } = useTranslation();
	const answers = useStore((state) => state.approvedAnswers).filter(
		(answer) => answer.questionId === props.questionId
	);

	return (
		<div className={twMerge("group flex  items-center gap-2  text-white ")}>
			<div className="flex flex-col">
				<div className={twMerge("flex items-center gap-3")}>
					<div
						className={twMerge(
							` relative mb-2 flex w-auto max-w-[15rem] whitespace-pre-line rounded-lg rounded-tl-none bg-white p-2  text-sm text-text  md:max-w-sm lg:max-w-3xl  `
						)}
					>
						<span
							style={{
								wordBreak: "break-word",
								fontWeight: "400",
							}}
						>
							{props.question}
						</span>
					</div>
					<div>{props.children}</div>
				</div>

				{props?.answersLength || (props.questionToBeAnswered && answers?.length > 0) ? (
					<span
						className={twMerge(
							"w-auto items-center gap-1  rounded-lg  border border-text bg-rteTransparent p-1 text-sm text-text ",
							props.subscriberId === props.userId ? "self-end" : "self-start"
						)}
					>
						<span>
							{props.questionToBeAnswered ? answers?.length : props?.answersLength}{" "}
							{t("live_page.replies")}
						</span>
					</span>
				) : null}
			</div>
		</div>
	);
};

export default AnswerContent;
