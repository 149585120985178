import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { generateSignedEndpoint } from "../../api/generateSignedEndpoint";
import { useStore } from "../../store/store";
import { SocketEvents } from "./useSocket/types";

const useManageQuestion = (questionId: number | undefined | null) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get("token");
	const socket = useStore((state) => state.socket);
	const { t } = useTranslation();

	return useMutation({
		mutationFn: async () => {
			const signedEndpoint = await generateSignedEndpoint(
				`/live/questions/manage-question/${questionId}`
			);
			return axios.put(signedEndpoint, null, {
				headers: {
					token: token,
				},
			});
		},
		onSuccess: (data) => {
			if (socket?.readyState === 1) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.manageQuestion,
						data: data.data.question,
						toAdminOnly: true,
					})
				);
			}
		},
		onError: () => {
			toast.error(t("errors.managed"));
		},
	});
};

export default useManageQuestion;
