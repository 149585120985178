import { useParams } from "react-router-dom";
import useGetLiveDetails from "../SignupPage/hooks/useGetLiveDetails";
import useLiveDetailsUpdate from "../hooks/useLiveDetailsUpdate";
import PromptedQuestion from "./PromptedQuestion";

const PrompterPage = () => {
	const { liveToken } = useParams();
	const liveData = useGetLiveDetails(liveToken);
	const data = liveData.data?.live;
	useLiveDetailsUpdate(data);
	return (
		<div className="flex min-h-screen w-full items-center justify-center bg-black p-3 ">
			<PromptedQuestion />
		</div>
	);
};

export default PrompterPage;
