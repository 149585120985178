import { useStore } from "../../../store/store";

const Images = () => {
	const liveDetails = useStore((state) => state.live);
	return (
		<>
			{liveDetails?.files
				?.filter((file) => file.format.includes("image"))
				?.map((file) => {
					return (
						<a
							key={file.id}
							href={file.url}
							target="_blank"
							rel="noreferrer"
							download={file.name}
							className="h-24 w-full"
						>
							<img
								src={file.url}
								alt={file.name}
								className=" aspect-video h-full  w-full max-w-[12rem] rounded-lg transition-all hover:-translate-y-2 "
							/>
						</a>
					);
				})}
		</>
	);
};

export default Images;
