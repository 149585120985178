interface IProps {
	hovered: boolean;
}
const ExportIcon = (props: IProps) => {
	return (
		<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.75 7.33333H13.75V0H6.25V7.33333H1.25L10 17.1111L18.75 7.33333ZM0 19.5556H20V22H0V19.5556Z"
				fill={props.hovered ? "#00A6D9" : "#263238"}
			/>
		</svg>
	);
};

export default ExportIcon;
