import Logo from "./Logo";
import ShareButton from "./ShareButton";
interface IProps {
	children?: React.ReactNode;
	title?: string;
	livePage?: boolean;
}
const Header = (props: IProps) => {
	return (
		<header className="flex h-20 w-full items-center justify-between bg-rte p-3 lg:pl-6 lg:pr-3">
			<div className=" flex items-center gap-2 sm:gap-16">
				<div className="h-12 sm:h-16">
					<Logo/>
				</div>
				<span className="group relative ">
					{props.title ? (
						<h1 className="max-w-[7rem] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-bold text-white sm:max-w-xs md:max-w-md lg:max-w-lg lg:text-lg xl:max-w-xl 2xl:max-w-7xl">
							{props.title}
						</h1>
					) : null}
					<h2 className="absolute left-1/2 top-7 z-10 hidden -translate-x-1/2 rounded-lg bg-[#ffffffe7] p-3 text-rte group-hover:flex">
						{props.title}
					</h2>
				</span>
			</div>
			<div className="flex items-center gap-2">
				{props.children}
				<ShareButton livePage={props.livePage} />
			</div>
		</header>
	);
};

export default Header;
