const PDFIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5 5.625V17.5C17.5 18.163 17.2366 18.7989 16.7678 19.2678C16.2989 19.7366 15.663 20 15 20H13.75V18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5.625H13.75C13.2527 5.625 12.7758 5.42746 12.4242 5.07583C12.0725 4.72419 11.875 4.24728 11.875 3.75V1.25H5C4.66848 1.25 4.35054 1.3817 4.11612 1.61612C3.8817 1.85054 3.75 2.16848 3.75 2.5V13.75H2.5V2.5C2.5 1.83696 2.76339 1.20107 3.23223 0.732233C3.70107 0.263392 4.33696 0 5 0L11.875 0L17.5 5.625ZM2 14.8125H0V19.8113H0.98875V18.1338H1.9925C2.35083 18.1338 2.65583 18.0617 2.9075 17.9175C3.16167 17.7717 3.35458 17.5742 3.48625 17.325C3.62349 17.0644 3.69271 16.7733 3.6875 16.4788C3.6875 16.1663 3.62167 15.8842 3.49 15.6325C3.3581 15.3837 3.15883 15.1771 2.915 15.0363C2.665 14.8863 2.36 14.8117 2 14.8125ZM2.68125 16.4788C2.6855 16.6434 2.649 16.8066 2.575 16.9538C2.50865 17.082 2.4049 17.187 2.2775 17.255C2.13188 17.3267 1.971 17.3619 1.80875 17.3575H0.985V15.6H1.81C2.0825 15.6 2.29583 15.6754 2.45 15.8263C2.60417 15.9788 2.68125 16.1962 2.68125 16.4788ZM4.2025 14.8125V19.8113H6.0275C6.52917 19.8113 6.945 19.7125 7.275 19.515C7.60895 19.3152 7.86972 19.0132 8.01875 18.6538C8.18208 18.2787 8.26375 17.8271 8.26375 17.2987C8.26375 16.7738 8.18208 16.3258 8.01875 15.955C7.87117 15.5998 7.61297 15.3017 7.2825 15.105C6.9525 14.91 6.53375 14.8125 6.02625 14.8125H4.2025ZM5.19125 15.6187H5.895C6.20417 15.6187 6.45792 15.6821 6.65625 15.8088C6.86191 15.9444 7.01736 16.1437 7.09875 16.3762C7.19708 16.6279 7.24625 16.9417 7.24625 17.3175C7.25013 17.5668 7.22155 17.8156 7.16125 18.0575C7.11813 18.2488 7.0346 18.4286 6.91625 18.585C6.80749 18.7252 6.6634 18.8339 6.49875 18.9C6.30581 18.9721 6.10093 19.0069 5.895 19.0025H5.19125V15.6187ZM9.87 17.8225V19.8113H8.8825V14.8125H12.0675V15.6287H9.87V17.025H11.8775V17.8225H9.87Z"
				fill="#00A6D9"
			/>
		</svg>
	);
};

export default PDFIcon;
