import { UseQueryResult } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useEffect } from "react";
import { useStore } from "../../store/store";
import { ILiveDetailsResponse } from "./useGetLiveDetails";

const useCheckProtectedLive = (liveData: UseQueryResult<ILiveDetailsResponse, unknown>) => {
	const setShowPasswordModal = useStore((state) => state.setShowPasswordModal);
	useEffect(() => {
		if (
			liveData.isError &&
			liveData.error &&
			isAxiosError(liveData.error) &&
			liveData.error.response?.status === 403 &&
			liveData.error.response?.data?.message === "Unauthorized"
		) {
			setShowPasswordModal(true);
		}
	}, [liveData.isError, liveData.error]);
};

export default useCheckProtectedLive;
