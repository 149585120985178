import { IStream } from "../../store/types";
import { IMessage, ISubscriber } from "../messages/types";
import { IQuestions } from "../useGetQuestions";
import { IQuestionLike } from "../useLikeQuestion";

export type EventType =
	| "question"
	| "chat"
	| "reaction"
	| "error"
	| "approveQuestion"
	| "deleteQuestion"
	| "editQuestion"
	| "manageQuestion"
	| "likeQuestion"
	| "live_started"
	| "live_ended"
	| "broadcastQuestion"
	| "userAlreadyConnected"
	| "userVerified"
	| "pong"
	| "live_stream";

export interface IReactionEvent {
	id: number;
	liveId: number;
	createdAt: string;
	liveChatId: number;
	liveInteractionId: number;
	subscriberId: number;
	interaction: {
		id: number;
		interaction: string;
	};
	subscriber: ISubscriber;
}
export interface IPong {
	id: string;
	time: number;
	dolocation: string;
	users: string;
}

export interface IUserVerified {
	type: "userVerified";
	data: {
		message: string;
		userId: string;
	};
}

export interface IEvent {
	type: EventType;
	data:
		| IQuestions
		| IMessage
		| IReactionEvent
		| IUserVerified
		| IPong
		| IStream
		| number
		| {
				data: {
					questionLike: IQuestionLike | null;
					result: string;
				};
				questionId: number;
		  };
}

export const SocketEvents = {
	question: "question",
	chat: "chat",
	reaction: "reaction",
	error: "error",
	approveQuestion: "approveQuestion",
	liveStarted: "live_started",
	liveEnded: "live_ended",
	pong: "pong",
	deleteQuestion: "deleteQuestion",
	editQuestion: "editQuestion",
	likeQuestion: "likeQuestion",
	manageQuestion: "manageQuestion",
	broadcastQuestion: "broadcastQuestion",
	userAlreadyConnected: "userAlreadyConnected",
	userVerified: "userVerified",
	liveStream:"live_stream"
} as const;
