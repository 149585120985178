import React, { useEffect } from "react";
import useGetUrlMetaTags from "../../hooks/useGetUrlMetaTags";
import SmallLoader from "../SmallLoader";
import { useStore } from "../../store/store";

interface IProps {
	url: string;
	loaderColor: string;
}
const LinkPreview = React.memo((props: IProps) => {
	const getUrlMetaTags = useGetUrlMetaTags(props.url);
	const previewData = getUrlMetaTags.data;
	const setFailedPreviewLinksFetch = useStore((state) => state.setFailedPreviewLinksFetch);
	const handleClick = () => {
		window.open(props.url, "_blank");
	};

	useEffect(() => {
		if (getUrlMetaTags.isError) {
			setFailedPreviewLinksFetch(props.url);
		}
	}, [getUrlMetaTags.isError, setFailedPreviewLinksFetch]);

	if (getUrlMetaTags.isLoading) {
		return (
			<div onClick={handleClick} className="mt-1 cursor-pointer p-1">
				<SmallLoader color={props.loaderColor} />
			</div>
		);
	}

	if ((!previewData?.image && !previewData?.["og:image"]) || getUrlMetaTags.isError) {
		return null;
	}

	return (
		<div onClick={handleClick} className="mt-1 cursor-pointer p-1">
			{previewData?.["og:image"] && (
				<img
					loading="lazy"
					src={previewData["og:image"] || previewData.image}
					className="rounded-lg"
					alt={previewData["og:title"] || previewData?.title || "Link Preview"}
				/>
			)}
			<h3 className="p">{previewData?.["og:title"] || previewData?.title}</h3>
			<p>{previewData?.description || previewData?.["og:description"]}</p>
		</div>
	);
});

export default LinkPreview;
