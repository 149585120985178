const AnswersQuestion = () => {
	return (
		<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18 17.59L14.41 14H3C2.46957 14 1.96086 13.7893 1.58579 13.4142C1.21071 13.0391 1 12.5304 1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H16C16.5304 1 17.0391 1.21071 17.4142 1.58579C17.7893 1.96086 18 2.46957 18 3V17.59ZM18 19H19V3C19 2.20435 18.6839 1.44129 18.1213 0.87868C17.5587 0.316071 16.7956 0 16 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V12C0 12.7956 0.316071 13.5587 0.87868 14.1213C1.44129 14.6839 2.20435 15 3 15H14L18 19Z"
				fill="white"
			/>
		</svg>
	);
};

export default AnswersQuestion;
