export interface IChatIconProps {
	active: boolean;
}
const ChatIcon = (props: IChatIconProps) => {
	return (
		<svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27 1H5.72727C4.47352 1 3.27112 1.49805 2.38459 2.38459C1.49805 3.27112 1 4.47352 1 5.72727V17.5455C1 18.7992 1.49805 20.0016 2.38459 20.8881C3.27112 21.7747 4.47352 22.2727 5.72727 22.2727H18.7273L25.8182 27V22.2727H27C28.2537 22.2727 29.4561 21.7747 30.3427 20.8881C31.2292 20.0016 31.7273 18.7992 31.7273 17.5455V5.72727C31.7273 4.47352 31.2292 3.27112 30.3427 2.38459C29.4561 1.49805 28.2537 1 27 1Z"
				stroke={props.active ? "#00A6D9" : "white"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.4545 11.6364C10.4545 11.9498 10.33 12.2504 10.1084 12.4721C9.88677 12.6937 9.58617 12.8182 9.27273 12.8182C8.95929 12.8182 8.65869 12.6937 8.43706 12.4721C8.21542 12.2504 8.09091 11.9498 8.09091 11.6364C8.09091 11.323 8.21542 11.0224 8.43706 10.8007C8.65869 10.5791 8.95929 10.4546 9.27273 10.4546C9.58617 10.4546 9.88677 10.5791 10.1084 10.8007C10.33 11.0224 10.4545 11.323 10.4545 11.6364ZM17.5455 11.6364C17.5455 11.9498 17.4209 12.2504 17.1993 12.4721C16.9777 12.6937 16.6771 12.8182 16.3636 12.8182C16.0502 12.8182 15.7496 12.6937 15.528 12.4721C15.3063 12.2504 15.1818 11.9498 15.1818 11.6364C15.1818 11.323 15.3063 11.0224 15.528 10.8007C15.7496 10.5791 16.0502 10.4546 16.3636 10.4546C16.6771 10.4546 16.9777 10.5791 17.1993 10.8007C17.4209 11.0224 17.5455 11.323 17.5455 11.6364ZM24.6364 11.6364C24.6364 11.9498 24.5119 12.2504 24.2902 12.4721C24.0686 12.6937 23.768 12.8182 23.4545 12.8182C23.1411 12.8182 22.8405 12.6937 22.6189 12.4721C22.3972 12.2504 22.2727 11.9498 22.2727 11.6364C22.2727 11.323 22.3972 11.0224 22.6189 10.8007C22.8405 10.5791 23.1411 10.4546 23.4545 10.4546C23.768 10.4546 24.0686 10.5791 24.2902 10.8007C24.5119 11.0224 24.6364 11.323 24.6364 11.6364Z"
				stroke={props.active ? "#00A6D9" : "white"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChatIcon;
