import Input from "./Inputs/Input";
import NameIcon from "./../../../assets/NameIcon.svg";
import EnterpriseIcon from "./../../../assets/EnterpriseIcon.svg";
import EmailIcon from "./../../../assets/EmailIcon.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useStore } from "../../../store/store";
import { SocketEvents } from "../../hooks/useSocket/types";
import useCreateQuestion from "../../hooks/useCreateQuestion";
import { IProps } from "./types";
import DialogueInput from "./Inputs/DialogueInput";
import AddDialogueButton from "./AddDialogueButton";
import useCreateMessage from "../../hooks/useCreateMessage";
import ShowWithAnimation from "../../../components/ShowWithAnimation/ShowWithAnimation";
import CustomToast from "../../../components/CustomToast";

const DialogueForm = (props: IProps) => {
	const { t } = useTranslation();
	const listenerEmail = useStore((state) => state.listenerEmail);
	const setlistenerEmail = useStore((state) => state.setListenerEmail);
	const listenerEnterprise = useStore((state) => state.listenerEneterprise);
	const setListenerEnterprise = useStore((state) => state.setListenerEnterprise);
	const listenerName = useStore((state) => state.listenerName);
	const setListenerName = useStore((state) => state.setListenerName);
	const listenerLastName = useStore((state) => state.listenerLastName);
	const setListenerLastName = useStore((state) => state.setListenerLastName);
	const question = useStore((state) => state.question);
	const setQuestion = useStore((state) => state.setQuestion);
	const setShowFailToast = useStore((state) => state.setShowFailToast);
	const setShowSuccessToast = useStore((state) => state.setShowSuccessToast);
	const setShowForm = useStore((state) => state.setShowForm);
	const socket = useStore((state) => state.socket);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const liveId = useStore((state) => state.liveId);
	const createQuestionMutation = useCreateQuestion(liveId);
	const createMessageMutation = useCreateMessage();
	const user = useStore((state) => state.user);
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const setFailMessage = useStore((state) => state.setFailMessage);
	const questionToAnswerId = useStore((state) => state.questionToAnswer);
	const failMessage = useStore((state) => state.failMessage);
	const showFailToast = useStore((state) => state.showFailToast);
	const messageToReply = useStore((state) => state.messageToReply);
	const setMessageToReply = useStore((state) => state.setMessageToReply);

	useEffect(() => {
		if (user?.email) {
			setlistenerEmail(user.email);
		}
	}, [user?.email]);

	useEffect(() => {
		if (createQuestionMutation.isSuccess && props.type !== "message") {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.question,
						data: createQuestionMutation.data.data.data,
						toAdminOnly: isCommentValidation,
					})
				);
				setQuestion("");
				setShowForm(false);
				if (isCommentValidation) {
					setShowSuccessToast(true);
					setTimeout(() => {
						setShowSuccessToast(false);
					}, 3000);
				}
			}
		}
	}, [createQuestionMutation.isSuccess, createQuestionMutation.data]);

	useEffect(() => {
		if (createMessageMutation.isSuccess && props.type === "message") {
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.chat,
						data: createMessageMutation.data.data.message,
					})
				);
				setQuestion("");
				setShowForm(false);
				if (messageToReply) {
					setMessageToReply(null);
				}
			}
		}
	}, [createMessageMutation.isSuccess, createMessageMutation.data]);

	const sendQuestion = () => {
		if (
			listenerName.trim() === "" ||
			listenerEmail.trim() === "" ||
			listenerEnterprise.trim() === "" ||
			question.trim() === ""
		) {
			setShowFailToast(true);
			setFailMessage(t("signup_page.fill_all_fields"));
			setTimeout(() => {
				setShowFailToast(false);
			}, 3000);
			return;
		}
		if (!emailRegex.test(listenerEmail)) {
			setShowFailToast(true);
			setFailMessage(t("signup_page.invalid_email"));
			setTimeout(() => {
				setShowFailToast(false);
			}, 3000);
			return;
		}
		if (props.type === "message") {
			createMessageMutation.mutate({
				liveId: liveId,
				message: question.trim(),
				firstName: listenerName,
				lastName: listenerLastName,
				email: listenerEmail,
				enterprise: listenerEnterprise,
				...(messageToReply && { replyMessageId: messageToReply?.id }),
				userRole: user?.role ?? "listener",
				type: "general",
			});
		} else {
			createQuestionMutation.mutate({
				liveId: liveId,
				question: question.trim(),
				firstName: listenerName,
				lastName: listenerLastName,
				email: listenerEmail,
				enterprise: listenerEnterprise,
				userRole: user?.role ?? "listener",
				type: props.type,
				approved: !isCommentValidation,
				...(props.type === "answer" && {
					questionId: questionToAnswerId,
				}),
			});
		}
	};
	const submitQuestion = async (e: React.FormEvent) => {
		e.preventDefault();
		sendQuestion();
	};

	return (
		<form
			onSubmit={submitQuestion}
			className="relative flex w-full flex-col items-center justify-center gap-6 "
		>
			<ShowWithAnimation isMounted={showFailToast}>
				<CustomToast
					bottom={true}
					backgroundColor="#FF333333"
					textColor="#FF3333"
					message={failMessage}
				/>
			</ShowWithAnimation>
			<Input
				icon={NameIcon}
				value={listenerName}
				setValue={setListenerName}
				title={`${t("signup_page.last_name")}`}
				type="text"
			/>
			<Input
				icon={NameIcon}
				value={listenerLastName}
				setValue={setListenerLastName}
				title={`${t("signup_page.name")}`}
				type="text"
			/>
			<Input
				value={listenerEmail}
				setValue={setlistenerEmail}
				icon={EmailIcon}
				title={t("signup_page.email")}
				type="email"
			/>
			<Input
				value={listenerEnterprise}
				setValue={setListenerEnterprise}
				icon={EnterpriseIcon}
				title={t("signup_page.enterprise")}
				type="text"
			/>
			<DialogueInput
				type={props.type}
				sendQuestion={sendQuestion}
				isLoading={
					props.type === "message"
						? createMessageMutation.isLoading
						: createQuestionMutation.isLoading
				}
			/>
			<AddDialogueButton
				isLoading={
					props.type === "message"
						? createMessageMutation.isLoading
						: createQuestionMutation.isLoading
				}
				type={props.type}
			/>
		</form>
	);
};

export default DialogueForm;
