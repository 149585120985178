export interface IBackstageIconProps {
	active: boolean;
}
const BackstageIcon = (props: IBackstageIconProps) => {
	return (
		<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.48387 18.2402C4.65767 18.4147 4.79084 18.6253 4.8739 18.8571C4.95696 19.089 4.98786 19.3362 4.96441 19.5814C4.84645 20.7158 4.62336 21.8369 4.29802 22.93C6.63372 22.3892 8.06026 21.763 8.70823 21.4349C9.07575 21.2487 9.499 21.2046 9.89701 21.311C11.0377 21.6163 12.2138 21.7694 13.3947 21.7664C20.0853 21.7664 25.1151 17.0665 25.1151 11.7204C25.1151 6.3742 20.0853 1.67434 13.3947 1.67434C6.70405 1.67434 1.67434 6.37588 1.67434 11.7204C1.67434 14.1783 2.7074 16.4587 4.48387 18.2402ZM3.65843 24.7785C3.2617 24.8571 2.86372 24.9291 2.46462 24.9945C2.12976 25.0481 1.87526 24.6998 2.00753 24.3884C2.1558 24.0376 2.29206 23.6819 2.41607 23.3218L2.42109 23.3051C2.83633 22.0996 3.17454 20.7132 3.29844 19.4223C1.24403 17.3629 0 14.6672 0 11.7204C0 5.24737 5.99747 0 13.3947 0C20.7919 0 26.7894 5.24737 26.7894 11.7204C26.7894 18.1933 20.7919 23.4407 13.3947 23.4407C12.068 23.4437 10.7467 23.2714 9.46503 22.9284C8.59437 23.3687 6.72079 24.1707 3.65843 24.7785Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
			<path
				d="M11.8308 9.64413C11.5047 9.13622 11.0225 8.74788 10.4567 8.53749C9.89095 8.3271 9.27219 8.30602 8.69342 8.47743C8.11465 8.64883 7.60715 9.00345 7.2472 9.48799C6.88724 9.97254 6.69427 10.5608 6.6973 11.1644C6.6976 11.6643 6.83213 12.1549 7.08682 12.585C7.34152 13.015 7.70704 13.3689 8.1452 13.6094C8.58336 13.85 9.07807 13.9684 9.57767 13.9524C10.0773 13.9365 10.5634 13.7866 10.9853 13.5185C10.7659 14.1699 10.3574 14.8647 9.68432 15.5612C9.55554 15.6945 9.48496 15.8734 9.4881 16.0586C9.48965 16.1504 9.50926 16.2409 9.5458 16.325C9.58234 16.4092 9.6351 16.4853 9.70106 16.5491C9.76702 16.6129 9.8449 16.663 9.93024 16.6967C10.0156 16.7303 10.1067 16.7469 10.1985 16.7453C10.3837 16.7422 10.5601 16.6656 10.6889 16.5324C13.177 13.9539 12.8538 11.151 11.8308 9.64748V9.64413ZM18.5282 9.64413C18.202 9.13622 17.7198 8.74788 17.1541 8.53749C16.5883 8.3271 15.9695 8.30602 15.3908 8.47743C14.812 8.64883 14.3045 9.00345 13.9445 9.48799C13.5846 9.97254 13.3916 10.5608 13.3946 11.1644C13.3949 11.6643 13.5295 12.1549 13.7842 12.585C14.0389 13.015 14.4044 13.3689 14.8425 13.6094C15.2807 13.85 15.7754 13.9684 16.275 13.9524C16.7746 13.9365 17.2607 13.7866 17.6826 13.5185C17.4633 14.1699 17.0548 14.8647 16.3817 15.5612C16.3179 15.6272 16.2678 15.7051 16.2341 15.7904C16.2004 15.8758 16.1839 15.9669 16.1854 16.0586C16.187 16.1504 16.2066 16.2409 16.2431 16.325C16.2797 16.4092 16.3324 16.4853 16.3984 16.5491C16.4644 16.6129 16.5422 16.663 16.6276 16.6967C16.7129 16.7303 16.8041 16.7469 16.8958 16.7453C16.9875 16.7438 17.0781 16.7242 17.1622 16.6876C17.2464 16.6511 17.3225 16.5983 17.3863 16.5324C19.8743 13.9539 19.5512 11.151 18.5282 9.64748V9.64413Z"
				fill={props.active ? "#00A6D9" : "white"}
			/>
		</svg>
	);
};

export default BackstageIcon;
