import ImagesIcon from "../../../../assets/ImagesIcon";
import PDFIcon from "../../../../assets/PDFIcon";
import TextIcon from "../../../../assets/TextIcon";
import VideosIcon from "../../../../assets/VideosIcon";
import AdvancedSettingsTab from "./AdvancedSettingsTab";

const AdvancedSettingsTabs = () => {
	return (
		<div className="flex w-full items-center justify-between gap-0 overflow-x-auto overflow-y-hidden border-b-2 border-white bg-[#ECF6FA] ">
			<AdvancedSettingsTab title="Images" icon={<ImagesIcon />} number={1} />
			<AdvancedSettingsTab title="Videos" icon={<VideosIcon />} number={2} />
			<AdvancedSettingsTab title="Text doc" icon={<TextIcon />} number={3} />
			<AdvancedSettingsTab title="PDF doc" icon={<PDFIcon />} number={4} />
		</div>
	);
};

export default AdvancedSettingsTabs;
