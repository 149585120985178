interface IProps {
	description: string | undefined;
}

const Description = (props: IProps) => {
	return (
		<p
			className="max-h-32 w-full overflow-hidden text-ellipsis whitespace-pre-line  py-3 text-base"
			title={props?.description}
		>
			{props?.description ?? "Inscriptions attendues pour le 10 janvier 2024"}
		</p>
	);
};

export default Description;
