const TextIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.17132 22.7284L7.17132 22.7285L7.18058 22.729C7.36947 22.7389 7.58777 22.75 7.81 22.75H16.19C18.1592 22.75 19.8205 22.1601 20.9903 20.9903C22.1601 19.8205 22.75 18.1592 22.75 16.19V7.81C22.75 6.20389 22.362 4.80161 21.562 3.69645C21.2229 3.20726 20.7927 2.77703 20.3035 2.43793C19.1984 1.63797 17.7961 1.25 16.19 1.25H7.81C7.59096 1.25 7.37007 1.26066 7.15695 1.28251C5.36817 1.40014 3.8713 2.0484 2.82396 3.20544C1.7765 4.36262 1.25 5.94973 1.25 7.81V16.19C1.25 17.7961 1.63798 19.1984 2.43793 20.3035C2.77678 20.7924 3.20664 21.2223 3.69539 21.5613C4.64982 22.2569 5.83091 22.6412 7.17132 22.7284Z"
				stroke="#00A6D9"
				strokeWidth="1.5"
			/>
			<path
				opacity="0.4"
				d="M16.67 5.64001H7.33C6.18 5.64001 5.25 6.57001 5.25 7.72001V8.90001C5.25 9.31001 5.59 9.65001 6 9.65001C6.41 9.65001 6.75 9.31001 6.75 8.90001V7.72001C6.75 7.40001 7.01 7.14001 7.33 7.14001H11.25V16.86H9.47C9.06 16.86 8.72 17.2 8.72 17.61C8.72 18.02 9.06 18.36 9.47 18.36H14.54C14.95 18.36 15.29 18.02 15.29 17.61C15.29 17.2 14.95 16.86 14.54 16.86H12.76V7.14001H16.68C17 7.14001 17.26 7.40001 17.26 7.72001V8.90001C17.26 9.31001 17.6 9.65001 18.01 9.65001C18.42 9.65001 18.76 9.31001 18.76 8.90001V7.72001C18.75 6.58001 17.82 5.64001 16.67 5.64001Z"
				fill="#00A6D9"
			/>
		</svg>
	);
};

export default TextIcon;
