const PersonQuestion = () => {
	return (
		<svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.5 9.85185C8.49456 9.85185 9.44839 9.40701 10.1517 8.61519C10.8549 7.82337 11.25 6.74943 11.25 5.62963C11.25 4.50983 10.8549 3.43589 10.1517 2.64407C9.44839 1.85225 8.49456 1.40741 7.5 1.40741C6.50544 1.40741 5.55161 1.85225 4.84835 2.64407C4.14509 3.43589 3.75 4.50983 3.75 5.62963C3.75 6.74943 4.14509 7.82337 4.84835 8.61519C5.55161 9.40701 6.50544 9.85185 7.5 9.85185ZM7.5 11.2593C6.17392 11.2593 4.90215 10.6661 3.96447 9.61038C3.02678 8.55462 2.5 7.1227 2.5 5.62963C2.5 4.13656 3.02678 2.70464 3.96447 1.64888C4.90215 0.59312 6.17392 0 7.5 0C8.82608 0 10.0979 0.59312 11.0355 1.64888C11.9732 2.70464 12.5 4.13656 12.5 5.62963C12.5 7.1227 11.9732 8.55462 11.0355 9.61038C10.0979 10.6661 8.82608 11.2593 7.5 11.2593ZM13.75 18.2963V16.1852C13.75 15.6253 13.5525 15.0883 13.2008 14.6924C12.8492 14.2965 12.3723 14.0741 11.875 14.0741H3.125C2.62772 14.0741 2.15081 14.2965 1.79917 14.6924C1.44754 15.0883 1.25 15.6253 1.25 16.1852V18.2963C1.25 18.4829 1.18415 18.6619 1.06694 18.7939C0.949731 18.9259 0.79076 19 0.625 19C0.45924 19 0.300269 18.9259 0.183058 18.7939C0.0658481 18.6619 0 18.4829 0 18.2963V16.1852C0 15.252 0.32924 14.3571 0.915291 13.6972C1.50134 13.0374 2.2962 12.6667 3.125 12.6667H11.875C12.7038 12.6667 13.4987 13.0374 14.0847 13.6972C14.6708 14.3571 15 15.252 15 16.1852V18.2963C15 18.4829 14.9342 18.6619 14.8169 18.7939C14.6997 18.9259 14.5408 19 14.375 19C14.2092 19 14.0503 18.9259 13.9331 18.7939C13.8158 18.6619 13.75 18.4829 13.75 18.2963Z"
				fill="white"
			/>
		</svg>
	);
};

export default PersonQuestion;
