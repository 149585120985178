import { twMerge } from "tailwind-merge";

interface IProps {
	message: string;
	backgroundColor: string;
	textColor: string;
	top?: boolean;
	bottom?: boolean;
}
const CustomToast = (props: IProps) => {
	return (
		<div
			className={twMerge(
				"absolute left-1/2  z-50 w-[90%] -translate-x-1/2 rounded-lg p-2 text-center text-sm font-medium",
				!props.top && !props.bottom && "top-7",
				props.bottom && "bottom-24",
				props.top && "top-7"
			)}
			style={{
				backgroundColor: props.backgroundColor,
				color: props.textColor,
				backdropFilter: "blur(10px)",
			}}
		>
			<span>{props.message}</span>
		</div>
	);
};

export default CustomToast;
