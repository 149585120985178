import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { generateSignedEndpoint } from "../../../api/generateSignedEndpoint";
import { IMessagesResponse } from "./types";

const fetchMessages = async (
	liveId: number | undefined,
	page: number
): Promise<IMessagesResponse> => {
	const endpoint = `/live/messages/${liveId}?page=${page}&limit=10&type=backstage`;
	const signedUrl = await generateSignedEndpoint(endpoint);
	const response = await axios.get(signedUrl);
	return response?.data;
};
const useGetBackStageMessages = (
	liveId: number | undefined,
	page: number,
	backstage: boolean | undefined
) => {
	return useQuery({
		queryKey: ["backstageMessages", liveId, page],
		queryFn: () => fetchMessages(liveId, page),
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		refetchOnMount: false,
		enabled: backstage,
	});
};

export default useGetBackStageMessages;
