import TextPreview from "../../../assets/TextPreview";
import { useStore } from "../../../store/store";

const TextFiles = () => {
	const liveDetails = useStore((state) => state.live);
	return (
		<>
			{liveDetails?.files
				?.filter(
					(file) =>
						(file.format.includes("application") && file.format.split("/1")[1] !== "pdf") ||
						file.format.includes("text")
				)
				?.map((file) => {
					return (
						<a
							title={file.name}
							key={file.id}
							href={file.url}
							target="_blank"
							rel="noreferrer"
							download={file.name}
							className="advanced-settings-card"
						>
							<TextPreview />
							<span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
								{file.name}
							</span>
						</a>
					);
				})}
		</>
	);
};

export default TextFiles;
