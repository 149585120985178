import { useStore } from "../../store/store";
import Moderator from "./Moderator/Moderator";
import Chat from "./Chat/Chat";
import Details from "./Details";
import QuestionToAnswer from "./Questions/QuestionToAnswer";
import Questions from "./Questions/Questions";
import Tabs from "./Tabs/Tabs";
import SideBarBg2 from "./../../assets/SideBarBg2.png";
import VisibleQuestionsWithoutRegister from "./QuestionsWithoutRegister/VisibleQuestionsWithoutRegister/VisibleQuestionsWithoutRegister";
import usePopulateQuestionsAndMessages from "../../hooks/usePopulateQuestionsAndMessages";
import { UseQueryResult } from "@tanstack/react-query";
import { IMessage, IMessagesResponse } from "../hooks/messages/types";
import useGetMessages from "../hooks/messages/useGetMessages";
import useGetBackStageMessages from "../hooks/messages/useGetBackStageMessages";
import { IUser } from "../../InteractionPage/store/store";
import AdvancedSettings from "./AdvancedSettings/AdvancedSettings";
import { TabId } from "../store/types";
import { ILiveDetails } from "../../SignupPage/hooks/useGetLiveDetails";

interface IProps {
	liveId: number | undefined;
	chat: boolean | undefined;
	canComment: boolean | undefined;
	isCommentValidation: boolean | undefined;
	backstage: boolean | undefined;
}

export const TabToRender = (
	activeTab: TabId,
	messagesQuery: UseQueryResult<IMessagesResponse, unknown>,
	backstageMessagesQuery: UseQueryResult<IMessagesResponse, unknown>,
	user: IUser | null,
	messages: IMessage[] | undefined,
	backstageMessages: IMessage[] | undefined,
	liveDetails: ILiveDetails | null
) => {
	switch (activeTab) {
		case 1:
			if (!liveDetails?.chat) {
				return null;
			}
			return (
				<Chat
					liveId={liveDetails?.id}
					hasMore={messagesQuery?.data?.hasMore}
					isLoading={messagesQuery?.isLoading}
					chatType="general"
					messages={messages}
					liveWithSubscription={liveDetails?.subscription}
				/>
			);
		case 2:
			if (
				((user?.role && user?.role !== "listener") || liveDetails?.canComment) &&
				!liveDetails?.subscription
			) {
				return <VisibleQuestionsWithoutRegister liveId={liveDetails?.id} />;
			} else if (
				((user?.role && user?.role !== "listener") || liveDetails?.canComment) &&
				liveDetails?.subscription
			) {
				return <Questions liveId={liveDetails?.id} />;
			}
			return null;
		case 3:
			return <Details />;
		case 4:
			return (
				<QuestionToAnswer
					liveWithSubscription={liveDetails?.subscription}
					liveId={liveDetails?.id}
				/>
			);
		case 5:
			if (!liveDetails?.canComment) {
				return null;
			}
			return <Moderator liveId={liveDetails?.id} />;
		case 7:
			if (!liveDetails?.private_chat) {
				return null;
			}
			return (
				<Chat
					liveId={liveDetails?.id}
					hasMore={backstageMessagesQuery.data?.hasMore}
					isLoading={backstageMessagesQuery.isLoading}
					chatType="backstage"
					messages={backstageMessages}
					liveWithSubscription={liveDetails?.subscription}
				/>
			);
		case 8:
			if (!liveDetails?.canShareFile) {
				return null;
			}
			return <AdvancedSettings />;
		default:
			return null;
	}
};

const SideBar = (props: IProps) => {
	const liveDetails = useStore((state) => state.live);
	const showSideBar = useStore((state) => state.showSidebar);
	const activeTab = useStore((state) => state.activeTab);
	const user = useStore((state) => state.user);
	const page = useStore((state) => state.page);
	const backstagePage = useStore((state) => state.backstagePage);
	const messagesQuery = useGetMessages(props.liveId, page);
	const backStageMessagesQuery = useGetBackStageMessages(
		props.liveId,
		backstagePage,
		props.backstage
	);
	const messages = useStore((state) => state.messages);
	const backstageMessages = useStore((state) => state.backstageMessages);

	usePopulateQuestionsAndMessages(
		props.liveId,
		user,
		messagesQuery.data,
		backStageMessagesQuery.data
	);

	const Tab = TabToRender(
		activeTab,
		messagesQuery,
		backStageMessagesQuery,
		user,
		messages,
		backstageMessages,
		liveDetails
	);

	if (!showSideBar) return null;
	return (
		<div
			className={`flex h-full min-h-0 w-full flex-grow flex-col rounded-lg border border-rte bg-[#ECF6FA] md:w-96  md:min-w-[24rem] md:max-w-sm  md:self-start`}
			style={{
				backgroundImage: `url(${SideBarBg2})`,
				backgroundColor: 'rgba(0, 166, 217, 0.14)'
			}}
		>
			<div className=" w-full rounded-se-lg rounded-ss-lg bg-rte ">
				<Tabs canComment={props.canComment} chat={props.chat} />
			</div>
			{Tab}
		</div>
	);
};

export default SideBar;
