import SmallLoader from "../../../components/SmallLoader";
import TextAreaInput from "./TextAreaInput";
interface IProps {
	sendFunction: () => void;
	placeholder: string;
	isLoading: boolean;
}

const SideBarInput = (props: IProps) => {

	return (
		<div className=" relative flex w-full items-center justify-center gap-3 border-t-2 border-question p-2 ">
			{props.isLoading ? (
				<div
					className="absolute  inset-0 flex w-full items-center justify-center rounded-lg"
					style={{
						backdropFilter: "blur(2px)",
						backgroundColor: "rgba(255, 255, 255, 0.2)",
					}}
				>
					<SmallLoader color="#00A6D9" />
				</div>
			) : null}{" "}
			<TextAreaInput
				sendFunction={props.sendFunction}
				placeholder={props.placeholder}
				isLoading={props.isLoading}
			/>
		</div>
	);
};

export default SideBarInput;
