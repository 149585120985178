import { useTranslation } from "react-i18next";
import { useStore } from "../../../../store/store";
import { twMerge } from "tailwind-merge";

const ReplyToMessagePopUp = () => {
	const { t } = useTranslation();
	const messageToReply = useStore((state) => state.messageToReply);
	const setMessageToReply = useStore((state) => state.setMessageToReply);
	const liveWithSubscription = useStore((state) => state.withSubscription);
	const user = useStore((state) => state.user);
	return (
		<div
			className={twMerge(
				"relative  mb-1 flex-col gap-1 rounded-se-lg rounded-ss-lg bg-[#00A6D9BD] p-1 text-white transition-all",
				liveWithSubscription || user?.role !== "listener" ? "sticky left-0 top-full" : "relative"
			)}
		>
			<div className="flex w-full items-center justify-between">
				<span>{t("live_page.respond_to")}</span>
				<button
					onClick={() => {
						setMessageToReply(null);
					}}
					className="cursor-pointer text-lg"
				>
					&#10005;
				</button>
			</div>
			<div className="flex w-full flex-col rounded-lg bg-rte p-3 ">
				<span className="text-text">{messageToReply?.lastName}</span>
				<span className="text-sm font-bold">{messageToReply?.message}</span>
			</div>
		</div>
	);
};

export default ReplyToMessagePopUp;
