import { useTranslation } from "react-i18next";
import SocketIndicator from "./../../assets/SocketIndicator.png";
import LiveRteWaitBug from "./../../assets/WaitLiveBug.png";
import ReloadIcon from "../../assets/ReloadIcon";
import RTELogo from "../../assets/RTELogo.svg";
import './LivePlayer.css'
const WebSocketIndicator = () => {
	const { t } = useTranslation();
    const isRte= window.process.env.CLIENT_NAME.includes('rte')
	return (		
		  <div className="aspect-video w-full flex-grow  md:max-h-full  md:w-auto">
			{!isRte ? <div className="mx-auto block aspect-video w-full rounded-lg bg-rte md:h-full md:w-auto">
			  <div className="relative top-[5%] flex flex-col gap-4 lg:top-[10%]">
				<img src={SocketIndicator} alt="refresh" className="mx-auto block w-1/4 " />
				<div className="flex flex-col items-center gap-3 lg:gap-8">
				  <h2
					className="text-center text-white"
					style={{
					  fontSize: "clamp(0.75rem, 1vw, 1.25rem)",
					}}
				  >
					{t("live_page.socket_error")}
				  </h2>
				  <button
					className="flex items-center gap-2 rounded-lg bg-white p-1 text-rte md:rounded-xl lg:px-3 lg:py-2"
					style={{
					  fontSize: "clamp(0.75rem, .95vw, 1.25rem)",
					}}
					onClick={() => window.location.reload()}
				  >
					<ReloadIcon />
					{t("live_page.reload")}
				  </button>
				</div>
			  </div>
			</div> : 
			<div
			className="mx-auto flex flex-col  block aspect-video w-full h-auto rounded-lg bg-rte  md:h-auto md:w-auto  playerDiv"
		>
			{<>
			<div className="h-10 p-5">
				<img alt="rteLogo" src={RTELogo} className="w-16 sm:w-20 max-w-20"/>
			</div>
			<div className="relative gap-3 h-50 self-center justify-center">
				<img src={LiveRteWaitBug} alt="LiveStatusImage" className="m-auto" />				
			</div>
			
					<div className="p-5">
						<h1 className="text-white font-bold text-2xl lg:text-4xl"> {t("live_page.wait_live_bug")} </h1>
					</div>				
				</>}
		  </div>}
		  </div>		
	  );	  
};

export default WebSocketIndicator;
