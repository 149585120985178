import { useStore } from "../../../store/store";
import AdvancedSettingsTabs from "./AdvancedSettingsTabs/AdvancedSettingsTabs";
import Images from "./Images";
import PDFFiles from "./PDFFiles";
import TextFiles from "./TextFiles";
import Videos from "./Videos";
import classes from "./style.module.css";

const AdvancedSettings = () => {
	const activeAdvancedSettingsTab = useStore((state) => state.activeAdvancedSettingsTab);
	return (
		<div className="flex h-full w-full flex-grow flex-col overflow-y-auto">
			<AdvancedSettingsTabs />
			<div className={classes["media-wrapper"]}>
				{activeAdvancedSettingsTab === 1 && <Images />}
				{activeAdvancedSettingsTab === 2 && <Videos />}
				{activeAdvancedSettingsTab === 3 && <TextFiles />}
				{activeAdvancedSettingsTab === 4 && <PDFFiles />}
			</div>
		</div>
	);
};

export default AdvancedSettings;
