import { useTranslation } from "react-i18next";
import Question from "./Question";
import ShowWithAnimation from "../../../../components/ShowWithAnimation/ShowWithAnimation";
import CustomToast from "../../../../components/CustomToast";
import { useStore } from "../../../../store/store";
import NoQuestions from "./NoQuestions";
import Dialogue from "../../Dialogue/Dialogue";
import SideBarInput from "../../SideBarInput/SideBarInput";
import { useEffect, useRef, useState } from "react";
import useCreateQuestion from "../../../hooks/useCreateQuestion";
import { SocketEvents } from "../../../hooks/useSocket/types";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
interface IProps {
	liveId: number | undefined;
}
const VisibleQuestionsWithoutRegister = (props: IProps) => {
	const { t } = useTranslation();
	const showSuccessToast = useStore((state) => state.showSuccessToast);
	const approvedQuestions = useStore((state) => state.approvedQuestions);
	const questionsRef = useRef<VirtuosoHandle>(null);
	const user = useStore((state) => state.user);
	const socket = useStore((state) => state.socket);
	const createQuestionQuery = useCreateQuestion(props.liveId);
	const isCommentValidation = useStore((state) => state.isCommentValidation);
	const setSideBarInput = useStore((state) => state.setSideBarInput);
	const sideBarInput = useStore((state) => state.sideBarInput);
	const setShowSuccessToast = useStore((state) => state.setShowSuccessToast);
	const [atBottom, setAtBottom] = useState(false);
	const [moreQuestions, setMoreQuestions] = useState(false);

	useEffect(() => {
		if (createQuestionQuery.isSuccess) {
			const questionData = createQuestionQuery.data.data.question;
			if (socket?.readyState === WebSocket.OPEN) {
				socket?.send(
					JSON.stringify({
						type: SocketEvents.question,
						data: questionData,
						toAdminOnly: isCommentValidation,
					})
				);
				setSideBarInput("");
				if (isCommentValidation) {
					setShowSuccessToast(true);
					setTimeout(() => {
						setShowSuccessToast(false);
					}, 3000);
				}
			}
		}
	}, [createQuestionQuery.isSuccess]);

	const renderedQuestions = approvedQuestions.filter((question) => question.approved);

	useEffect(() => {
		if (renderedQuestions && !atBottom) {
			setMoreQuestions(true);
		} else {
			setMoreQuestions(false);
		}
	}, [renderedQuestions, atBottom]);

	const sendQuestion = () => {
		if (sideBarInput.trim() === "") return;
		createQuestionQuery.mutate({
			question: sideBarInput.trim(),
			liveId: Number(props.liveId),
			...(user?.role !== "moderator" && { subscriberId: user?.id }),
			...(user?.role === "moderator" && { moderatorId: user?.id }),
			type: "question",
			approved: !isCommentValidation,
		});
	};

	return (
		<div className=" relative flex flex-grow flex-col overflow-x-hidden rounded-lg bg-[#ECF6FA] ">
			<ShowWithAnimation isMounted={showSuccessToast}>
				<CustomToast
					bottom={true}
					backgroundColor="#70e290cc"
					textColor=" #0b772a"
					message={t("live_page.question_submitted")}
				/>
			</ShowWithAnimation>
			{approvedQuestions?.filter((question) => question.approved)?.length === 0 ? (
				<NoQuestions />
			) : (
				<div className="flex  flex-grow flex-col overflow-x-hidden bg-[#ECF6FA] p-2">
					<div className="flex h-full flex-col">
						<h3 className="text-center font-medium text-rte">{t("live_page.questions_list")}</h3>
						{
							<Virtuoso
								ref={questionsRef}
								data={renderedQuestions}
								totalCount={renderedQuestions.length}
								initialTopMostItemIndex={
									approvedQuestions.filter((question) => question.approved).length
								}
								atBottomStateChange={(bottom) => {
									setAtBottom(bottom);
								}}
								className="h-full w-full"
								itemContent={(_, question) => {
									return (
										<Question
											key={question.id}
											id={question.id}
											question={question.question}
											sender={
												question?.subscriber
													? question?.subscriber?.lastName
													: question?.moderator?.username
											}
											email={
												question?.subscriber
													? question?.subscriber?.email
													: question?.moderator?.email
											}
											time={question.createdAt}
											answersLength={question?.answers?.filter((answer) => answer.approved)?.length}
										/>
									);
								}}
							/>
						}
					</div>
					{moreQuestions && (
						<div className="sticky  bottom-2  flex  items-center  justify-center transition-all">
							<button
								onClick={() => {
									questionsRef.current?.scrollToIndex({
										index: approvedQuestions.filter((question) => question.approved).length,
										behavior: "smooth",
									});

									setMoreQuestions(false);
								}}
								className=" cursor-pointer rounded-full bg-rte p-2 text-white"
							>
								{t("live_page.scroll_to_bottom")}
							</button>
						</div>
					)}
				</div>
			)}

			{user?.role === "listener" || !user?.role ? (
				<Dialogue type="question" />
			) : (
				<SideBarInput
					sendFunction={sendQuestion}
					placeholder={t("live_page.question_input_placeholder")}
					isLoading={createQuestionQuery.isLoading}
				/>
			)}
		</div>
	);
};

export default VisibleQuestionsWithoutRegister;
