import { StateCreator } from "zustand";
import { Role } from "../../SignupPage/hooks/useSignup";
import { IQuestions } from "../../LivePage/hooks/useGetQuestions";

export interface IUser {
	email: string;
	name: string;
	id: number;
	role: Role;
	liveId: number;
	lastName?: string;
	userName?: string;
	livePassword?: string;
}
export interface IInteractionPageSlice {
	showMenu: boolean;
	toggleMenu: () => void;
	closeMenu: () => void;
	user: IUser | null;
	setUser: (user: IUser) => void;
	broadcastedQuestion: IQuestions | null;
	setBroadcastedQuestion: (question: IQuestions) => void;
	userIpAddress: string;
	setUserIpAddress: (ipAdress: string) => void;
	userSocketId: string;
	setUserSocketId: (socketId: string) => void;
}
export const interactionPageSlice: StateCreator<IInteractionPageSlice> = (set) => ({
	showMenu: false,
	toggleMenu: () => set((state) => ({ showMenu: !state.showMenu })),
	closeMenu: () => set({ showMenu: false }),
	user: null,
	setUser: (user) => set({ user }),
	broadcastedQuestion: null,
	setBroadcastedQuestion: (question) =>
		set((state) => {
			if (state.broadcastedQuestion?.id === question.id) {
				return { broadcastedQuestion: null };
			}
			return { broadcastedQuestion: question };
		}),
	userSocketId: "",
	setUserSocketId(socketId) {
		set({ userSocketId: socketId });
	},
	userIpAddress: "",
	setUserIpAddress(ipAdress) {
		set({ userIpAddress: ipAdress });
	},
});
