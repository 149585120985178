import { useTranslation } from "react-i18next";
const Footer = () => {
	const { t } = useTranslation();
	const year = new Date().getFullYear();
	return (
		<footer className=" p-2 text-center">
			© Copyright {window.process.env.CLIENT_NAME.toUpperCase()} - {year},{" "}
			{t("signup_page.all_rights_reserved")}
		</footer>
	);
};

export default Footer;
