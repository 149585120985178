import { useTranslation } from "react-i18next";
import { useStore } from "../../../../store/store";
import { DialogueType } from "../types";
import SideBarBg from "./../../../../assets/SideBarBg2.png";
interface IProps {
	sendQuestion: () => void;
	isLoading: boolean;
	type: DialogueType;
}
const DialogueInput = (props: IProps) => {
	const question = useStore((state) => state.question);
	const setQuestion = useStore((state) => state.setQuestion);
	const { t } = useTranslation();
	let label = "";

	if (props.type === "question") {
		label = t("live_page.question");
	}

	if (props.type === "answer") {
		label = t("live_page.answer");
	}

	if (props.type === "message") {
		label = t("live_page.message");
	}

	return (
		<div className="relative flex w-full  flex-col sm:w-4/5 md:w-full ">
			<label
				htmlFor={props.type}
				className="absolute -top-4 left-2 z-10 bg-white px-1 font-medium capitalize text-rte "
				style={{
					backgroundImage: `url(${SideBarBg})`,
				}}
			>
				{label}
			</label>
			<textarea
				name={props.type}
				id={props.type}
				className="h-24 max-h-48 w-full resize-none rounded-lg border border-rte bg-transparent p-2 text-sm italic outline-none disabled:bg-[#ffffff33] disabled:blur-[2px]"
				value={question}
				disabled={props.isLoading}
				onChange={(e) => {
					setQuestion(e.target.value);
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
						props.sendQuestion();
					}
				}}
			></textarea>
		</div>
	);
};

export default DialogueInput;
