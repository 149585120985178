import SideBarBg from "./../../../../assets/SideBarBg2.png";
interface IProps {
	title: string;
	icon: string;
	value: string;
	setValue: (value: string) => void;
	type: string;
}

const Input = (props: IProps) => {
	return (
		<div className="relative flex w-full flex-col sm:w-4/5  md:w-full ">
			<label
				className="absolute -top-4 left-2 z-10  bg-white px-1 font-medium text-rte "
				style={{
					backgroundImage: `url(${SideBarBg})`,
				}}
			>
				{props.title}
			</label>
			<input
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				type={props.type}
				className="  z-0 w-full rounded-lg border border-rte bg-transparent p-2 outline-none"
			/>
			<div className="absolute inset-y-1 right-0 flex items-center border-l border-l-rte px-3  ">
				<img src={props.icon} alt={`${props.title} icon`} />
			</div>
		</div>
	);
};

export default Input;
