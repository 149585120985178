import { Turnstile } from '@marsidev/react-turnstile';
import Image from '../../assets/DefaultCaptchaLogo.svg'
import { useTranslation } from 'react-i18next';
import classes from "./Turnstile.module.css";

const TurnstileContainer = ({ onSuccess }: any) => {
  const { t } = useTranslation();

  const imageUrl = `https://${window.process.env.FO_CLIENT}/logo/${window.process.env.CLIENT_NAME}.png`;

  const handleImageError = (event:any) => {
		event.target.src = Image;
		event.target.alt = 'default_logo';

	  };
  return (
    <div
    className={classes.CaptchaContainer} >
    <div
    className={classes.items} 
  
    >
        <div style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
        <img src={imageUrl} 
					alt={`${process.env.CLIENT_NAME}_logo`}
          className={classes.image}
        onError={handleImageError}

        />
          <h1 className={classes.ClientName}>
            {window.process.env.CLIENT_NAME}
          </h1>
        </div>
        <h3 className={classes.verify}  style={{ marginBottom: "20px" }}>
        {t("captcha.Verify")}
        </h3>
        <Turnstile siteKey='0x4AAAAAAARjdnIP4TmKxKQZ' onSuccess={onSuccess} />
        <h2 className={classes.info}>
        {t("captcha.info")}
        </h2>
      </div>
    </div>
  );
};

export default TurnstileContainer;
