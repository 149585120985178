const TabArrow = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.30004 0.899994L5.04004 2.24999L11.7 8.99999L5.04004 15.75L6.30004 17.1L14.4 8.99999L6.30004 0.899994Z"
				fill="white"
			/>
		</svg>
	);
};

export default TabArrow;
