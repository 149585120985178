import { generateSignedUrl } from "./generateSignedUrl";

export const generateSignedEndpoint = async (endpoint: string) => {
	try {
		const hostName = window.location.hostname;
		let apiHostLive = window.process.env.API_HOST_LIVE;
		if (window.process.env.URL_LIVE_INTRANET === hostName) {
			apiHostLive = "//" + window.process.env.URL_LIVE_INTRANET;
		}
		if (!apiHostLive?.includes("https://")) {
			apiHostLive = "https:" + apiHostLive;
		}
		const url = new URL(`${apiHostLive}${endpoint}`);
		const signedUrl = await generateSignedUrl(url);
		return signedUrl;
	} catch (e) {
		console.log("ERROR GENERATING SIGNED URL: ", e);
		return "";
	}
};
