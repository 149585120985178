import { useStore } from "../../store/store";

const Details = () => {
	const description = useStore((state) => state.description);
	return (
		<div className="flex-grow overflow-y-auto p-2 text-text">
			<p className="whitespace-pre-line text-justify">{description}</p>
		</div>
	);
};

export default Details;
