import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { useStore } from "../../../../store/store";

interface IProps {
	lastName: string;
	subscriberId: number;
	email?: string;
	userId: number | undefined;
	createdAt: string;
	isAnswer?: boolean;
	type: "question" | "message";
}

const Sender = (props: IProps) => {
	const { t } = useTranslation();
	const user = useStore((state) => state.user);
	const liveWithSubscription = useStore((state) => state.withSubscription);
	let senderName = "";
	if (props.type === "question") {
		if (user?.role === "moderator") {
			senderName =
				props.subscriberId === props.userId
					? t("live_page.you")
					: props.lastName + ` (${props.email})`;
		}
	} else {
		senderName = props.subscriberId === props.userId ? t("live_page.you") : props.lastName;
	}

	return (
		<div
			className={twMerge(
				`flex items-center gap-2`,
				!props.isAnswer && props.subscriberId === props.userId
					? "flex-row-reverse justify-start"
					: ""
			)}
		>
			<span
				className={twMerge(
					"flex items-center gap-1 text-xs font-medium text-text",
					!props.isAnswer && props.subscriberId === props.userId && "flex-row-reverse justify-start"
				)}
			>
				<span
					className={twMerge(
						" flex items-center text-xl  font-extrabold",
						!liveWithSubscription && "text-rte"
					)}
				>
					&bull;
				</span>

				{senderName}
			</span>
			<span className="whitespace-nowrap text-xs text-[#C4C4C8] ">
				{format(new Date(props.createdAt), "hh:mm a")}
			</span>
		</div>
	);
};

export default Sender;
