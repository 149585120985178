import VideoPreview from "../../../assets/VideoPreview";
import { useStore } from "../../../store/store";

const Videos = () => {
	const liveDetails = useStore((state) => state.live);
	return (
		<>
			{liveDetails?.files
				?.filter((file) => file.format.includes("video"))
				?.map((file) => {
					return (
						<a
							key={file.id}
							href={file.url}
							target="_blank"
							download={file.name}
							className="advanced-settings-card"
							rel="noreferrer"
						>
							<VideoPreview />
							<span className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
								{file.name}
							</span>
						</a>
					);
				})}
		</>
	);
};

export default Videos;
