import { format } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

interface IProps {
	startDate: string | undefined;
	endDate: string | undefined;
	isError: boolean;
}

const LiveDate = (props: IProps) => {
	const { t, i18n } = useTranslation();
	const startDate = props.startDate?.replace(/\+\d{2}:\d{2}/, "Z");
	const endDate = props.endDate?.replace(/\+\d{2}:\d{2}/, "Z");
	return (
		<>
			<span className="w-full">
				{i18n.language === "fr" ? "Le" : null}
				{format(
					new Date(startDate ?? "2024-02-08 14:00:00"),
					` eeee d MMMM yyyy, '${t("signup_page.from")}' HH'h'mm '${t("signup_page.to")}'`,
					{
						locale: i18n.language === "en" ? enUS : fr,
					}
				)}{" "}
				{format(new Date(endDate ?? "2024-02-08 15:40:00"), "HH'h'mm", {
					locale: i18n.language === "en" ? enUS : fr,
				})}
			</span>
			<span className="w-full">
				{t("signup_page.on")}{" "}
				<a
					href={window.location.origin + window.location.pathname}
					target="_blank"
					rel="noreferrer"
				>
					{props.isError
						? "https://rte.empreinte.com/live-event/fyGYwV-rte-nos-perspectives-d-investissement-dans-le-reseau-edition-2024"
						: window.location.origin + window.location.pathname}
				</a>
			</span>
		</>
	);
};

export default LiveDate;
