import { twMerge } from "tailwind-merge";
import Sender from "../../Questions/Question/Sender";
import Content from "./Content/Content";
interface IProps {
	subscriberId: number;
	userId: number | undefined;
	children: React.ReactNode;
}
const Message = (props: IProps) => {
	return (
		<div
			className={twMerge(
				`flex flex-col  py-2 font-normal `,
				props.subscriberId === props.userId ? "justify-end" : ""
			)}
		>
			{props.children}
		</div>
	);
};

Message.Sender = Sender;
Message.Content = Content;
export default Message;
