const VideoPreview = () => {
	return (
		<svg width="64" height="61" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M32 42H0V0H22L32 10V42Z" fill="#90CAF9" />
			<path d="M30.5 11H21V1.5L30.5 11Z" fill="#E1F5FE" />
			<path d="M22 25L10 18V32L22 25Z" fill="#1976D2" />
		</svg>
	);
};

export default VideoPreview;
