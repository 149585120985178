import LiveTypePreview from "../../components/LiveTypePreview/LiveTypePreview";
import { useStore } from "../../store/store";

interface IProps {
	src: string | undefined;
	live:boolean;
}

const Iframe = (props: IProps) => {
	const streamStatus = useStore((state) => state.streamStatus);
	const status = useStore((state) => state.status);
	const live = useStore((state) => state.live);
    const isRte= window.process.env.CLIENT_NAME.includes('rte');

	const displayFrame = (props.live&&streamStatus) ||status===1 || !isRte;
	return (
		<div className="aspect-video w-full flex-grow md:max-h-full md:w-auto">
			<iframe
				// if videoEmbedUrl exists it means that the live is over and the video is embeded
				src={props.src}
				allowFullScreen
				className="mx-auto block aspect-video w-full rounded-lg border-0 outline-none md:h-full md:w-auto"
				title="Live"
				allow="autoplay;"
				style={{height:(displayFrame || !live?.types?.length)?"100%":"0%"}}
			></iframe>
				{((!displayFrame) && live?.types?.length )?				<LiveTypePreview type={live?.types[0]}/>
:null}
			</div>
	);
};

export default Iframe;
